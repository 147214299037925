import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { ThemeProvider } from '@mui/material/styles'
import RemoveIcon from '@material-ui/icons/HighlightOffOutlined'

import { currencyRenderer } from '../../core/utils/currency-renderer'
import { formatNumber } from '../../core/utils/format-number'
import { theme } from '../../config/theme'
import { minCollRatio } from '../../core/constants'
import { useAppContext } from '../../AppContext'
import { useSelectNft } from '../../hooks/state/useSelectNft'
import './Drawer.css'

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  borderRadius: 7.41,
  height: 40,
})

export function FluidDrawer({
  absolute,
  compact,
  margin,
  maxHeight,
  maxWidth,
  nfts,
  showHeader,
  title,
}) {
  const { ethUsdPrice } = useAppContext()
  const [selectedNfts, setNftIds] = useSelectNft()

  const handleRemoveNft = (tokenId) => {
    selectedNfts.delete(tokenId)
    setNftIds(selectedNfts)
  }

  const liquiditySum = nfts?.reduce((sum, nft) => {
    sum += nft.floorPrice ? nft.floorPrice / minCollRatio : 0
    return sum
  }, 0)

  // Leave out the borrow balanc
  if (!nfts?.length) {
    return <div />
  }

  return (
    <div
      className={`fluid-drawer ${absolute ? 'absolute' : 'relative'}`}
      style={{
        maxHeight,
        maxWidth,
        margin,
      }}
    >
      <div className='fluid-drawer-wrapper'>
        {title && (
          <h1 className='oxanium-medium-gravel-18px' style={{ marginTop: 5 }}>
            {title}
          </h1>
        )}
        {showHeader && (
          <div className='fluid-drawer-grid-2 fluid-drawer-header'>
            <div className='fluid-drawer-header-image' />

            <div className='fluid-drawer-grid-4'>
              <div
                className='fluid-drawer-item-section'
                style={{ width: '13rem' }}
              />
              <div className='fluid-drawer-item-section oxanium-medium-gravel-16px'>
                Floor Price
              </div>
              <div className='fluid-drawer-item-section oxanium-medium-gravel-16px'>
                Available Liquidity
              </div>
              <div
                // style={{ width: '7.5rem' }}
                className='fluid-drawer-item-section oxanium-medium-gravel-16px'
              >
                Interest Rate
              </div>
            </div>
          </div>
        )}
        <Scrollbars>
          {nfts?.map((nft) => {
            return (
              <div
                key={`nft-drawer-${nft.tokenId}`}
                className='fluid-drawer-grid-2 fluid-drawer-item'
              >
                <div
                  className={`fluid-drawer-grid-item fluid-drawer-image ${
                    compact ? 'compact' : ''
                  }`}
                >
                  <div
                    className='fluid-drawer-icon-close'
                    onClick={() => handleRemoveNft(nft.tokenId)}
                  >
                    <RemoveIcon fontSize='small' />
                  </div>
                  <LazyLoadImage
                    className={`fluid-drawer-image ${compact ? 'compact' : ''}`}
                    src={nft.image}
                    onLoad={(event) =>
                      (event.target.style.display = 'inline-block')
                    }
                    onError={(event) => (event.target.style.display = 'none')}
                  />
                </div>
                <div className='fluid-drawer-grid-item fluid-drawer-right-section'>
                  <div className='fluid-drawer-grid-4'>
                    <div
                      style={{ width: compact ? 'auto' : '10rem' }}
                      className='fluid-drawer-grid-item fluid-drawer-item-section'
                    >
                      <div
                        className={`oxanium-medium-black-${
                          compact ? '16px' : '18px'
                        }`}
                      >
                        {nft.collectionSymbol} #{nft.tokenId}
                      </div>
                      <div
                        className={`oxanium-medium-gravel-${
                          compact ? '12px' : '16px'
                        } fluid-drawer-collection-name${compact ? '-sm' : ''}`}
                      >
                        {nft.collectionName}
                      </div>
                    </div>
                    {!compact && (
                      <div className='fluid-drawer-grid-item fluid-drawer-item-section'>
                        <div className='fluid-drawer-available-liquidity'>
                          <div className='oxanium-medium-black-18px'>
                            {formatNumber(nft.floorPrice, 3)}
                          </div>
                          <img
                            className='fluid-drawer-weth-icon'
                            src='/img/weth-icon-1@2x.svg'
                          />
                        </div>
                        <div
                          className={`oxanium-medium-gravel-${
                            compact ? '12px' : '16px'
                          } ${compact ? 'fluid-drawer-collection-name' : ''}`}
                        >
                          {currencyRenderer(
                            nft.floorPrice * parseFloat(ethUsdPrice),
                            2
                          )}
                        </div>
                      </div>
                    )}
                    <div className='fluid-drawer-grid-item fluid-drawer-item-section'>
                      <div className='fluid-drawer-available-liquidity'>
                        <div
                          className={`oxanium-medium-black-${
                            compact ? '16px' : '18px'
                          }`}
                        >
                          {formatNumber(nft.availableLiquidity, 3)}
                        </div>
                        <img
                          className='fluid-drawer-weth-icon'
                          src='/img/weth-icon-1@2x.svg'
                        />
                      </div>
                      <div
                        className={`oxanium-medium-gravel-${
                          compact ? '12px' : '16px'
                        }`}
                      >
                        {currencyRenderer(
                          parseFloat(nft.availableLiquidity) *
                            parseFloat(ethUsdPrice)
                        )}
                      </div>
                    </div>

                    {!compact && (
                      <div className='fluid-drawer-grid-item fluid-drawer-item-section'>
                        <div className='oxanium-medium-black-18px'>
                          {isNaN(nft.interestRate)
                            ? '--'
                            : (nft.interestRate * 100).toFixed(2)}{' '}
                          %
                        </div>
                        <div className='oxanium-medium-gravel-16px'>APR</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Scrollbars>
        {compact && (
          <ThemeProvider theme={theme}>
            <Link to={`/review`}>
              <StyledButton
                variant='contained'
                className='fluid-drawer-submit-borrow-button'
              >
                Borrow up to {formatNumber(liquiditySum, 3)} {'ETH'}
              </StyledButton>
            </Link>
          </ThemeProvider>
        )}
      </div>
    </div>
  )
}
