import { usePersistentContext } from './usePersistentState'

export const useSelectNft = () => {
  const [selectedNfts, setNftIds] = usePersistentContext('selected-nfts')
  const parseSelectedNfts = (nfts) => {
    const values = Array.from(nfts.values())
    return setNftIds(values)
  }
  return [new Set(selectedNfts || []), parseSelectedNfts]
}
