import React from "react";
import "./RowItem.css";

function RowItem(props) {
  const { className, nftSymbol, nftTokenId, nftImage } = props;

  return (
    <div className={`row-item ${className || ""}`}>
      <img className="nft-icon-1" src={nftImage} />
      <div className="nft-title-3 valign-text-middle oxanium-semi-bold-black-18px">
        <span>
          <span className="span oxanium-semi-bold-black-18px">
            {nftSymbol}
            <br />
          </span>
          <span className="span oxanium-normal-black-18px">#{nftTokenId}</span>
        </span>
      </div>
    </div>
  );
}

export default RowItem;
