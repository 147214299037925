import React from "react";
import "./DashboardAverageCr2.css";

function DashboardAverageCr2(props) {
  const { averageCrTitle, averageCrAmount, className } = props;

  return (
    <div className={`dashboard-average-cr-2 ${className || ""}`}>
      <div className="x-cr-title valign-text-middle oxanium-medium-star-dust-24px">{averageCrTitle}</div>
      <div className="x-cr-amount-4 valign-text-middle oxanium-medium-white-40px">{averageCrAmount}</div>
      <div className="x-cr-min-amount valign-text-middle oxanium-medium-granite-gray-22px">Min. 150%</div>
    </div>
  );
}

export default DashboardAverageCr2;
