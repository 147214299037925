import { useWeb3React } from "@web3-react/core";
import useIsValidNetwork from "./useIsValidNetwork";
import { useAppContext } from "../AppContext";
import { formatEther } from "@ethersproject/units";


export const useETH = () => {
    const { active, account, library } = useWeb3React();
    const { isValidNetwork } = useIsValidNetwork();
    const { setEthBalance } = useAppContext();

    const fetchEthBalance = async () => {
        if (active && library && account) {
            const balance = await library.getBalance(account);
            setEthBalance(formatEther(balance));
        } else {
            setEthBalance("--");
        }
    } 

    return {
        fetchEthBalance
    }
};

export default useETH;
