import { createAlchemyWeb3 } from '@alch/alchemy-web3'
import { useWeb3React } from '@web3-react/core'
import { formatEther } from '@ethersproject/units'
import debug from 'debug'

import useIsValidNetwork from './useIsValidNetwork'
import { useAppContext } from '../AppContext'
import { useContract } from './useContract'
import NFTPriceConsumer from '../artifacts/contracts/NFTPriceConsumer/NFTPriceConsumer.json'
import NFTData from '../artifacts/contracts/mocks/NFT/NFT.json' // TODO remove mock
import metaBAYC from '../data/metaBAYC.json'

const logger = debug('fluidnft:useCollateralManager')

export const useNFT = () => {
  const { account } = useWeb3React()
  const { isValidNetwork } = useIsValidNetwork()
  const {
    setNfts,
    setNft,
    setBorrowImages,
    setBorrowNames,
    setBorrowSymbols,
    setBorrowTokenIds,
    setPoolCollectionAddresses,
    setPoolCollectionImages,
    setPoolCollectionNames,
    setPoolSymbols,
    setFloorPrices,
    setFloorPrice,
  } = useAppContext()

  // MOCK START
  const isMock = true

  const nftContractABI = NFTData['abi']
  const nftContractAddressBAYC = process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS
  const nftContractBAYC = useContract(nftContractAddressBAYC, nftContractABI)
  const nftContract = {
    BAYC: nftContractBAYC,
  }
  const fetchImageBAYC = async (tokenId) => {
    // let response = await fetch(`https://gateway.pinata.cloud/ipfs/QmeSjSinHpPnmXmspMjwiXyN6zS4E9zccariGR3jxcaWtq/${tokenId}`);
    // let data = await response.json();
    let data = metaBAYC[tokenId]
    let imageIPFS = data['image']
    let imageURL = `https://nftstorage.link/ipfs/${imageIPFS.split('//')[1]}`
    return imageURL
  }

  // MOCK END

  const whitelistContractAddresses = [
    '0x0d0167a823c6619d430b1a96ad85b888bcf97c37', //0X0D0167A823C6619D430B1A96AD85B888BCF97C37 // 0x0d0167a823c6619d430b1a96ad85b888bcf97c37
    '0x22c08c358f62f35b742d023bf2faf67e30e5376e', //0X22C08C358F62F35B742D023BF2FAF67E30E5376E // 0x22c08c358f62f35b742d023bf2faf67e30e5376e
    process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS,
  ]

  const nftPriceConsumerAddress =
    process.env.REACT_APP_NFT_PRICE_CONSUMER_CONTRACT_ADDRESS
  const nftPriceConsumerABI = NFTPriceConsumer['abi']
  const nftPriceConsumer = useContract(
    nftPriceConsumerAddress,
    nftPriceConsumerABI
  )

  const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY
  const web3 = createAlchemyWeb3(
    `https://eth-mainnet.alchemyapi.io/v2/${apiKey}`
  )

  const cleanWhitelistedNftData = (nft) => {
    let nftData = {}
    logger(
      'cleanWhitelistedNftData',
      nft.tokenAddress,
      nft.tokenAddress.toUpperCase()
    )
    switch (nft.tokenAddress.toUpperCase()) {
      case '0X0D0167A823C6619D430B1A96AD85B888BCF97C37':
        nftData = nft
        nftData['collectionName'] = 'Expansion Punks'
        nftData['collectionSymbol'] = 'XPUNK'
        return nftData

      case '0X22C08C358F62F35B742D023BF2FAF67E30E5376E':
        nftData = nft
        nftData['collectionName'] = '0xApes'
        nftData['collectionSymbol'] = '0xAPE'
        return nftData

      case process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS.toUpperCase():
        nftData = nft
        nftData['collectionName'] = 'Bored Ape Yacht Club'
        nftData['collectionSymbol'] = 'BAYC'
        return nftData

      case '0XBC4CA0EDA7647A8AB7C2061C2E118A18A936F13D':
        nftData = nft
        nftData['collectionName'] = 'Bored Ape Yacht Club'
        nftData['collectionSymbol'] = 'BAYC'
        return nftData

      case '0X8A90CAB2B38DBA80C64B7734E58EE1DB38B8992E':
        nftData = nft
        nftData['collectionName'] = 'Doodles'
        nftData['collectionSymbol'] = 'DDLE'
        return nftData

      case '0XEDB61F74B0D09B2558F1EEB79B247C1F363AE452':
        nftData = nft
        nftData['collectionName'] = 'Gutter Cat Gang'
        nftData['collectionSymbol'] = 'GCG'
        return nftData

      default:
        return nft
    }
  }

  const fetchNft = async (address, tokenId) => {
    if (account && isValidNetwork) {
      let nft = {}
      if (isMock) {
        const imageUrl = await fetchImageBAYC(tokenId)
        const floorPrice = await nftPriceConsumer.getFloorPrice(address)
        nft['tokenAddress'] = address
        nft['tokenId'] = tokenId
        nft['collectionName'] = 'Bored Ape Yacht Club'
        nft['image'] = imageUrl
        nft['collectionSymbol'] = 'BAYC'
        nft['floorPrice'] = parseFloat(formatEther(floorPrice))
      } else {
        nft = await web3.alchemy.getNftMetadata({
          contractAddress: address,
          tokenId: tokenId,
        })
      }

      setNft(nft)
    }
  }

  const fetchNfts = async (address, isWhitelist) => {
    if (account && isValidNetwork) {
      let nfts = { ownedNfts: [] }
      if (isMock) {
        logger('getting floor price', { account, isValidNetwork })
        // mock ==> always BAYC floor Price
        const floorPrice = await nftPriceConsumer.getFloorPrice(
          process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS
        )
        logger('got floor price', { floorPrice })
        for (var tokenId = 0; tokenId < 120; tokenId++) {
          try {
            const owner = await nftContract['BAYC'].ownerOf(tokenId)
            logger('valid', owner == account, tokenId)
            if (owner == account) {
              // Get Image URL
              logger('getting image', tokenId)
              const imageUrl = await fetchImageBAYC(tokenId)
              logger('got image', imageUrl)

              // Get nft data
              const nft = {}
              nft['tokenAddress'] =
                process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS
              nft['tokenId'] = tokenId
              nft['collectionName'] = 'Bored Ape Yacht Club'
              nft['image'] = imageUrl
              nft['collectionSymbol'] = 'BAYC'
              nft['floorPrice'] = parseFloat(formatEther(floorPrice))

              // Add to list
              nfts['ownedNfts'].push(nft)

              logger('imageUrl', imageUrl)
            }
          } catch {
            console.error('tokenId', tokenId)
          }
        }
      } else {
        nfts = isWhitelist
          ? await web3.alchemy.getNfts({
              owner: address,
              contractAddresses: whitelistContractAddresses,
            })
          : await web3.alchemy.getNfts({
              owner: address,
            })
      }
      setNfts(nfts)
    }
  }

  const formatNftData = (nft) => {
    if (account && isValidNetwork) {
      if (isMock) {
        return nft
      } else {
        var nftData = {}
        nftData['tokenAddress'] = nft.contract.address
        nftData['tokenId'] = nft.id.tokenId.startsWith('0x')
          ? parseInt(nft.id.tokenId, 16)
          : nft.id.tokenId
        nftData['collectionName'] = nft.title.includes(' #')
          ? nft.title.substring(0, nft.title.indexOf(' #'))
          : nft.title
        nftData['image'] =
          nft.media[0].raw.includes('ipfs.io') ||
          nft.media[0].raw.includes('ipfs://')
            ? 'https://nftstorage.link/ipfs/' +
              (nft.media[0].raw.includes('ipfs://')
                ? nft.media[0].raw.split('ipfs://')[1]
                : nft.media[0].raw.split('ipfs/')[1])
            : nft.media[0].raw
        nftData['collectionSymbol'] = ''

        if (
          whitelistContractAddresses.includes(
            nftData['tokenAddress'].toUpperCase()
          )
        ) {
          nftData = cleanWhitelistedNftData(nftData)
        }
        nftData['nftName'] =
          nftData['collectionSymbol'] !== ''
            ? `${nftData['collectionSymbol']} #${nftData['tokenId']}`
            : `#${nftData['tokenId']}`
        return nftData
      }
    }
  }

  const getFormattedNftData = async (address, tokenId) => {
    if (isMock) {
      // Get Image URL
      const imageUrl = await fetchImageBAYC(tokenId)
      const floorPrice = await nftPriceConsumer.getFloorPrice(address)

      // Get nft data
      const nft = {}
      nft['tokenAddress'] = process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS
      nft['tokenId'] = tokenId
      nft['collectionName'] = 'Bored Ape Yacht Club'
      nft['image'] = imageUrl
      nft['collectionSymbol'] = 'BAYC'
      nft['floorPrice'] = parseFloat(formatEther(floorPrice))

      // Return formatted nft
      return nft
    } else {
      const nft = await web3.alchemy.getNftMetadata({
        contractAddress: address,
        tokenId: tokenId,
      })
      return formatNftData(nft)
    }
  }

  const fetchNftData = async (borrows) => {
    if (account && isValidNetwork) {
      const data = await Promise.all(
        borrows.map((borrow) =>
          getFormattedNftData(borrow.nftContractAddress, borrow.nftTokenId)
        )
      )
      setBorrowImages(data.map(({ image }) => image))
      setBorrowNames(data.map(({ collectionName }) => collectionName))
      setBorrowSymbols(data.map(({ collectionSymbol }) => collectionSymbol))
      setBorrowTokenIds(data.map(({ tokenId }) => tokenId))
    }
  }

  const fetchPoolNftData = async (pools) => {
    if (account && isValidNetwork) {
      const data = await Promise.all(
        pools.map(
          (pool) => getFormattedNftData(pool.tokenAddress, 1) // TokenId 1
        )
      )
      setPoolCollectionAddresses(pools.map(({ tokenAddress }) => tokenAddress))
      setPoolCollectionImages(data.map(({ image }) => image))
      setPoolCollectionNames(data.map(({ collectionName }) => collectionName))
      setPoolSymbols(
        data.map(({ collectionSymbol }) => `${collectionSymbol}-WETH`)
      )
    }
  }

  const fetchDefaultNftData = async (defaults) => {
    if (account && isValidNetwork) {
      const data = await Promise.all(
        pools.map(
          (pool) => getFormattedNftData(pool.tokenAddress, 1) // TokenId 1
        )
      )
      setDefaultBorrowImages(data.map(({ image }) => image))
    }
  }

  const fetchNftFloorPrice = async (nftProject) => {
    if (account && isValidNetwork) {
      const price = await nftPriceConsumer.getFloorPrice(nftProject)
      setFloorPrice(parseFloat(formatEther(price)))
    }
  }

  const fetchNftFloorPrices = async () => {
    if (account && isValidNetwork) {
      const floorPrices = {}
      for (let i = 0; i < whitelistContractAddresses.length; i++) {
        logger(
          'fetchNftFloorPrices whitelistContractAddresses[i]',
          whitelistContractAddresses[i]
        )
        const price = await nftPriceConsumer.getFloorPrice(
          whitelistContractAddresses[i]
        )
        floorPrices[whitelistContractAddresses[i]] = parseFloat(
          formatEther(price)
        )
      }
      setFloorPrices(floorPrices)
    }
  }

  return {
    fetchNft,
    fetchNfts,
    formatNftData,
    fetchNftData,
    fetchPoolNftData,
    fetchNftFloorPrice,
    fetchNftFloorPrices,
    nftContract,
    fetchDefaultNftData,
  }
}

export default useNFT
