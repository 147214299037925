import React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import { theme, themeLight } from '../../config/theme'

import './Mobile.css'

function Mobile(props) {
  const {
    logo,
    sectionTitle,
    submitRepayText,
    submitRepayButton1Props,
    submitRepayButton2Props,
  } = props

  return (
    <div className='container-center-horizontal'>
      <div className='mobile screen'>
        <img className='logo-10' src={logo} />
        <div className='section-title-7 valign-text-middle'>{sectionTitle}</div>
        <div className='submit-repay-button-1'>
          <ThemeProvider theme={theme}>
            <StyledButton
              variant='contained'
              className='submit-repay-text valign-text-middle'
              onClick={() => window.open('https://fluidnft.org/')}
            >
              {submitRepayText}
            </StyledButton>
          </ThemeProvider>
        </div>
        <ThemeProvider theme={themeLight}>
          <div className='button-light '>
            <StyledButtonLight
              variant='contained'
              onClick={() => window.open('https://discord.gg/2N5wVwsRAe')}
            >
              {submitRepayButton1Props.children}
            </StyledButtonLight>
          </div>
          <div className='button-light '>
            <StyledButtonLight
              className='submit-repay-text-1 valign-text-middle oxanium-normal-black-25px'
              variant='contained'
              onClick={() => window.open('https://twitter.com/FluidNFT_')}
            >
              {submitRepayButton2Props.children}
            </StyledButtonLight>
          </div>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default Mobile

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 28,
  borderRadius: 10,
  height: 60,
  width: 400,
})

const StyledButtonLight = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 28,
  borderRadius: 10,
  height: 50,
  width: 400,
})
