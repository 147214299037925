import React, { useEffect, useState } from 'react'

import Slider from '@mui/material/Slider'
import { ThemeProvider } from '@mui/material/styles'

import { useAppContext } from '../../AppContext'
import { theme } from '../../config/theme'
import './RiskConfiguration.css'

function RiskConfiguration(props) {
  const {
    className,
    getSliderValue,
    sliderValue,
    minSliderValue,
    maxSliderValue,
    isRepay,
    showLiquidation = true,
  } = props
  const { crValue, liquidationValue, borrowBalance, floorPrice } =
    useAppContext()

  let maxValue = maxSliderValue ? maxSliderValue : 100
  let minValue = minSliderValue ? minSliderValue : 0

  const [_crValue, _setCrValue] = useState('--')
  const [_liquidationValue, _setLiquidationValue] = useState('--')

  useEffect(() => {
    if (!props.crValue && crValue != _crValue) {
      _setCrValue(crValue.toFixed(2))
    } else if (props.crValue && props.crValue != _crValue) {
      _setCrValue(props.crValue.toFixed(2))
    }
    if (liquidationValue != _liquidationValue) {
      _setLiquidationValue(
        liquidationValue.toLocaleString('en', {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })
      )
    }
  }, [props.crValue, crValue, liquidationValue])

  return (
    <div className={`risk-configuration ${className || ''}`}>
      <div className='title-container oxanium-medium-granite-gray-15px'>
        <div className='liquidation-price-title valign-text-middle'>
          {showLiquidation && <span>Liquidation Price</span>}
        </div>
        <div className='collateralization-ratio-title valign-text-middle'>
          Collateralization Ratio
        </div>
      </div>
      <div className='amount-container oxanium-medium-black-18px'>
        <div className='liquidation-price-amount valign-text-middle1'>
          {showLiquidation && (
            <>
              <div className='liquidation-price-amount2'>
                {_liquidationValue}
              </div>
              <img className='weth-logo-31' src='/img/weth-logo-3@2x.svg' />
            </>
          )}
        </div>
        <div className='collateralization-ratio-amount valign-text-middle'>
          {_crValue}%
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Slider
          aria-label='Temperature'
          defaultValue={75}
          color='primary'
          value={sliderValue}
          onChange={getSliderValue}
          max={maxValue}
          min={minValue}
        />
      </ThemeProvider>
      <div className='risk-m-container oxanium-medium-granite-gray-15px'>
        <div className='risk-min valign-text-middle'>
          {isRepay ? 'Increase Risk' : 'Decrease Risk'}
        </div>
        <div className='risk-max valign-text-middle'>
          {isRepay ? 'Decrease Risk' : 'Increase Risk'}
        </div>
      </div>
    </div>
  )
}

export default RiskConfiguration
