import React from "react";
import "./RowItem2.css";

function RowItem2(props) {
  const { borrowAmount } = props;

  return (
    <div className="row-item-2">
      <div className="header-item-text-8 valign-text-middle oxanium-normal-black-18px">{borrowAmount}</div>
      <img className="weth-icon-21" src="/img/weth-icon-31@2x.svg" />
    </div>
  );
}

export default RowItem2;
