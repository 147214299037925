import React from "react";
import ModuloApprove from "../ModuloApprove";
import ModuloConfirm from "../ModuloConfirm";
import "./ModuloRedeem.css";

function ModuloRedeem(props) {
  const {
    moduloTitle,
    subTitle,
    repayAmount,
    repayAmountUsd,
    nftName,
    nftProjectName,
    nftImage
  } = props;

  return (
    <div className="container-center-horizontal">
      {/* <div className="overlap-group2-2">
        
      </div> */}
      <div className="overlap-group1-6">
        <img className="data-line-2" src="/img/data-line@1x.svg" /> 
        <div className="modulo-title-2 valign-text-middle oxanium-medium-black-30px">{moduloTitle}</div>
        <img className="header-line-2" src="/img/data-line@1x.svg" />
        <div className="overlap-group3">

          <div className="overlap-group-17">
            <div className="amount-weth-2 valign-text-middle oxanium-medium-black-30px">{repayAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 4})}</div>
            <div className="amount-usd-2 valign-text-middle oxanium-medium-star-dust-16px">${repayAmountUsd.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
            <div className="repay-title-2 valign-text-middle oxanium-medium-granite-gray-16px">{subTitle}</div>
            <img className="weth-logo-2" src="/img/weth-logo-4@2x.svg" />
          </div>
          <div className="modulo-collateral-2">
            <img className="nft-icon" src={nftImage} />
            <div className="collateral-container">
              <div className="nft-title-2 valign-text-middle oxanium-medium-black-30px">{nftName}</div>
              <div className="nft-project-2 valign-text-middle oxanium-medium-gravel-20px">{nftProjectName}</div>
            </div>
          </div>
        </div>
        <ModuloApprove
          approveText="Approve WETH"
          approveDescription="To repay you must approve WETH, which requires a one-time gas fee."
        />
        <ModuloConfirm
          confirmText={`Confirm ${repayAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 4})} WETH repayment`}
          confirmDescription="Accept the signature request in your wallet and wait for your repayment to process."
        />
        <ModuloConfirm
          confirmText="Success!"
          confirmDescription={`Your borrow is no longer up for liquidation. If the full amount was repaid, the NFT will be sent back to your wallet. This will be visible in "My Portfolio."`}
          className="modulo-1"
        />
      </div>
    </div>
  );
}

export default ModuloRedeem;
