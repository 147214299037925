import sugarAbbreviate from 'sugar/number/abbr'

export const currencyRenderer = (value: number | string) => {
  const formattedNumber = Number(value)
  if (
    isNaN(formattedNumber) ||
    typeof formattedNumber !== 'number' ||
    formattedNumber === 0
  )
    return '--'
  return `$ ${sugarAbbreviate(formattedNumber, 2).toUpperCase()}`
}
