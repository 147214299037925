import { useContract } from "./useContract";
import useIsValidNetwork from "./useIsValidNetwork";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "../AppContext";
import { formatUnits } from "@ethersproject/units";
import FTokenData from  "../artifacts/contracts/FToken/FToken.json";

export const useFToken = () => {
    
    const { account } = useWeb3React();
    const { isValidNetwork } = useIsValidNetwork();
    // FTokens
    const fTokenContractAddressWETH = process.env.REACT_APP_N_TOKEN_WETH_CONTRACT_ADDRESS; 
    // AssetTokens
    const assetTokenContractAddressWETH = process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS;
    const assetTokenContractAddress = {
        "WETH": assetTokenContractAddressWETH
    }
    const fTokenABI = FTokenData["abi"];
    const fTokenContractWETH = useContract(fTokenContractAddressWETH, fTokenABI);
    const fTokenContract = {
        "WETH": fTokenContractWETH
    }
    const { setFTokenBalanceWETH, setFTokenYieldWETH, setFTokenSupplyWETH } = useAppContext();

    const fetchFTokenBalance = async () => {
        const fTokenBalanceWETH = await fTokenContractWETH.balanceOf(account);
        setFTokenBalanceWETH(formatUnits(fTokenBalanceWETH, 18));
    };

    
    const fetchFTokenYield = async () => {    
        // TODO: investigate update replacement from nTOken getCurrentAPY
        // const fTokenYieldWETH = await fTokenContractWETH.getCurrentAPY();
        // setFTokenYieldWETH(formatUnits(fTokenYieldWETH, 18));
    };

    const fetchFTokenSupply = async () => {
        // TODO: investigate why this causes error
        // const fTokenSupplyWETH = await fTokenContractWETH.totalSupply();
        // setFTokenSupplyWETH(formatUnits(fTokenSupplyWETH, 18));
    }

    return {
        fetchFTokenBalance,
        fetchFTokenYield,
        fetchFTokenSupply,
        assetTokenContractAddress,
        fTokenContract
    }
};

export default useFToken;