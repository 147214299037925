import React from 'react'

import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { ThemeProvider, styled } from '@mui/material/styles'

import { useAppContext } from '../../AppContext'
import { theme } from '../../config/theme'
import './SubnavPortfolio4.css'

function SubnavPortfolio4(props) {
  const { className } = props
  const { isMyPortfolioBorrow, setMyPortfolioBorrow } = useAppContext()

  const handleChange = (event, newState) => {
    if (newState !== null) {
      setMyPortfolioBorrow(newState)
    }
  }

  return (
    <div className={`subnav-portfolio ${className || ''}`}>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          color='primary'
          value={isMyPortfolioBorrow}
          exclusive
          onChange={handleChange}
        >
          <StyledToggleButton value={true}>Borrow</StyledToggleButton>
          <StyledToggleButton value={false}>Repay</StyledToggleButton>
        </ToggleButtonGroup>
      </ThemeProvider>
    </div>
  )
}

export default SubnavPortfolio4

const StyledToggleButton = styled(ToggleButton)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 18,
  borderRadius: 5,
})
