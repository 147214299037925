import React from 'react'

import { Link, useLocation, useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { Tabs, Tab } from '@mui/material'
import debug from 'debug'
import 'regenerator-runtime/runtime'

import NavBalanceContainer from '../NavBalanceContainer'
import { useAppContext } from '../../AppContext'
import { useActivateWallet } from '../../hooks/useActivateWallet'
import { theme } from '../../config/theme'
import './Nav.css'

const logger = debug('fluidnft:Nav')

function Nav() {
  const { setPath } = useAppContext()

  let location = useLocation()
  let history = useHistory()
  const [active, connect] = useActivateWallet()

  const getPath = () => {
    logger('location.pathname', location.pathname)
    logger('location.pathname.split()', location.pathname.split('/')[1])
    let value = 0
    let pathName = location.pathname.split('/')[1]
    if (pathName == 'lend' || pathName == 'lend') {
      value = 1
    } else if (pathName == 'snipe' || pathName == 'borrow') {
      value = 2
    }
    return value
  }

  const handleClick = (v) => {
    setPath(v)
    if (v == 0) {
      history.push('/')
    } else if (v == 1) {
      history.push('/lend')
    } else if (v == 2) {
      history.push('/snipe')
    }
  }

  return (
    <div className='nav'>
      <div className='logo-container'>
        <Link to='/'>
          <img className='logo' src='/img/logo@2x.svg' />
        </Link>
        <div className='overlap-group-2'>
          <div className='beta-text valign-text-middle oxanium-semi-bold-white-14-8px'>
            BETA
          </div>
        </div>
      </div>
      <div className='nav-menu'>
        <Tabs value={getPath()} centered>
          <StyledTab label='Borrow' onClick={() => handleClick(0)} />
          <StyledTab label='Lend' onClick={() => handleClick(1)} />
          <StyledTab label='Snipe' onClick={() => handleClick(2)} />
        </Tabs>
      </div>
      {active ? (
        <NavBalanceContainer />
      ) : (
        <div className='connect-button'>
          <ThemeProvider theme={theme}>
            <ConnectButton
              variant='contained'
              className='connect-button'
              onClick={connect}
            >
              Connect wallet
            </ConnectButton>
          </ThemeProvider>
        </div>
      )}
    </div>
  )
}

export default Nav

const ConnectButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 24,
  borderRadius: 10,
  height: 48,
  width: 286,
})

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 18,
  height: 48,
  width: 160,
})
