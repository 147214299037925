import React, { useEffect } from 'react'

import { useWeb3React } from '@web3-react/core'
import { useParams } from 'react-router-dom'
import debug from 'debug'

import { useAppContext } from '../../AppContext'
import Borrow from '../Borrow'
import Repay from '../Repay'
import { useNFT } from '../../hooks/useNFT'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import { useInterestRate } from '../../hooks/useInterestRate'
import './Asset.css'

const logger = debug('fluidnft:Asset')

function Asset(props) {
  const {
    sectionTitle, // Borrow + Repay, here and below
    floorPriceTitle,
    inputTitle,
    dashboardProps,
    subnavPortfolio4Props,
    floorPriceWeth,
    floorPriceUsd, // Repay only, here and below
    riskConfigurationProps,
  } = props
  const {
    isMyPortfolioBorrow,
    nft,
    setNftTokenAddress,
    nftTokenAddress,
    setNftTokenId,
    nftTokenId,
    setNftCollectionName,
    nftCollectionName,
    setNftCollectionSymbol,
    nftCollectionSymbol,
    setNftImage,
    nftImage,
    floorPrice,
    floorPrices,
  } = useAppContext()
  const { account } = useWeb3React()
  const { tokenAddress, tokenId } = useParams()
  const { fetchNft, formatNftData, fetchNftFloorPrice } = useNFT()
  const { fetchUserBorrows } = useCollateralManager()
  const { fetchInterestRate } = useInterestRate()
  let nftData = {}
  let nftFloorPrice = 100 //"--";

  useEffect(() => {
    fetchNft(tokenAddress, tokenId)
    fetchNftFloorPrice(tokenAddress)
  }, [])

  useEffect(() => {
    fetchNftFloorPrice(tokenAddress)
    if (account) {
      fetchInterestRate(tokenAddress)
      fetchUserBorrows()
      logger('RUN fetchUserBorrows!!!!!!!!!!!!!!!!')
    }
  }, [account])

  useEffect(() => {
    if (Object.keys(nft).length !== 0) {
      nftData = formatNftData(nft)
      setNftTokenAddress(nftData.tokenAddress)
      setNftTokenId(nftData.tokenId)
      setNftCollectionName(nftData.collectionName)
      setNftCollectionSymbol(nftData.collectionSymbol)
      setNftImage(nftData.image)
    }
  }, [nft])

  useEffect(() => {
    nftFloorPrice = floorPrice
  }, [floorPrice])

  return (
    <>
      {isMyPortfolioBorrow ? (
        <Borrow
          sectionTitle={sectionTitle}
          floorPriceTitle={floorPriceTitle}
          inputTitle={inputTitle}
          dashboardProps={dashboardProps}
          subnavPortfolio4Props={subnavPortfolio4Props}
          tokenAddress={tokenAddress}
          tokenId={tokenId}
          nftName={
            nftCollectionSymbol
              ? `${nftCollectionSymbol} #${tokenId}`
              : `#${tokenId}`
          }
          nftProjectName={nftCollectionName}
          nftImage={nftImage}
        />
      ) : (
        <Repay
          sectionTitle={sectionTitle}
          floorPriceTitle={floorPriceTitle}
          inputTitle={inputTitle}
          dashboardProps={dashboardProps}
          subnavPortfolio4Props={subnavPortfolio4Props}
          floorPriceWeth={floorPriceWeth}
          floorPriceUsd={floorPriceUsd}
          riskConfigurationProps={riskConfigurationProps}
          tokenAddress={tokenAddress}
          tokenId={tokenId}
          nftName={
            nftCollectionSymbol
              ? `${nftCollectionSymbol} #${tokenId}`
              : `#${tokenId}`
          }
          nftProjectName={nftCollectionName}
          nftImage={nftImage}
        />
      )}
    </>
  )
}

export default Asset
