import React, { createContext, useReducer } from 'react';

const initialContext = {
  availableLiquidity: 0,
  setAvailableLiquidity: () => {},
  ethUsdPrice: 0,
  setEthUsdPrice: () => {},
  inputValue: 0,
  setInputValue: () => {},
  minSliderValue: 0,
  setMinSliderValue: () => {},
  maxSliderValue: 100,
  setMaxSliderValue: () => {},
  crValue: "--",
  setCrValue: () => {},
  liquidationValue: "--",
  setLiquidationValue: () => {},
  interestRate: "--",
  setInterestRate: () => {}, 
  borrowBalance: "--",
  setBorrowBalance: () => {},
  floorPrice: "--",
  setFloorPrice: () => {},
  floorPrices: {},
  setFloorPrices: () => {},
  txnStatus: "NOT_SUMBITTED",
  setTxnStatus: () => {},
  borrowToken: "WETH",
  setBorrowToken: () => {},
  borrowProject: "--",
  setBorrowProject: () => {},
  borrowFloorPrice: "--",
  setBorrowFloorPrice: () => {},
  userBorrows: {},
  setUserBorrows: () => {},
  borrowDefault: {},
  setBorrowDefault: () => {},
  borrowDefaults: [],
  setBorrowDefaults: () => {},
  borrowAPR: "--",
  setBorrowAPR: () => {},
  aprPUNK: "--",
  setAprPUNK: () => {},
  aprBAYC: "--",
  setAprBAYC: () => {},
  whitelistNFT: [],
  setWhitelistNFT: () => {},
  ethBalance: "--",
  setEthBalance: () => {},
  assetTokenAddress: "",
  setAssetTokenAddress: () => {},
  assetTokenBalanceWETH: "--",
  setAssetTokenBalanceWETH: () => {},
  debtTokenBalanceWETH: "--",
  setDebtTokenBalanceWETH: () => {},
  fTokenBalanceWETH: "--",
  setFTokenBalanceWETH: () => {},
  path: 0,
  setPath: () => {},
  pool: "",
  setPool: () => {},
  pools: [],
  setPools: () => {},
  poolSymbols: [],
  setPoolSymbols: () => {},
  poolCollectionNames: [],
  setPoolCollectionNames: () => {},
  poolCollectionImages: [],
  setPoolCollectionImages: () => {},
  poolCollectionAddresses: [],
  setPoolCollectionAddresses: () => {},
  defaultBorrowImages: [],
  setDefaultBorrowImages: () => {},
  borrow: null,
  setBorrow: () => {},
  borrows: [],
  setBorrows: () => {},
  borrowImages: [],
  setBorrowImages: () => {},
  borrowNames: [],
  setBorrowNames: () => {},
  borrowSymbols: [],
  setBorrowSymbols: () => {},
  borrowTokenIds: [],
  setBorrowTokenIds: () => {},
  searchSelection: null,
  setSearchSelection: () => {}, 
  nfts: {},
  setNfts: () => {},
  nft: {},
  setNft: () => {},
  nftTokenAddress: '1',
  setNftTokenAddress: () => {},
  nftTokenId: '2',
  setNftTokenId: () => {},
  nftName:'',
  setNftName: () => {},
  nftCollectionName:'',
  setNftCollectionName: () => {},
  nftCollectionSymbol: '',
  setNftCollectionSymbol: () => {},
  nftImage: '',
  setNftImage: () => {},
  ethBalance: '--',
  setEthBalance: () => {},
  wethBalance: '--',
  setWethBalance: () => {},
  wethUsdExchangeRate: 0,
  setWethUsdExchangeRate: () => {},
  isWhitelisted: true, 
  setWhitelisted: () => {},
  isMyPortfolioBorrow: true,
  setMyPortfolioBorrow: () => {},
  isLendingPoolDeposit: true,
  setLendingPoolDeposit: () => {},
  isLiquidationLiquidate: true,
  setLiquidationLiquidate: () => {},
  isWalletConnectionModalOpen: false,
  setWalletConnectModal: () => {},
  txnStatus: 'NOT_SUBMITTED',
  setTxnStatus: () => {},
};

const appReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_AVAILABLE_LIQUIDITY":
      return {
        ...state,
        availableLiquidity: payload,
      };

    case "SET_ETH_USD_PRICE":
      return {
        ...state,
        ethUsdPrice: payload,
      };

    case "SET_INPUT_VALUE":
      return {
        ...state,
        inputValue: payload,
      };

    case "SET_MIN_SLIDER_VALUE":
      return {
        ...state,
        minSliderValue: payload,
      };

    case "SET_MAX_SLIDER_VALUE":
      return {
        ...state,
        maxSliderValue: payload,
      };

    case "SET_CR_VALUE":
      return {
        ...state,
        crValue: payload,
      };

    case "SET_LIQUIDATION_VALUE":
      return {
        ...state,
        liquidationValue: payload,
      };

    case "SET_INTEREST_RATE":
      return {
        ...state,
        interestRate: payload,
      };

    case "SET_BORROW_BALANCE":
      return {
        ...state,
        borrowBalance: payload,
      };

    case "SET_FLOOR_PRICE":
      return {
        ...state,
        floorPrice: payload,
      };

    case "SET_FLOOR_PRICES":
      return {
        ...state,
        floorPrices: payload,
      };

    case "SET_TXN_STATUS":
      return {
        ...state,
        txnStatus: payload,
      };

    case "SET_BORROW_TOKEN":
      return {
        ...state,
        borrowToken: payload,
      }; 

    case "SET_BORROW_PROJECT":
      return {
        ...state,
        borrowProject: payload,
      }; 

    case "SET_BORROW_FLOOR_PRICE":
      return {
        ...state,
        borrowFloorPrice: payload,
      }; 

    case "SET_USER_BORROWS":
      return {
        ...state,
        userBorrows: payload,
      };

    case "SET_BORROW_DEFAULT":
        return {
          ...state,
          borrowDefault: payload,
        };        

    case "SET_BORROW_DEFAULTS":
      return {
        ...state,
        borrowDefaults: payload,
      };  

    case "SET_BORROW_APR":
      return {
        ...state,
        borrowAPR: payload,
      }; 

    case "SET_APR_PUNK":
      return {
        ...state,
        aprPUNK: payload,
      }; 

      case "SET_APR_BAYC":
      return {
        ...state,
        aprBAYC: payload,
      }; 

    case "SET_WHITELIST_NFT":
      return {
        ...state,
        whitelistNFT: payload,
      };  

    case "SET_ETH_BALANCE":
      return {
        ...state,
        ethBalance: payload,
      };

    case "SET_ASSET_TOKEN_ADDRESS":
      return {
        ...state,
        assetTokenAddress: payload,
      };

    case "SET_ASSET_TOKEN_BALANCE_WETH":
      return {
        ...state,
        assetTokenBalanceWETH: payload,
      };

    case "SET_DEBT_TOKEN_BALANCE_WETH":
      return {
        ...state,
        debtTokenBalanceWETH: payload,
      };

    case "SET_F_TOKEN_BALANCE_WETH":
      return {
        ...state,
        fTokenBalanceWETH: payload,
      };

    case 'SET_PATH':
      return {
        ...state,
        pool: payload,
      };

    case 'SET_POOL':
      return {
        ...state,
        pool: payload,
      };

    case 'SET_POOLS':
      return {
        ...state,
        pools: payload,
      };

    case 'SET_POOL_SYMBOLS':
      return {
        ...state,
        poolSymbols: payload,
      };

    case 'SET_POOL_COLLECTION_NAMES':
      return {
        ...state,
        poolCollectionNames: payload,
      };

    case 'SET_POOL_COLLECTION_IMAGES':
      return {
        ...state,
        poolCollectionImages: payload,
      };

    case 'SET_POOL_COLLECTION_ADDRESSES':
      return {
        ...state,
        poolCollectionAddresses: payload,
      };

    case 'SET_DEFAULT_BORROW_IMAGES':
      return {
        ...state,
        defaultBorrowImages: payload,
      };
        
      case 'SET_BORROW':
      return {
        ...state,
        borrow: payload,
      };

    case 'SET_BORROWS':
      return {
        ...state,
        borrows: payload,
      };

    case 'SET_BORROW_IMAGES':
      return {
        ...state,
        borrowImages: payload,
      };

    case 'SET_BORROW_NAMES':
      return {
        ...state,
        borrowNames: payload,
      };

    case 'SET_BORROW_SYMBOLS':
      return {
        ...state,
        borrowSymbols: payload,
      };

    case 'SET_BORROW_TOKEN_IDS':
      return {
        ...state,
        borrowTokenIds: payload,
      };

    case 'SET_SEARCH_SELECTION':
      return {
        ...state,
        searchSelection: payload,
      };

    case 'SET_NFTS':
      return {
        ...state,
        nfts: payload,
      };

    case 'SET_NFT':
        return {
          ...state,
          nft: payload,
        };

    case 'SET_NFT_TOKEN_ADDRESS':
      return {
        ...state,
        nftTokenAddress: payload,
      };

    case 'SET_NFT_TOKEN_ID':
      return {
        ...state,
        nftTokenId: payload,
      };

    case 'SET_NFT_NAME':
      return {
        ...state,
        nftName: payload,
      };
      
    case 'SET_NFT_COLLECTION_NAME':
      return {
        ...state,
        nftCollectionName: payload,
      };

    case 'SET_NFT_COLLECTION_SYMBOL':
      return {
        ...state,
        nftCollectionSymbol: payload,
      };

    case 'SET_NFT_IMAGE':
      return {
        ...state,
        nftImage: payload,
      };

    case 'SET_ETH_BALANCE':
      return {
        ...state,
        ethBalance: payload,
      };

    case 'SET_WETH_BALANCE':
      return {
        ...state,
        wethBalance: payload,
      };

    case 'SET_WETH_USD_EXCHANGE_RATE':
      return {
        ...state,
        wethUsdExchangeRate: payload,
      };

    case 'SET_WHITELISTED':
      return {
        ...state,
        isWhitelisted: payload,
      };

    case 'SET_MY_PORTFOLIO_BORROW':
      return {
        ...state,
        isMyPortfolioBorrow: payload,
      };

    case 'SET_LENDING_POOL_DEPOSIT':
      return {
        ...state,
        isLendingPoolDeposit: payload,
      };

    case 'SET_LIQUIDATION_LIQUIDATE':
      return {
        ...state,
        isLiquidationLiquidate: payload,
      };

    case 'SET_WALLET_MODAL':
      return {
        ...state,
        isWalletConnectModalOpen: payload,
      };

    case 'SET_TXN_STATUS':
      return {
        ...state,
        txnStatus: payload,
      };

    default:
      return state;
  }
};

const AppContext = createContext(initialContext);
export const useAppContext = () => React.useContext(AppContext);
export const AppContextProvider = ({ children }) => {
  const [store, dispatch] = useReducer(appReducer, initialContext);

  const contextValue = {
    availableLiquidity: store.availableLiquidity,
    setAvailableLiquidity: (liquidity) => {
      dispatch({ type: "SET_AVAILABLE_LIQUIDITY", payload: liquidity });
    },
    ethUsdPrice: store.ethUsdPrice,
    setEthUsdPrice: (price) => {
      dispatch({ type: "SET_ETH_USD_PRICE", payload: price });
    },
    inputValue: store.inputValue,
    setInputValue: (value) => {
      dispatch({ type: "SET_INPUT_VALUE", payload: value });
    },
    minSliderValue: store.minSliderValue,
    setMinSliderValue: (value) => {
      dispatch({ type: "SET_MIN_SLIDER_VALUE", payload: value });
    },
    maxSliderValue: store.maxSliderValue,
    setMaxSliderValue: (value) => {
      dispatch({ type: "SET_MAX_SLIDER_VALUE", payload: value });
    },    
    crValue: store.crValue,
    setCrValue: (value) => {
      dispatch({ type: "SET_CR_VALUE", payload: value });
    },
    liquidationValue: store.liquidationValue,
    setLiquidationValue: (value) => {
      dispatch({ type: "SET_LIQUIDATION_VALUE", payload: value });
    },    
    interestRate: store.interestRate,
    setInterestRate: (rate) => {
      dispatch({ type: "SET_INTEREST_RATE", payload: rate });
    },
    borrowBalance: store.borrowBalance,
    setBorrowBalance: (balance) => {
      dispatch({ type: "SET_BORROW_BALANCE", payload: balance });
    },
    floorPrice: store.floorPrice,
    setFloorPrice: (price) => {
      dispatch({ type: "SET_FLOOR_PRICE", payload: price });
    },
    floorPrices: store.floorPrices,
    setFloorPrices: (prices) => {
      dispatch({ type: "SET_FLOOR_PRICES", payload: prices });
    },
    txnStatus: store.txnStatus,
    setTxnStatus: (status) => {
      dispatch({ type: "SET_TXN_STATUS", payload: status });
    },
    borrowToken: store.borrowToken,
    setBorrowToken: (token) => {
      dispatch({ type: "SET_BORROW_TOKEN", payload: token});
    },
    borrowProject: store.borrowProject,
    setBorrowProject: (projectSymbol) => {
      dispatch({ type: "SET_BORROW_PROJECT", payload: projectSymbol});
    },
    borrowFloorPrice: store.borrowFloorPrice,
    setBorrowFloorPrice: (price) => {
      dispatch({ type: "SET_BORROW_FLOOR_PRICE", payload: price});
    },
    userBorrows: store.userBorrows,
    setUserBorrows: (userBorrows) => {
      dispatch({ type: "SET_USER_BORROWS", payload: userBorrows});
    },
    borrowDefault: store.borrowDefault,
    setBorrowDefault: (default_) => {
      dispatch({ type: "SET_BORROW_DEFAULT", payload: default_});
    },
    borrowDefaults: store.borrowDefaults,
    setBorrowDefaults: (defaults) => {
      dispatch({ type: "SET_BORROW_DEFAULTS", payload: defaults});
    },
    borrowAPR: store.borrowAPR,
    setBorrowAPR: (apr) => {
      dispatch({ type: "SET_BORROW_APR", payload: apr});
    },
    aprPUNK: store.aprPUNK,
    setAprPUNK: (apr) => {
      dispatch({ type: "SET_APR_PUNK", payload: apr});
    },
    aprBAYC: store.aprBAYC,
    setAprBAYC: (apr) => {
      dispatch({ type: "SET_APR_BAYC", payload: apr});
    },
    whitelistNFT: store.whitelistNFT,
    setWhitelistNFT: (whitelist) => {
      dispatch({ type: "SET_WHITELIST_NFT", payload: whitelist});
    },
    ethBalance: store.ethBalance,
    setEthBalance: (balance) => {
      dispatch({ type: "SET_ETH_BALANCE", payload: balance});
    },
    assetTokenAddress: store.assetTokenAddress,
    setAssetTokenAddress: (address) => {
      dispatch({ type: "SET_ASSET_TOKEN_ADDRESS", payload: address});
    },
    assetTokenBalanceWETH: store.assetTokenBalanceWETH,
    setAssetTokenBalanceWETH: (balance) => {
      dispatch({ type: "SET_ASSET_TOKEN_BALANCE_WETH", payload: balance});
    },
    debtTokenBalanceWETH: store.debtTokenBalanceWETH,
    setDebtTokenBalanceWETH: (balance) => {
      dispatch({ type: "SET_DEBT_TOKEN_BALANCE_WETH", payload: balance});
    }, 
    fTokenBalanceWETH: store.fTokenBalanceWETH,
    setFTokenBalanceWETH: (balance) => {
      dispatch({ type: "SET_F_TOKEN_BALANCE_WETH", payload: balance});
    },    
    path: store.path,
    setPath: (path) => {
      dispatch({ type: 'SET_PATH', payload: path });
    },
    pool: store.pool,
    setPool: (pool) => {
      dispatch({ type: 'SET_POOL', payload: pool });
    },
    pools: store.pools,
    setPools: (pools) => {
      dispatch({ type: 'SET_POOLS', payload: pools });
    },
    poolSymbols: store.poolSymbols,
    setPoolSymbols: (symbols) => {
      dispatch({ type: 'SET_POOL_SYMBOLS', payload: symbols });
    },
    poolCollectionNames: store.poolCollectionNames,
    setPoolCollectionNames: (names) => {
      dispatch({ type: 'SET_POOL_COLLECTION_NAMES', payload: names });
    },
    poolCollectionImages: store.poolCollectionImages,
    setPoolCollectionImages: (images) => {
      dispatch({ type: 'SET_POOL_COLLECTION_IMAGES', payload: images });
    },
    poolCollectionAddresses: store.poolCollectionAddresses,
    setPoolCollectionAddresses: (addresses) => {
      dispatch({ type: 'SET_POOL_COLLECTION_ADDRESSES', payload: addresses });
    },
    defaultBorrowImages: store.defaultBorrowImages,
    setDefaultBorrowImages: (images) => {
      dispatch({ type: 'SET_DEFAULT_BORROW_IMAGES', payload: images });
    },
    borrow: store.borrow,
    setBorrow: (borrow) => {
      dispatch({ type: 'SET_BORROW', payload: borrow });
    },
    borrows: store.borrows,
    setBorrows: (borrows) => {
      dispatch({ type: 'SET_BORROWS', payload: borrows });
    },
    borrowImages: store.borrowImages,
    setBorrowImages: (images) => {
      dispatch({ type: 'SET_BORROW_IMAGES', payload: images });
    },
    borrowNames: store.borrowNames,
    setBorrowNames: (names) => {
      dispatch({ type: 'SET_BORROW_NAMES', payload: names });
    },  
    borrowSymbols: store.borrowSymbols,
    setBorrowSymbols: (symbols) => {
      dispatch({ type: 'SET_BORROW_SYMBOLS', payload: symbols });
    },  
    borrowTokenIds: store.borrowTokenIds,
    setBorrowTokenIds: (tokenIds) => {
      dispatch({ type: 'SET_BORROW_TOKEN_IDS', payload: tokenIds });
    },    
    searchSelection: store.searchSelection,
    setSearchSelection: (selection) => {
      dispatch({ type: 'SET_SEARCH_SELECTION', payload: selection });
    },
    nfts: store.nfts,
    setNfts: (nfts) => {
      dispatch({ type: 'SET_NFTS', payload: nfts });
    },
    nft: store.nft,
    setNft: (nft) => {
      dispatch({ type: 'SET_NFT', payload: nft });
    },
    nftTokenAddress: store.nftTokenAddress,
    setNftTokenAddress: (address) => {
      dispatch({ type: 'SET_NFT_TOKEN_ADDRESS', payload: address });
    },
    nftTokenId: store.nftTokenId,
    setNftTokenId: (tokenId) => {
      dispatch({ type: 'SET_NFT_TOKEN_ID', payload: tokenId });
    },
    nftName: store.nftName,
    setNftName: (name) => {
      dispatch({ type: 'SET_NFT_NAME', payload: name });
    },
    nftCollectionName: store.nftCollectionName,
    setNftCollectionName: (name) => {
      dispatch({ type: 'SET_NFT_COLLECTION_NAME', payload: name });
    },
    nftCollectionSymbol: store.nftCollectionSymbol,
    setNftCollectionSymbol: (symbol) => {
      dispatch({ type: 'SET_NFT_COLLECTION_SYMBOL', payload: symbol });
    },
    nftImage: store.nftImage,
    setNftImage: (image) => {
      dispatch({ type: 'SET_NFT_IMAGE', payload: image });
    },
    ethBalance: store.ethBalance,
    setEthBalance: (balance) => {
      dispatch({ type: 'SET_ETH_BALANCE', payload: balance });
    },    
    wethBalance: store.wethBalance,
    setWethBalance: (balance) => {
      dispatch({ type: 'SET_WETH_BALANCE', payload: balance });
    },
    wethUsdexchangeRate: store.wethUsdexchangeRate,
    setWethUsdExchangeRate: (rate) => {
      dispatch({ type: 'SET_WETH_USD_EXCHANGE_RATE', payload: rate });
    },
    isWhitelisted: store.isWhitelisted,
    setWhitelisted: (isWhitelisted) => {
      dispatch({ type: 'SET_WHITELISTED', payload: isWhitelisted });
    },
    isMyPortfolioBorrow: store.isMyPortfolioBorrow,
    setMyPortfolioBorrow: (isBorrow) => {
      dispatch({ type: 'SET_MY_PORTFOLIO_BORROW', payload: isBorrow });
    },
    isLendingPoolDeposit: store.isLendingPoolDeposit,
    setLendingPoolDeposit: (isDeposit) => {
      dispatch({ type: 'SET_LENDING_POOL_DEPOSIT', payload: isDeposit });
    },
    isLiquidationLiquidate: store.isLiquidationLiquidate,
    setLiquidationLiquidate: (isLiquidate) => {
      dispatch({ type: 'SET_LIQUIDATION_LIQUIDATE', payload: isLiquidate });
    },
    isWalletConnectModalOpen: store.isWalletConnectModalOpen,
    setWalletConnectModal: (open) => {
      dispatch({ type: 'SET_WALLET_MODAL', payload: open });
    },
    txnStatus: store.txnStatus,
    setTxnStatus: (status) => {
      dispatch({ type: 'SET_TXN_STATUS', payload: status });
    },
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};