import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: 'Oxanium',
  },
  palette: {
    primary: {
      main: '#000000',
    },
    action: {
      disabledBackground: '#959696',
      disabled: '#ffffff',
    },
  },
})

export const themeLight = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    action: {
      disabledBackground: '#959696',
      disabled: '#000000',
    },
  },
})
