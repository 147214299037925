import React from 'react'
import Nav from '../Nav'
import Dashboard from '../Dashboard'
import SubnavPortfolio4 from '../SubnavPortfolio4'
import Search from '../Search'
import Toggle from '../Toggle'
import NFT from '../NFT'
import './Home.css'
import { styled } from '@mui/system'
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../connectors'
import 'regenerator-runtime/runtime'
import MyPortfolio from '../MyPortfolio'
import Borrows from '../Borrows'

function Home(props) {
  const {
    sectionTitle,
    toggleText,
    dashboardProps,
    nFT7Props,
    headerItemText1,
    headerItemText2,
    headerItemText3,
    headerItemText4,
    headerItemText5,
    headerItemText6,
    headerItemText7,
    headerItemText8,
    headerItemText9,
    headerItemText10,
    searchProps,
    rowItemProps,
  } = props
  const { fetchNfts, formatNftData } = useNFT()
  const {
    nfts,
    setWhitelisted,
    isWhitelisted,
    setNftTokenAddress,
    setNftTokenId,
    setNftCollectionName,
    setNftCollectionSymbol,
    setNftImage,
    nftImage,
    searchSelection,
    isMyPortfolioBorrow,
  } = useAppContext()
  const { account } = useWeb3React()

  return (
    <>
      {isMyPortfolioBorrow ? (
        <MyPortfolio
          sectionTitle={sectionTitle}
          toggleText='Whitelisted'
          dashboardProps={dashboardProps}
          nFT7Props={nFT7Props}
        />
      ) : (
        <Borrows
          sectionTitle={sectionTitle}
          headerItemText1={headerItemText1}
          headerItemText2={headerItemText2}
          headerItemText3={headerItemText3}
          headerItemText4={headerItemText4}
          headerItemText5={headerItemText5}
          headerItemText6={headerItemText6}
          headerItemText7={headerItemText7}
          headerItemText8={headerItemText8}
          headerItemText9={headerItemText9}
          headerItemText10={headerItemText10}
          dashboardProps={dashboardProps}
          searchProps={searchProps}
          rowItemProps={rowItemProps}
        />
      )}
    </>
  )
}

export default Home

const black = {
  500: '#000000',
}

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
}

const Root = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
  margin: 9px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${theme.palette.mode === 'dark' ? grey[600] : grey[400]};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 20px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${black[500]};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
)
