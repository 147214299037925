import React from "react";
import { Link } from "react-router-dom";
import DashboardAverageCr2 from "../DashboardAverageCr2";
import SubnavPortfolio2 from "../SubnavPortfolio2";
import RiskConfiguration2 from "../RiskConfiguration2";
import "./Dark.css";

function Dark(props) {
  const {
    logoDarkMode,
    betaText,
    navMenuTextSelected,
    navMenuText1,
    navMenuText2,
    balanceEth,
    wethBalance,
    balanceAccountAddress,
    liquidityTitle,
    liquidityAmountWeth,
    liquidityAmountUsd,
    borrowTitle,
    borrowAmountWeth,
    borrowAmountUsd,
    depositTitle,
    depositAmountWeth,
    depositUsd,
    sectionTitle,
    borrowItemTitle,
    borrowBalanceWeth,
    borrowBalanceUsd,
    floorPriceWeth,
    floorPriceTitle,
    floorPriceUsd,
    borrowInterestTitle,
    borrowInterestRate,
    borrowItemProject,
    borrowItem,
    inputTitle,
    dashboardAverageCr21Props,
    dashboardAverageCr22Props,
    subnavPortfolio2Props,
    riskConfiguration2Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="dark screen">
        <div className="nav-10">
          <img className="logo-dark-mode" src={logoDarkMode} />
          <div className="overlap-group2-12">
            <div className="beta-text-10 valign-text-middle">{betaText}</div>
          </div>
          <div className="nav-menu-10">
            <div className="nav-menu-text-selected-10 valign-text-middle">{navMenuTextSelected}</div>
            <Link to="/lend">
              <div className="nav-menu-text-20 valign-text-middle oxanium-medium-celeste-28px">{navMenuText1}</div>
            </Link>
            <Link to="/snipe">
              <div className="nav-menu-text-21 valign-text-middle oxanium-medium-celeste-28px">{navMenuText2}</div>
            </Link>
          </div>
          <img className="experimental-sun" src="/img/experimental---sun@2x.svg" />
          <div className="nav-menu-balance-10">
            <div className="balance-eth-10 valign-text-middle oxanium-medium-gallery-20px">{balanceEth}</div>
            <div className="weth-container-26">
              <div className="weth-pill-12"></div>
              <div className="weth-container-27">
                <div className="weth-balance-10 valign-text-middle oxanium-medium-gallery-20px">{wethBalance}</div>
                <img className="weth-icon-40" src="/img/weth-icon-26@2x.svg" />
              </div>
            </div>
            <div className="balance-account-container-10">
              <div className="balance-account-pill-10"></div>
              <div className="balance-account-icon-10"></div>
              <div className="balance-account-address-10 valign-text-middle oxanium-medium-gallery-20px">
                {balanceAccountAddress}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-container-8">
          <div className="dashboard-liquidity-4">
            <div className="x-title-4 valign-text-middle oxanium-medium-star-dust-24px">{liquidityTitle}</div>
            <div className="liquidity-amount-4">
              <div className="liquidity-amount-weth-4 valign-text-middle oxanium-medium-white-40px">
                {liquidityAmountWeth}
              </div>
              <img className="weth-icon-39" src="/img/weth-icon-23@2x.svg" />
            </div>
            <div className="t-usd valign-text-middle oxanium-medium-granite-gray-22px">{liquidityAmountUsd}</div>
          </div>
          <div className="dashboard-borrow-4">
            <div className="x-title-4 valign-text-middle oxanium-medium-star-dust-24px">{borrowTitle}</div>
            <div className="borrow-amount-4">
              <div className="borrow-amount-weth-4 valign-text-middle oxanium-medium-white-40px">
                {borrowAmountWeth}
              </div>
              <img className="weth-icon-39" src="/img/weth-icon-23@2x.svg" />
            </div>
            <div className="t-usd valign-text-middle oxanium-medium-granite-gray-22px">{borrowAmountUsd}</div>
          </div>
          <div className="dashboard-deposit-4">
            <div className="x-title-4 valign-text-middle oxanium-medium-star-dust-24px">{depositTitle}</div>
            <div className="deposit-amount-4">
              <div className="deposit-amount-weth-4 valign-text-middle oxanium-medium-white-40px">
                {depositAmountWeth}
              </div>
              <img className="weth-icon-39" src="/img/weth-icon-23@2x.svg" />
            </div>
            <div className="t-usd valign-text-middle oxanium-medium-granite-gray-22px">{depositUsd}</div>
          </div>
        </div>
        <div className="dashboard-container-9">
          <DashboardAverageCr2
            averageCrTitle={dashboardAverageCr21Props.averageCrTitle}
            averageCrAmount={dashboardAverageCr21Props.averageCrAmount}
          />
          <DashboardAverageCr2
            averageCrTitle={dashboardAverageCr22Props.averageCrTitle}
            averageCrAmount={dashboardAverageCr22Props.averageCrAmount}
            className={dashboardAverageCr22Props.className}
          />
        </div>
        <div className="flex-row-14">
          <a href="javascript:history.back()" className="align-self-flex-center">
            <img className="back-arrow-6" src="/img/back-arrow-1@2x.svg" />
          </a>
          <div className="section-title-11 valign-text-middle">{sectionTitle}</div>
          <SubnavPortfolio2
            subnavButtonTextSelected={subnavPortfolio2Props.subnavButtonTextSelected}
            subnavButtonText={subnavPortfolio2Props.subnavButtonText}
          />
        </div>
        <img className="section-line-10" src="/img/section-line-2@1x.svg" />
        <div className="flex-row-15">
          <img className="nft-collection-icon-9" src="/img/nft-collection-icon-16@1x.svg" />
          <div className="overlap-group5-3">
            <div className="overlap-group4-4">
              <img className="section-divider-6" src="/img/section-divider-1@1x.svg" />
              <div className="borrow-item-balance-2">
                <div className="borrow-item-title-2 valign-text-middle oxanium-medium-star-dust-24px">
                  {borrowItemTitle}
                </div>
                <div className="weth-container-28">
                  <img className="weth-logo-10" src="/img/weth-logo-4@2x.svg" />
                  <div className="borrow-balance-weth-2 valign-text-middle oxanium-medium-white-40px">
                    {borrowBalanceWeth}
                  </div>
                </div>
                <div className="borrow-balance-usd-2 valign-text-middle oxanium-medium-granite-gray-24px">
                  {borrowBalanceUsd}
                </div>
              </div>
              <div className="borrow-item-floor-price-2">
                <div className="overlap-group2-13">
                  <img className="weth-logo-11" src="/img/weth-logo-5@2x.svg" />
                  <div className="floor-price-weth-2 valign-text-middle oxanium-medium-white-40px">
                    {floorPriceWeth}
                  </div>
                  <div className="floor-price-title-2 valign-text-middle oxanium-medium-star-dust-24px">
                    {floorPriceTitle}
                  </div>
                </div>
                <div className="floor-price-usd-2 valign-text-middle oxanium-medium-granite-gray-24px">
                  {floorPriceUsd}
                </div>
              </div>
            </div>
            <div className="borrow-item-interest-2">
              <div className="borrow-interest-title-2 valign-text-middle oxanium-medium-star-dust-24px">
                {borrowInterestTitle}
              </div>
              <div className="borrow-interest-rate-2 valign-text-middle oxanium-medium-white-40px">
                {borrowInterestRate}
              </div>
            </div>
            <div className="borrow-item-container">
              <div className="borrow-item-project-2 valign-text-middle oxanium-medium-celeste-28px">
                {borrowItemProject}
              </div>
              <h1 className="borrow-item-2 valign-text-middle">{borrowItem}</h1>
            </div>
          </div>
          <div className="flex-col-1">
            <div className="input-title-4 valign-text-middle">{inputTitle}</div>
            <img className="borrow-config-amount-6" src="/img/borrow-config-amount-1@1x.svg" />
            <RiskConfiguration2
              liquidationPriceTitle={riskConfiguration2Props.liquidationPriceTitle}
              collateralizationRatioTitle={riskConfiguration2Props.collateralizationRatioTitle}
              liquidationPriceAmount={riskConfiguration2Props.liquidationPriceAmount}
              collateralizationRatioAmount={riskConfiguration2Props.collateralizationRatioAmount}
              riskMin={riskConfiguration2Props.riskMin}
              riskMax={riskConfiguration2Props.riskMax}
            />
            <img className="submit-borrow-button-1" src="/img/submit-borrow-button-1@1x.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dark;
