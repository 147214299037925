import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import debug from 'debug'

import Nav from '../Nav'
import Dashboard from '../Dashboard'
import SubnavPortfolio4 from '../SubnavPortfolio4'
import SearchBorrow from '../SearchBorrow'
import RowItem from '../RowItem'
import RowItem2 from '../RowItem2'
import RowItem4 from '../RowItem4'
import './Borrows.css'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import { useNFT } from '../../hooks/useNFT'
import { useAppContext } from '../../AppContext'
import { useInterestRate } from '../../hooks/useInterestRate'

const logger = debug('fluidnft:Borrows')

function Borrows(props) {
  const {
    sectionTitle,
    headerItemText1,
    headerItemText2,
    headerItemText3,
    headerItemText4,
    headerItemText5,
    headerItemText6,
    headerItemText7,
    headerItemText8,
    headerItemText9,
    headerItemText10,
    dashboardProps,
    searchProps,
    rowItemProps,
  } = props
  const {
    userBorrows,
    borrowImages,
    borrowSymbols,
    borrowTokenIds,
    floorPrices,
    interestRate
  } = useAppContext()
  // const { fetchUserBorrows } = useBorrow();
  const { fetchUserBorrows } = useCollateralManager()
  const { fetchNftData, fetchNftFloorPrices } = useNFT()
  const { fetchInterestRate } = useInterestRate()
  const { account } = useWeb3React()

  const poolMinCr = 1.5 // TODO: take from protocol

  useEffect(() => {
    fetchUserBorrows()
    fetchNftFloorPrices()
    // TODO: fetch interst rate for each NFT project
    fetchInterestRate()
  }, [account])

  useEffect(() => {
    fetchNftData(Object.values(userBorrows))
  }, [userBorrows])

  // const filterBorrows = (borrows) => {
  //   let filtered = [];
  //   if (account) {
  //     borrows.map((borrow, index) => {
  //       if (borrow.ownerAddress === walletAddress) {
  //         filtered.push(borrow)
  //       }
  //     });
  //   }
  //   return filtered;
  // }

  // const [borrowData, setBorrowData] = useState({});
  // useEffect(() => {
  //   if (Object.keys(userBorrows).length !== 0) {
  //     setBorrowData(userBorrows);
  //   }
  // }, [userBorrows]);

  const formatValue = (value, decimals) => {
    return Number(value).toFixed(decimals)
  }

  return (
    <div className='container-center-horizontal'>
      <div className='borrows screen'>
        <Nav />
        <Dashboard
          dashboardAverageCr1Props={dashboardProps.dashboardAverageCr1Props}
          dashboardAverageCr2Props={dashboardProps.dashboardAverageCr2Props}
        />
        <div className='flex-row'>
          <div className='section-title-2 valign-text-middle oxanium-medium-black-27px'>
            {sectionTitle}
          </div>
          <SubnavPortfolio4 />
        </div>
        <img className='section-line' src='/img/section-line@1x.svg' />
        {/* <div className="flex-row-1">
          <SearchBorrow pathName="" />
        </div> */}
        <div className='header-div'>
          <div className='overlap-group-18'>
            <div className='header-item-text-2 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText1}
            </div>
            <img
              className='arrow-caret_down'
              src='/img/arrow---caret-down@2x.svg'
            />
          </div>
          <div className='overlap-group1-9'>
            <div className='header-item-text-3 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText2}
            </div>
            <img
              className='arrow-caret_down-1'
              src='/img/arrow---caret-down-1@2x.svg'
            />
          </div>
          <div className='overlap-group2-4'>
            <div className='header-item-text-4 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText3}
            </div>
            <img
              className='arrow-caret_down-2'
              src='/img/arrow---caret-down-2@2x.svg'
            />
          </div>
          <div className='overlap-group3-1'>
            <div className='header-item-text-5 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText4}
            </div>
            <img
              className='arrow-caret_down-3'
              src='/img/arrow---caret-down-3@2x.svg'
            />
          </div>
          <div className='overlap-group4'>
            <div className='header-item-text-6 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText5}
            </div>
            <img
              className='arrow-caret_down-4'
              src='/img/arrow---caret-down-3@2x.svg'
            />
          </div>
          <div className='overlap-group5'>
            <div className='header-item-text-7 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText6}
            </div>
            <img
              className='arrow-caret_down-5'
              src='/img/arrow---caret-down-5@2x.svg'
            />
          </div>
        </div>
        <div className='div-container-1'>
          {Object.values(userBorrows).map(
            (userBorrow, index) => (
              logger('userBorrow, index', userBorrow, index),
              (
                <div className='row' key={index}>
                  <img
                    className='header-divider'
                    src='/img/header-divider@1x.svg'
                  />
                  <Link
                    to={`/asset/${userBorrow.nftContractAddress}/${userBorrow.nftTokenId}`}
                  >
                    <div className='row-div-1 oxanium-normal-black-18px'>
                      <RowItem
                        className={rowItemProps.className}
                        nftSymbol={userBorrow.nftSymbol}
                        nftTokenId={userBorrow.nftTokenId}
                        nftImage={borrowImages[index]}
                      />
                      <RowItem2
                        borrowAmount={formatValue(userBorrow.borrowAmount, 3)}
                      />
                      <div className='header-item-text valign-text-middle'>
                        {formatValue(interestRate*100, 2)}%
                      </div>
                      <div className='row-item-3'>
                        <div className='header-item-text-9 valign-text-middle oxanium-normal-black-18px'>
                          {formatValue(userBorrow.floorPrice, 3)}
                        </div>
                        <img
                          className='weth-icon-22'
                          src='/img/weth-icon-32@2x.svg'
                        />
                      </div>
                      <RowItem4
                        liquidationPrice={formatValue(
                          userBorrow.borrowAmount * poolMinCr,
                          3
                        )}
                      />
                      <div className='header-item-text-1 valign-text-middle'>
                        {formatValue(userBorrow.collRatio, 2)}%
                      </div>
                    </div>
                  </Link>
                </div>
              )
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default Borrows
