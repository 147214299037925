import { useAppContext } from "../AppContext";
import { useContract } from "./useContract";
import TokenPriceConsumer from "../artifacts/contracts/TokenPriceConsumer/TokenPriceConsumer.json";
import {  formatEther } from "@ethersproject/units";
import { useWeb3React } from "@web3-react/core";


export const useToken = () => {
	const {
		setEthUsdPrice
	} = useAppContext();
	const { account } = useWeb3React();

	const tokenPriceConsumerAddress = process.env.REACT_APP_TOKEN_PRICE_CONSUMER_CONTRACT_ADDRESS;
  const tokenPriceConsumerABI = TokenPriceConsumer["abi"];
  const tokenPriceConsumer = useContract(tokenPriceConsumerAddress, tokenPriceConsumerABI);

	const fetchEthUsdPrice = async () => {
		if (account) {
			const ethUsdPrice = await tokenPriceConsumer.getMockEthUsdPrice(); // TODO: update for mainnet
			setEthUsdPrice(formatEther(ethUsdPrice));
		}
	}

  return {
		fetchEthUsdPrice
  }

};

export default useToken;