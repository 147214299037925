import React from 'react'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import ModuloApprove from '../ModuloApprove'
import ModuloConfirm from '../ModuloConfirm'
import ModuloSuccess from '../ModuloSuccess'
import './ModuloBorrow.css'

function ModuloBorrow(props) {
  const {
    moduloTitle,
    subTitle,
    borrowAmount,
    borrowAmountUsd,
    nftName,
    nftProjectName,
    nftImage,
    isBusy,
  } = props

  return (
    <div className='container-center-horizontal'>
      <div className='modulo-borrow-1'>
        <div className='overlap-group1-10'>
          <img className='data-line-2' src='/img/data-line@1x.svg' />
          <div className='modulo-title-3 valign-text-middle oxanium-medium-black-30px'>
            {moduloTitle}
          </div>
          <img className='header-line-3' src='/img/data-line@1x.svg' />
        </div>
        <div className='overlap-group2-5'>
          <div className='overlap-group-19'>
            <div className='amount-weth-3 valign-text-middle oxanium-medium-black-30px'>
              {borrowAmount.toLocaleString('en', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 4,
              })}
            </div>
            <div className='amount-usd-3 valign-text-middle oxanium-medium-star-dust-16px'>
              $
              {borrowAmountUsd.toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div className='repay-title-3 valign-text-middle oxanium-medium-granite-gray-16px'>
              {subTitle}
            </div>
            <img className='weth-logo-6' src='/img/weth-logo-4@2x.svg' />
          </div>
          <div className='modulo-collateral-3'>
            <img className='nft-icon-2' src={nftImage} />
            <div className='collateral-container'>
              <div className='nft-title-4 valign-text-middle oxanium-medium-black-30px'>
                {nftName}
              </div>
              <div className='nft-project-3 valign-text-middle oxanium-medium-gravel-20px'>
                {nftProjectName}
              </div>
            </div>
          </div>
        </div>
        <ModuloApprove
          approveText={`Approve ${nftName} as collateral`}
          approveDescription='To create a borrow you must approve this NFT as collateral, which requires a one-tiem gas fee.'
        />
        <ModuloConfirm
          confirmText={`Confirm ${borrowAmount.toLocaleString('en', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 4,
          })} WETH borrow`}
          confirmDescription='Accept the signature request in your wallet and wait for your borrow to process.'
        />
        <ModuloSuccess
          confirmText='Success!'
          confirmDescription={`Check your wallet for the additional ${borrowAmount.toLocaleString(
            'en',
            { minimumFractionDigits: 1, maximumFractionDigits: 4 }
          )} WETH. Repay your borrow at any time to retrieve your NFT.`}
          className='modulo-5'
        />
        {isBusy && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </div>
    </div>
  )
}

export default ModuloBorrow
