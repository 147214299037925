import React from "react";
import "./BorrowItemDiv.css";

function BorrowItemDiv(props) {
  const {nftName, nftProjectName} = props;
  return (
    <div className="borrow-item-div">
      <div className="borrow-item valign-text-middle oxanium-medium-black-30px">{nftName}</div>
      <div className="borrow-item-project valign-text-middle oxanium-medium-gravel-18px">{nftProjectName}</div>
    </div>
  );
}

export default BorrowItemDiv;
