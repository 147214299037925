import React, { useState, useEffect } from "react";
import "./Search.css";
import { Autocomplete, TextField } from '@mui/material';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { styled } from '@mui/material/styles';
import { useAppContext } from "../../AppContext";
import { useNFT } from "../../hooks/useNFT";

function Search() {
  const { nfts, setSearchSelection } = useAppContext();
  const { formatNftData } = useNFT();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (Object.keys(nfts).length !== 0) {
      const optionsData = [];
      const seen = new Set([]);
      nfts.ownedNfts.map((nft) => {
        const nftData = formatNftData(nft)
        const collectionName = nftData.collectionName;
        const collectionSymbol = nftData.collectionSymbol;
        const tokenId = String(nftData.tokenId);
        if (!seen.has(collectionName)) {
          optionsData.push({title: collectionName, type: "COLLECTION"})
          seen.add(collectionName)
        }
        if (!seen.has(collectionSymbol)) {
          optionsData.push({title: collectionSymbol, type: "SYMBOL"})
          seen.add(collectionSymbol)
        }
        if (!seen.has(tokenId)) {
          optionsData.push({title: tokenId, type: "TOKEN ID"})
          seen.add(tokenId)
        }        
      });
      setOptions(optionsData);
    };
  }, [nfts]);

  return (
    <Autocomplete
      id="my-profile"
      options={options.sort((a, b) => -b.type.localeCompare(a.type) || -b.title.localeCompare(a.title))}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.title}
      onChange={(event, value) => setSearchSelection(value)}
      sx={{ width: 748, height: 44 }}
      size="small"
      renderInput={(params) => {
        return (
          <StyledTextField
            {...params}
            placeholder="Filter by collection, symbol or token id"
            fullWidth
            sx={{ width: 748, height: 44, borderRadius: 30, fontFamily: "Oxanium" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        );
      }}
    /> 
  );
}

export default Search;


const StyledTextField = styled(TextField)({
  textTransform: 'none',
  fontFamily: "Oxanium",
  fontSize: 40,
  borderRadius: 30,
});