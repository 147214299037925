import React, { useEffect } from 'react'

import { useWeb3React } from '@web3-react/core'
import Blockies from 'react-blockies'
import 'regenerator-runtime/runtime'
import debug from 'debug'

import { injected } from '../../connectors'
import { useAppContext } from '../../AppContext'
import useETH from '../../hooks/useETH'
import useAssetToken from '../../hooks/useAssetToken'
import './NavMenuBalance.css'

const logger = debug('fluidnft:NavMenuBalance')

function NavMenuBalance() {
  const { account, deactivate } = useWeb3React()
  const { fetchEthBalance } = useETH()
  const { fetchAssetTokenBalances } = useAssetToken()
  const { ethBalance, assetTokenBalanceWETH } = useAppContext()

  async function disconnect() {
    try {
      await deactivate(injected)
    } catch (ex) {
      logger(ex)
    }
  }

  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString('en', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 3,
    })
  }

  useEffect(() => {
    if (account) {
      fetchEthBalance()
      fetchAssetTokenBalances()
    }
  }, [account])

  return (
    <div className='nav-menu-balance'>
      <div className='balance-eth valign-text-middle oxanium-medium-eerie-black-16px'>
        {formatPrice(ethBalance)} ETH
      </div>
      <div className='weth-container'>
        <div className='weth-container-1'>
          <div className='weth-balance valign-text-middle oxanium-medium-eerie-black-16px'>
            {formatPrice(assetTokenBalanceWETH)}
          </div>
          <img className='weth-icon-2' src='/img/weth-icon-18@2x.svg' />
        </div>
      </div>
      <div className='balance-account-container'>
        <div className='balance-account-pill'></div>
        <div className='blockies-button' onClick={disconnect}>
          <Blockies
            seed={account}
            size={10}
            scale={2}
            className='balance-account-icon'
          />
        </div>
        <div className='balance-account-address valign-text-middle oxanium-medium-eerie-black-16px'>
          {account.substring(0, 6)}...{account.slice(-4)}
        </div>
      </div>
    </div>
  )
}

export default NavMenuBalance
