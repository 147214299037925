import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useWeb3React } from '@web3-react/core'

import Nav from '../Nav'
import SubnavPool from '../SubnavPool'
import ModuloDeposit from '../ModuloDeposit'
import useAssetToken from '../../hooks/useAssetToken'
import useTransaction from '../../hooks/useTransaction'
import useLendingPool from '../../hooks/useLendingPool'
import { useAppContext } from '../../AppContext'
import { useHistory, useParams } from 'react-router-dom'
import { useNFT } from '../../hooks/useNFT'
import { useToken } from '../../hooks/useToken'
import { theme } from '../../config/theme'
import './Deposit.css'

function Deposit(props) {
  const {
    depositLiquidityTitle,
    depositBalanceTitle,
    inputTitle,
    subnavPoolProps,
    // poolTokenName,
    poolSymbol,
    // poolCollectionName,
    poolCollectionImage,
    poolAvailableLiquidity,
    poolYield,
    userPoolDepositBalance,
  } = props
  const { account } = useWeb3React()
  const { fetchAssetTokenBalances } = useAssetToken()
  const {
    assetTokenBalanceWETH,
    pool,
    poolSymbols,
    poolCollectionNames,
    poolCollectionImages,
    ethUsdPrice,
  } = useAppContext()
  const { txnStatus, setTxnStatus } = useTransaction()
  const { fetchPoolNftData } = useNFT()
  const { deposit, fetchPool } = useLendingPool()
  const { fetchEthUsdPrice } = useToken()

  const { collectionTokenAddress, poolTokenName } = useParams()

  // Oracle data <- TODO: refactor and manage state
  const [ethUsd, setEthUsd] = useState(0)

  // Max values
  const maxInputValue = assetTokenBalanceWETH

  // Initial Values
  const initialInputValue = ''
  const initialInputValueUsd = initialInputValue
    ? initialInputValue * ethUsd
    : 0

  // State management
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [inputValueUsd, setInputValueUsd] = useState(initialInputValueUsd)
  const [poolBalance, setPoolBalance] = useState('--')

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/lend')
  }

  // Txn
  const handleDepositSubmit = () => {
    deposit(collectionTokenAddress, poolTokenName, String(inputValue))
  }

  useEffect(() => {
    if (ethUsdPrice) {
      setEthUsd(ethUsdPrice)
    }
  }, [ethUsdPrice])

  useEffect(() => {
    if (account) {
      fetchAssetTokenBalances()
      fetchPool(collectionTokenAddress)
    }
  }, [account])

  useEffect(() => {
    if (account) {
      txnStatus
    }
  }, [account])

  useEffect(() => {
    setTxnStatus('')
    fetchEthUsdPrice()
  }, [])

  useEffect(() => {
    if (pool) {
      fetchPoolNftData([pool])
      if (pool.userBalance) {
        setPoolBalance(pool.userBalance.toFixed(3))
      }
    }
  }, [pool])

  return (
    <div className='container-center-horizontal'>
      <div className='deposit-6 screen'>
        <Nav />
        <div className='flex-row-6'>
          <div className='section-container-2'>
            <a onClick={handleClick}>
              <img className='back-arrow-2' src='/img/back-arrow-2@2x.svg' />
            </a>
            <div className='section-title-4 valign-text-middle oxanium-medium-black-27px'>{`Deposit into ${
              poolSymbol ? poolSymbol : '...'
            }`}</div>
          </div>
          <SubnavPool
            subnavButtonTextSelected={subnavPoolProps.subnavButtonTextSelected}
            subnavButtonText={subnavPoolProps.subnavButtonText}
          />
        </div>
        <img className='section-line-4' src='/img/section-line-6@1x.svg' />
        <div className='flex-row-7'>
          <div className='lending-pool-div'>
            <div className='lending-pool-div-1'>
              <div className='lending-pool-title valign-text-middle oxanium-medium-black-30px'>
                {poolSymbols[0]}
              </div>
              <div className='lending-pool-project valign-text-middle oxanium-medium-gravel-18px'>
                {poolCollectionNames[0]}
              </div>
            </div>
            <div className='overlap-group-22'>
              <img className='weth-pill-10' src='/img/weth-pill-1@2x.svg' />
              <img className='weth-icon-24' src='/img/weth-icon-52@2x.svg' />
              <div className='nft-collection-icon-5'>
                <img
                  className='nft-collection-icon-5-image'
                  src={poolCollectionImages[0]}
                />
              </div>
            </div>
          </div>
          <div className='lending-pool-data'>
            <div className='deposit-item-liquidity'>
              <div className='overlap-group2-9'>
                <div className='weth-container-22'>
                  <img
                    className='weth-icon-25'
                    src='/img/weth-icon-51@2x.svg'
                  />
                  <div className='deposit-4 valign-text-middle oxanium-medium-black-30px'>
                    {poolAvailableLiquidity
                      ? poolAvailableLiquidity.toLocaleString('en', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 4,
                        })
                      : '--'}
                  </div>
                </div>
                <div className='deposit-liquidity-title valign-text-middle oxanium-medium-granite-gray-18px'>
                  {depositLiquidityTitle}
                </div>
              </div>
              <div className='deposit-5 valign-text-middle oxanium-medium-star-dust-15px'>
                {poolAvailableLiquidity
                  ? `$${(poolAvailableLiquidity * ethUsd).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : '--'}
              </div>
            </div>
            <div className='deposit-item-balance'>
              <div className='deposit-5 valign-text-middle oxanium-medium-granite-gray-18px'>
                {depositBalanceTitle}
              </div>
              <div className='weth-container-23'>
                <img className='weth-icon-26' src='/img/weth-icon-50@2x.svg' />
                <div className='deposit-4 valign-text-middle oxanium-medium-black-30px'>
                  {poolBalance}
                </div>
              </div>
              <div className='deposit-5 valign-text-middle oxanium-medium-star-dust-15px'>
                {poolBalance != '--'
                  ? `$${(poolBalance * ethUsd).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : '--'}
              </div>
            </div>
            <div className='deposit-item-yield'>
              <div className='deposit-yield-title valign-text-middle oxanium-medium-granite-gray-18px'>
                Yield
              </div>
              <div className='deposit-yield valign-text-middle oxanium-medium-black-30px'>
                {poolYield ? `${poolYield}% APY` : '--'}
              </div>
            </div>
          </div>
          <img
            className='section-divider-2'
            src='/img/section-divider-3@2x.svg'
          />
          <div className='deposit-div'>
            <div className='input-title-2 valign-text-middle oxanium-medium-black-22px'>
              {inputTitle}
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {isNaN(parseFloat(maxInputValue))
                        ? '--'
                        : parseFloat(maxInputValue).toLocaleString('en', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 4,
                          })}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={maxInputValue}
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <ThemeProvider theme={theme}>
              {inputValue ? (
                <>
                  {0 < inputValue && inputValue <= maxInputValue ? (
                    <Popup
                      trigger={
                        <div className='submit-deposit-button'>
                          <StyledButton
                            variant='contained'
                            className='submit-deposit-button'
                            onClick={handleDepositSubmit}
                          >
                            Deposit{' '}
                            {inputValue.toLocaleString('en', {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 4,
                            })}{' '}
                            WETH
                          </StyledButton>
                        </div>
                      }
                      modal
                      nested
                    >
                      <ModuloDeposit
                        moduloTitle='Complete deposit'
                        subTitle='Deposit'
                        depositAmount={inputValue}
                        depositAmountUsd={inputValueUsd}
                        poolName={poolName}
                        poolNftProjectName={poolNftProjectName}
                        poolCollectionImage={poolCollectionImages[0]}
                      />
                    </Popup>
                  ) : (
                    <div className='submit-deposit-button'>
                      <StyledButton
                        disabled
                        variant='contained'
                        className='submit-deposit-button'
                      >
                        {inputValue > maxInputValue
                          ? 'Insufficient funds'
                          : 'Select amount > 0'}
                      </StyledButton>
                    </div>
                  )}
                </>
              ) : (
                <div className='submit-deposit-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-deposit-button'
                  >
                    Select amount
                  </StyledButton>
                </div>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Deposit

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})

// Pool data
let poolName = 'BAYC-WETH'
let poolNftProjectName = 'Bored Ape Yacht Club'
