import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { useWeb3React } from '@web3-react/core'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import debug from 'debug'

import Nav from '../Nav'
import Dashboard from '../Dashboard'
import SubnavPortfolio4 from '../SubnavPortfolio4'
import RiskConfiguration from '../RiskConfiguration'
import ModuloBorrow from '../ModuloBorrow'
import { FluidDrawer } from '../Drawer'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import { useLendingPool } from '../../hooks/useLendingPool'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import { useToken } from '../../hooks/useToken'
import { useListNfts } from '../../hooks/useListNfts'
import { useActivateWallet } from '../../hooks/useActivateWallet'
import { theme } from '../../config/theme'
import { formatNumber } from '../../core/utils/format-number'
import './BorrowMultiple.css'
import { useSelectNft } from '../../hooks/state/useSelectNft'

const logger = debug('fluidnft:BorrowMultiple')

function BorrowMultiple(props) {
  const { inputTitle, dashboardProps, subnavPortfolio4Props } = props
  const {
    // borrowBalance,
    // crValue,
    ethUsdPrice,
    isWhitelisted,
    // nftCollectionName,
    // nftImage,
    // setCrValue,
    // userBorrows,
  } = useAppContext()
  const [liquidationValue, setLiquidationValue] = useState(0)
  const [minSliderValue, setMinSliderValue] = useState(0)
  const [crValue, setCrValue] = useState(0)
  const [active] = useActivateWallet()
  const { account } = useWeb3React()
  const { fetchNfts, fetchNftFloorPrices } = useNFT()
  const { fetchBorrowBalance, fetchUserBorrows } = useCollateralManager()
  const { batchBorrow } = useLendingPool()
  const { fetchEthUsdPrice } = useToken()
  const [, matchedNfts] = useListNfts()
  const [, setSelectedNfts] = useSelectNft()
  const [borrowBalance, setBorrowBalance] = useState(0)

  let ethUsd = ethUsdPrice

  // Min / Max values
  const minCr = 1.5
  const floorPrice = matchedNfts.reduce((sum, nft) => {
    sum += nft.floorPrice ? nft.floorPrice : 0
    return sum
  }, 0)

  const maxInputValue = floorPrice / minCr - borrowBalance
  const interestRate = matchedNfts.length
    ? matchedNfts.reduce((sum, nft) => {
        sum += nft.interestRate ? Number(nft.interestRate) : 0
        return sum
      }, 0) / matchedNfts.length
    : 0

  // Initial Values
  const initialSliderValue = borrowBalance
    ? (100 * borrowBalance * minCr) / floorPrice
    : 75
  const initialCrValue = (minCr / initialSliderValue) * 100
  const initialInputValue = borrowBalance ? '' : floorPrice / initialCrValue
  const initialInputValueUsd = initialInputValue
    ? initialInputValue * ethUsd
    : 0

  // State management
  const [sliderValue, setSliderValue] = useState(initialSliderValue)
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [inputValueUsd, setInputValueUsd] = useState(initialInputValueUsd)
  const [borrowStatus, setBorrowStatus] = useState('')
  logger({ maxInputValue, interestRate, liquidationValue })

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
    const calcSliderValue =
      (100 * ((value + borrowBalance) * minCr)) / floorPrice
    setSliderValue(calcSliderValue)
  }

  const getSliderValue = (event) => {
    const value = Number(event.target.value)
    setSliderValue(value)
    const calcInputValue = ((floorPrice / minCr) * value) / 100 - borrowBalance
    setInputValue(calcInputValue)
    setInputValueUsd(calcInputValue * ethUsd)
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/')
  }

  const handleBorrowSubmit = async () => {
    const nftTokenAddresses = []
    const nftTokenIds = []
    const tokenAmounts = []
    const tokenSymbols = []
    matchedNfts.forEach((nft) => {
      nftTokenAddresses.push(nft.tokenAddress)
      nftTokenIds.push(nft.tokenId)
      tokenAmounts.push((nft.floorPrice / crValue) * 100)
      tokenSymbols.push('WETH')
    })
    logger({
      nftTokenAddresses,
      sliderValue,
      crValue,
      nftTokenIds,
      tokenAmounts,
      tokenSymbols,
      matchedNfts,
    })
    const { status: batchBorrowStatus } = await batchBorrow({
      nftTokenAddresses,
      nftTokenIds,
      tokenAmounts,
      tokenSymbols,
      informStatus: setBorrowStatus,
    })
    if (batchBorrowStatus === 'success') {
      setSelectedNfts(new Set([]))
      setSliderValue(0)
      setInputValue(0)
      setInputValueUsd(0)
      fetchUserBorrows()
    }
  }

  const formatValue = (value, decimals) => {
    return Number(value).toFixed(decimals)
  }

  useEffect(() => {
    if (account && active) {
      fetchNfts(account, isWhitelisted)
      fetchNftFloorPrices()
      fetchEthUsdPrice()
    }
  }, [active, account, isWhitelisted])

  useEffect(() => {
    const getBorrowBalance = async () => {
      let partialBorrowBalance = 0
      for (const nft of matchedNfts) {
        const balance = await fetchBorrowBalance(nft.tokenAddress, nft.tokenId)
        partialBorrowBalance += balance ? parseFloat(balance) : 0
      }
      setBorrowBalance(partialBorrowBalance)
    }
    logger({ account, active, matchedNfts })
    if (account && active && matchedNfts.length) {
      getBorrowBalance()
      getInputValue({ target: { value: (maxInputValue * 75) / 100 } })
    }
  }, [account, active, matchedNfts.length])

  useEffect(() => {
    if (account && !isNaN(floorPrice) && !isNaN(borrowBalance)) {
      setMinSliderValue((borrowBalance / floorPrice) * 100)
    }
  }, [floorPrice, borrowBalance, account])

  // Update State
  useEffect(() => {
    let calcCrValue = ((minCr * 100) / initialSliderValue) * 100
    if (floorPrice && inputValue && borrowBalance) {
      calcCrValue = (100 * floorPrice) / (inputValue + borrowBalance)
    } else if (floorPrice && inputValue) {
      calcCrValue = (100 * floorPrice) / inputValue
    }

    const calcLiquidationValue = ((100 * floorPrice) / calcCrValue) * minCr
    setCrValue(calcCrValue)
    setLiquidationValue(calcLiquidationValue)
  }, [inputValue, sliderValue, floorPrice, borrowBalance])

  return (
    <div className='container-center-horizontal'>
      <div className='borrow screen'>
        <Nav />
        <Dashboard
          dashboardAverageCr1Props={dashboardProps.dashboardAverageCr1Props}
          dashboardAverageCr2Props={dashboardProps.dashboardAverageCr2Props}
        />
        <div className='flex-row-2'>
          <div className='section-container'>
            <a onClick={handleClick}>
              <img className='back-arrow' src='/img/back-arrow@2x.svg' />
            </a>
            <div className='section-title-1 valign-text-middle oxanium-medium-black-27px'>{`Borrow against Collateral`}</div>
          </div>
          <SubnavPortfolio4 className={subnavPortfolio4Props.className} />
        </div>
        <img className='section-line-1' src='/img/section-line@1x.svg' />
        <div className='div-container'>
          <div className='nft-div'>
            <FluidDrawer nfts={matchedNfts} maxHeight={'380px'} margin='0' showHeader />
          </div>
          <img className='section-divider' src='/img/section-divider@2x.svg' />
          <div className='borrow-div'>
            <div className='input-title valign-text-middle oxanium-medium-black-22px'>
              {inputTitle}
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {isNaN(maxInputValue)
                        ? ''
                        : formatValue(maxInputValue, 3)}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={maxInputValue}
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <RiskConfiguration
              getSliderValue={getSliderValue}
              sliderValue={sliderValue}
              crValue={crValue}
              liquidationValue={liquidationValue}
              minSliderValue={minSliderValue}
              isRepay={false}
              showLiquidation={false}
            />
            <ThemeProvider theme={theme}>
              {inputValue ? (
                <>
                  {0 < inputValue && inputValue <= maxInputValue ? (
                    <Popup
                      trigger={
                        <div className='submit-borrow-button'>
                          <StyledButton
                            variant='contained'
                            className='submit-borrow-button'
                            onClick={handleBorrowSubmit}
                          >
                            Borrow{' '}
                            {inputValue.toLocaleString('en', {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 4,
                            })}{' '}
                            WETH
                          </StyledButton>
                        </div>
                      }
                      modal
                      nested
                    >
                      <ModuloBorrow
                        moduloTitle='Complete borrow'
                        subTitle={`Borrow @ ${formatNumber(
                          interestRate * 100
                        )}% APR`}
                        borrowAmount={inputValue}
                        borrowAmountUsd={inputValueUsd}
                        nftName={'Collection'}
                        nftProjectName={'Collection'}
                        nftImage={matchedNfts[0].image}
                        isBusy={borrowStatus === 'COMPLETE'}
                      />
                    </Popup>
                  ) : (
                    <div className='submit-borrow-button'>
                      <StyledButton
                        disabled
                        variant='contained'
                        className='submit-borrow-button'
                      >
                        {inputValue > minCr
                          ? `Select CR > ${minCr * 100}%`
                          : 'Select amount > 0'}
                      </StyledButton>
                    </div>
                  )}
                </>
              ) : (
                <div className='submit-borrow-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-borrow-button'
                  >
                    Select amount
                  </StyledButton>
                </div>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BorrowMultiple

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})
