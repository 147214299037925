import React from "react";
import "./ModuloSuccess.css";
var Spinner = require('react-spinkit');
import useTransaction from '../../hooks/useTransaction';

function ModuloSuccess(props) {
  const { confirmText, confirmDescription, className } = props;
  const { txnStatus } = useTransaction();

  if (txnStatus == "TRANSFERRING") {
    return (
      <div className="modulo-approve">
      <div className="step-viz-loading">
      </div>
      <div className="step-loading">
        <Spinner name="ball-scale-ripple-multiple" />
      </div>
        <div className="approve-container">
          <div className="approve-text valign-text-middle oxanium-medium-black-22px">{confirmText}</div>
          <p className="approve-description valign-text-middle oxanium-medium-granite-gray-16px">{confirmDescription}</p>
        </div>
      </div>
    );
  } else if (txnStatus == "COMPLETE") {
    return (
      <div className={`modulo-6 ${className || ""}`}>
        <div className="step-viz-complete">
          <img src="/img/circle_check.svg" className="filter-green" />
        </div>
        <div className="confirm-container">
          <div className="confirm-text valign-text-middle oxanium-medium-black-22px">{confirmText}</div>
          <p className="x-description valign-text-middle oxanium-medium-granite-gray-16px">{confirmDescription}</p>
        </div>
      </div>
    );
  } else if (txnStatus == "ERROR") {
    return (
      <div className="modulo-approve">
        <div className="step-viz-error">
        </div>
        <div className="approve-container">
          <div className="approve-text valign-text-middle oxanium-medium-black-22px">{confirmText}</div>
          <p className="approve-description valign-text-middle oxanium-medium-granite-gray-16px">{confirmDescription}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`modulo-6 ${className || ""}`}>
        <div className="step-viz-waiting">
          <img src="/img/clock.svg" className="clock-filter-white" />
        </div>
        <div className="confirm-container">
          <div className="confirm-text valign-text-middle oxanium-medium-black-22px">{confirmText}</div>
          <p className="x-description valign-text-middle oxanium-medium-granite-gray-16px">{confirmDescription}</p>
        </div>
      </div>
    );
  }
}

export default ModuloSuccess;
