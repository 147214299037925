import './App.css'
import React from 'react'
import {
  Switch,
  HashRouter as Router,
  Route,
  withRouter,
} from 'react-router-dom'
import ModuloWithdraw from './components/ModuloWithdraw'
import Home from './components/Home'
import MyPortfolio from './components/MyPortfolio'
import ModuloRedeem from './components/ModuloRedeem'
// import Borrow from "./components/Borrow";
import Borrows from './components/Borrows'
import ModuloDeposit from './components/ModuloDeposit'
import ModuloLiquidate from './components/ModuloLiquidate'
// import Repay from "./components/Repay";
import Deposit from './components/Deposit'
import LendingPools from './components/LendingPools'
import Withdraw from './components/Withdraw'
import Mobile from './components/Mobile'
import Liquidate from './components/Liquidate'
import Redeem from './components/Redeem'
import Liquidations from './components/Liquidations'
import Asset from './components/Asset'
import BorrowMultiple from './components/BorrowMultiple'
import LendingPool from './components/LendingPool'
import Liquidation from './components/Liquidation'
import Dark from './components/Dark'
import SubnavPortfolio from './components/SubnavPortfolio'
import { AppContextProvider } from './AppContext'
import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers'
import { useMediaQuery } from 'react-responsive'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
    },
  },
})

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider)
}

function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 1160px)' })

  if (isMobile) {
    return (
      <Mobile
        logo='/img/logo-7@2x.png'
        sectionTitle='FluidNFT isn’t on mobile yet, use a computer to access our app.'
        submitRepayText='Go to our site'
        submitRepayButton1Props={mobileData.submitRepayButton1Props}
        submitRepayButton2Props={mobileData.submitRepayButton2Props}
      />
    )
  } else {
    return (
      <AppContextProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Switch>
                <Route path='/modulo-withdraw'>
                  <ModuloWithdraw
                    moduloTitle='Complete your withdraw'
                    repayTitle='Withdraw'
                    moduloApproveProps={moduloWithdraw1Data.moduloApproveProps}
                    moduloConfirm1Props={
                      moduloWithdraw1Data.moduloConfirm1Props
                    }
                    moduloConfirm2Props={
                      moduloWithdraw1Data.moduloConfirm2Props
                    }
                  />
                </Route>
                <Route exact path='/'>
                  <Home {...homeData} />
                </Route>
                <Route path='/my-portfolio'>
                  <MyPortfolio {...myPortfolioData} />
                </Route>
                <Route path='/modulo-redeem'>
                  <ModuloRedeem {...moduloRedeemData} />
                </Route>
                <Route path='/borrows'>
                  <Borrows {...borrowsData} />
                </Route>
                <Route path='/modulo-liquidate'>
                  <ModuloDeposit
                    moduloTitle='Complete your auction bid'
                    repayTitle='Bid'
                    moduloApproveProps={moduloLiquidate1Data.moduloApproveProps}
                    moduloConfirm1Props={
                      moduloLiquidate1Data.moduloConfirm1Props
                    }
                    moduloConfirm2Props={
                      moduloLiquidate1Data.moduloConfirm2Props
                    }
                  />
                </Route>
                <Route path='/deposit'>
                  <Deposit {...depositData} />
                </Route>
                <Route path='/lend'>
                  <LendingPools {...lendingPoolsData} />
                </Route>
                <Route path='/withdraw'>
                  <Withdraw {...withdrawData} />
                </Route>
                <Route path='/modulo-deposit'>
                  <ModuloWithdraw
                    moduloTitle='Complete your deposit'
                    repayTitle='Deposit'
                    moduloApproveProps={moduloWithdraw2Data.moduloApproveProps}
                    moduloConfirm1Props={
                      moduloWithdraw2Data.moduloConfirm1Props
                    }
                    moduloConfirm2Props={
                      moduloWithdraw2Data.moduloConfirm2Props
                    }
                  />
                </Route>
                <Route path='/bid/:tokenAddress/:tokenId'>
                  <Liquidate {...liquidateData} />
                </Route>
                <Route path='/modulo-repay'>
                  <ModuloLiquidate
                    moduloTitle='Complete your repayment'
                    repayTitle='Repay'
                    moduloApproveProps={moduloLiquidate2Data.moduloApproveProps}
                    moduloConfirm1Props={
                      moduloLiquidate2Data.moduloConfirm1Props
                    }
                    moduloConfirm2Props={
                      moduloLiquidate2Data.moduloConfirm2Props
                    }
                  />
                </Route>
                <Route path='/redeem/:tokenAddress/:tokenId'>
                  <Redeem {...redeemData} />
                </Route>
                <Route path='/snipe'>
                  <Liquidations {...liquidationsData} />
                </Route>
                <Route path='/modulo-borrow'>
                  <ModuloLiquidate
                    moduloTitle='Complete your borrow'
                    repayTitle='Borrow @ 20% APR'
                    moduloApproveProps={moduloLiquidate3Data.moduloApproveProps}
                    moduloConfirm1Props={
                      moduloLiquidate3Data.moduloConfirm1Props
                    }
                    moduloConfirm2Props={
                      moduloLiquidate3Data.moduloConfirm2Props
                    }
                  />
                </Route>
                <Route path='/asset/:tokenAddress/:tokenId'>
                  <Asset
                    sectionTitle='Borrow against BAYC #3420'
                    floorPriceWeth='100.000'
                    floorPriceTitle='Floor price'
                    floorPriceUsd='$200,000.00'
                    inputTitle='Borrow WETH'
                    dashboardProps={borrowData.dashboardProps}
                    subnavPortfolio4Props={borrowData.subnavPortfolio4Props}
                    riskConfigurationProps={riskConfiguration2Data}
                  />
                </Route>
                <Route path='/review'>
                  <BorrowMultiple
                    sectionTitle='Borrow against BAYC #3420'
                    floorPriceWeth='100.000'
                    floorPriceTitle='Floor price'
                    floorPriceUsd='$200,000.00'
                    inputTitle='Borrow WETH'
                    dashboardProps={borrowData.dashboardProps}
                    subnavPortfolio4Props={borrowData.subnavPortfolio4Props}
                    riskConfigurationProps={riskConfiguration2Data}
                  />
                </Route>
                <Route path='/pool/:collectionTokenAddress/:poolTokenName'>
                  <LendingPool
                    sectionTitle='Deposit into BAYC-WETH'
                    lendingPoolTitle='BAYC-WETH'
                    lendingPoolProject='Bored Ape Yacht Club'
                    depositLiquidityTitle='Available liquidity'
                    depositBalanceTitle='Deposit balance'
                    inputTitle='Deposit WETH'
                    subnavPoolProps={subnavPool1Data}
                    depositYieldTitle='APY'
                  />
                </Route>
                <Route path='/borrow/:tokenAddress/:tokenId'>
                  <Liquidation
                    sectionTitle='Liquidate borrow against BAYC #3420'
                    liquidationItemTitle='BAYC #3420'
                    liquidationItemProject='Bored Ape Yacht Club'
                    subnavPoolProps={subnavPool4Data}
                    riskConfigurationProps={riskConfiguration3Data}
                  />
                </Route>
                <Route path='/dark'>
                  <Dark {...darkData} />
                </Route>
                <Route path='/subnav-portfolio'>
                  <SubnavPortfolio
                    subnavButtonText='Repay'
                    subnavButtonTextSelected='Borrow'
                  />
                </Route>
              </Switch>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Web3ReactProvider>
      </AppContextProvider>
    )
  }
}

export default withRouter(App)
const moduloApprove1Data = {
  approveText: 'Approve WETH',
  approveDescription:
    'To withdraw you must approve WETH, which requires a one-time gas fee.',
}

const moduloConfirm1Data = {
  confirmText: 'Confirm 3.0 WETH withdraw',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your withdraw to process.',
}

const moduloConfirm2Data = {
  confirmText: 'Success!',
  confirmDescription:
    'Check your wallet for the additional 3.0 WETH. This change will be reflected in your portfolio as well.',
  className: 'modulo',
}

const moduloWithdraw1Data = {
  moduloApproveProps: moduloApprove1Data,
  moduloConfirm1Props: moduloConfirm1Data,
  moduloConfirm2Props: moduloConfirm2Data,
}

const dashboardAverageCr1Data = {
  averageCrTitle: 'Average Col. Ratio',
  averageCrAmount: '200.12%',
}

const dashboardAverageCr2Data = {
  averageCrTitle: 'Lowest Col. Ratio',
  averageCrAmount: '154.12%',
  className: 'dashboard-lowest-cr',
}

const dashboard1Data = {
  dashboardAverageCr1Props: dashboardAverageCr1Data,
  dashboardAverageCr2Props: dashboardAverageCr2Data,
}

const nFT2Data = {
  className: 'link-1-1',
}

const nFT3Data = {
  className: 'link-1-2',
}

const nFT4Data = {
  className: 'link-1-1',
}

const nFT5Data = {
  className: 'link-1-3',
}

const nFT6Data = {
  className: 'link-1-1',
}

const nFT7Data = {
  className: 'link-1-1',
}

const nFT9Data = {
  className: 'link-1-1',
}

const nFT10Data = {
  className: 'link-1-4',
}

const nFT11Data = {
  className: 'link-1-1',
}

const nFT12Data = {
  className: 'link-1',
}

const nFT13Data = {
  className: 'link-1-1',
}

const nFT14Data = {
  className: 'link-1-1',
}

const myPortfolioData = {
  sectionTitle: 'Select collateral',
  toggleText: 'Whitelisted',
  dashboardProps: dashboard1Data,
  nFT1Props: nFT2Data,
  nFT2Props: nFT3Data,
  nFT3Props: nFT4Data,
  nFT4Props: nFT5Data,
  nFT5Props: nFT6Data,
  nFT6Props: nFT7Data,
  nFT7Props: nFT9Data,
  nFT8Props: nFT10Data,
  nFT9Props: nFT11Data,
  nFT10Props: nFT12Data,
  nFT11Props: nFT13Data,
  nFT12Props: nFT14Data,
}

const moduloApprove2Data = {
  approveText: 'Approve WETH',
  approveDescription:
    'To repay you must approve WETH, which requires a one-time gas fee.',
}

const moduloConfirm3Data = {
  confirmText: 'Confirm 3.0 WETH repayment',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your repayment to process.',
}

const moduloConfirm4Data = {
  confirmText: 'Success!',
  confirmDescription:
    'Your borrow is no longer up for liquidation. If the full borrow amount was repaid, you will redeem your NFT.',
  className: 'modulo-1',
}

const moduloRedeemData = {
  overlapGroup2: '',
  headerLine: '',
  moduloTitle: 'Complete your redeem repayment',
  amountWeth: '3.000',
  amountUsd: '$6,000.00',
  repayTitle: 'Repay',
  wethLogo: '',
  nftIcon: '',
  nftTitle: 'BAYC #3420',
  nftProject: 'Bored Ape Yacht Club',
  moduloApproveProps: moduloApprove2Data,
  moduloConfirm1Props: moduloConfirm3Data,
  moduloConfirm2Props: moduloConfirm4Data,
}

const dashboardAverageCr3Data = {
  averageCrTitle: 'Average Col. Ratio',
  averageCrAmount: '200.12%',
}

const dashboardAverageCr4Data = {
  averageCrTitle: 'Lowest Col. Ratio',
  averageCrAmount: '154.12%',
  className: 'dashboard-lowest-cr-1',
}

const dashboard2Data = {
  dashboardAverageCr1Props: dashboardAverageCr3Data,
  dashboardAverageCr2Props: dashboardAverageCr4Data,
}

const subnavPortfolio42Data = {
  className: 'subnav-portfolio-1',
}

const borrowData = {
  sectionTitle: 'Borrow against BAYC #3420',
  floorPriceWeth: '100.000',
  floorPriceTitle: 'Floor price',
  floorPriceUsd: '$200,000.00',
  inputTitle: 'Borrow WETH',
  dashboardProps: dashboard2Data,
  subnavPortfolio4Props: subnavPortfolio42Data,
}

const dashboardAverageCr5Data = {
  averageCrTitle: 'Average Col. Ratio',
  averageCrAmount: '200.12%',
}

const dashboardAverageCr6Data = {
  averageCrTitle: 'Lowest Col. Ratio',
  averageCrAmount: '154.12%',
  className: 'dashboard-lowest-cr-2',
}

const dashboard3Data = {
  dashboardAverageCr1Props: dashboardAverageCr5Data,
  dashboardAverageCr2Props: dashboardAverageCr6Data,
}

const search2Data = {
  className: 'search-1',
}

const rowItem5Data = {
  className: 'row-item-1',
}

const homeData = {
  sectionTitle: 'Select collateral',
  toggleText: 'Whitelisted',
  dashboardProps: dashboard1Data,
  headerItemText1: 'Collateral',
  headerItemText2: 'Borrow Balance',
  headerItemText3: 'APR',
  headerItemText4: 'Floor Price',
  headerItemText5: 'Liquidation Price',
  headerItemText6: 'Col. Ratio',
  headerItemText7: '20.00%',
  headerItemText8: '160.00%',
  headerItemText9: '20.00%',
  headerItemText10: '160.00%',
  searchProps: search2Data,
  rowItemProps: rowItem5Data,
  nFT7Props: nFT9Data,
}

const borrowsData = {
  sectionTitle: 'Select collateral',
  headerItemText1: 'Collateral',
  headerItemText2: 'Borrow Balance',
  headerItemText3: 'APR',
  headerItemText4: 'Floor Price',
  headerItemText5: 'Liquidation Price',
  headerItemText6: 'Col. Ratio',
  headerItemText7: '20.00%',
  headerItemText8: '160.00%',
  headerItemText9: '20.00%',
  headerItemText10: '160.00%',
  dashboardProps: dashboard3Data,
  searchProps: search2Data,
  rowItemProps: rowItem5Data,
}

const moduloApprove3Data = {
  approveText: 'Approve WETH',
  approveDescription:
    'To bid you must approve WETH, which requires a one-time gas fee.',
}

const moduloConfirm5Data = {
  confirmText: 'Confirm 3.0 WETH bid',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your bid to process.',
}

const moduloConfirm6Data = {
  confirmText: 'Success!',
  confirmDescription:
    'Your WETH will be locked until you are out-bid or the auction ends; when the NFT will be redeemed.',
  className: 'modulo-2',
}

const moduloLiquidate1Data = {
  moduloApproveProps: moduloApprove3Data,
  moduloConfirm1Props: moduloConfirm5Data,
  moduloConfirm2Props: moduloConfirm6Data,
}

const dashboardAverageCr7Data = {
  averageCrTitle: 'Average Col. Ratio',
  averageCrAmount: '200.12%',
}

const dashboardAverageCr8Data = {
  averageCrTitle: 'Lowest Col. Ratio',
  averageCrAmount: '154.12%',
  className: 'dashboard-lowest-cr-3',
}

const dashboard4Data = {
  dashboardAverageCr1Props: dashboardAverageCr7Data,
  dashboardAverageCr2Props: dashboardAverageCr8Data,
}

const subnavPortfolio44Data = {
  className: 'subnav-portfolio-3',
}

const riskConfiguration2Data = {
  className: 'risk-configuration-1',
}

const repayData = {
  sectionTitle: 'Repay borrow against BAYC #3420',
  floorPriceWeth: '100.000',
  floorPriceTitle: 'Floor price',
  floorPriceUsd: '$200,000.00',
  inputTitle: 'Repay WETH',
  dashboardProps: dashboard4Data,
  subnavPortfolio4Props: subnavPortfolio44Data,
  riskConfigurationProps: riskConfiguration2Data,
}

const subnavPool1Data = {
  subnavButtonTextSelected: 'Deposit',
  subnavButtonText: 'Withdraw',
}

const depositData = {
  sectionTitle: 'Deposit into BAYC-WETH',
  lendingPoolTitle: 'BAYC-WETH',
  lendingPoolProject: 'Bored Ape Yacht Club',
  depositLiquidityWeth: '20.0k',
  depositLiquidityTitle: 'Available liquidity',
  depositLiquidityUsd: '$50.0M',
  depositBalanceTitle: 'Deposit balance',
  depositBalanceWeth: '6.123',
  depositBalanceUsd: '$12,456.78',
  depositYieldTitle: 'APY',
  depositYield: '18.2% APY',
  inputTitle: 'Deposit WETH',
  subnavPoolProps: subnavPool1Data,
}

const subnavPool2Data = {
  subnavButtonTextSelected: 'Deposit',
  subnavButtonText: 'Withdraw',
  className: 'subnav-pool-1',
}

const search3Data = {
  className: 'search-2',
}

const rowItem222Data = {
  borrowedAmount: '40.9k',
}

const rowItem223Data = {
  borrowedAmount: '75.000',
  className: 'row-item-8',
}

const rowItem224Data = {
  borrowedAmount: '40.9k',
}

const rowItem225Data = {
  borrowedAmount: '75.000',
  className: 'row-item-8',
}

const lendingPoolsData = {
  sectionTitle: 'Select lending pool',
  headerItemText1: 'Lending Pool',
  headerItemText2: 'NFT Collection',
  headerItemText3: 'Market Size',
  headerItemText4: 'Total Borrowed',
  headerItemText5: 'Deposit Balance',
  headerItemText6: 'Deposit APY',
  lendingPoolTitle1: 'BAYC-WETH',
  nftCollectionTitle1: 'Bored Ape Yacht Club',
  depositApy1: '18.5%',
  lendingPoolTitle2: 'BAYC-WETH',
  nftCollectionTitle2: 'Bored Ape Yacht Club',
  depositApy2: '18.5%',
  subnavPoolProps: subnavPool2Data,
  searchProps: search3Data,
  rowItem221Props: rowItem222Data,
  rowItem222Props: rowItem223Data,
  rowItem223Props: rowItem224Data,
  rowItem224Props: rowItem225Data,
}

const subnavPool3Data = {
  subnavButtonTextSelected: 'Deposit',
  subnavButtonText: 'Withdraw',
  className: 'subnav-pool-2',
}

const withdrawData = {
  sectionTitle: 'Withdraw from BAYC-WETH',
  lendingPoolTitle: 'BAYC-WETH',
  lendingPoolProject: 'Bored Ape Yacht Club',
  depositLiquidityUsd: '$50.0M',
  depositLiquidityWeth: '20.0k',
  depositLiquidityTitle: 'Available liquidity',
  depositBalanceUsd: '$12,456.78',
  depositBalanceWeth: '6.123',
  depositBalanceTitle: 'Deposit balance',
  depositYieldTitle: 'Yield',
  spanText1: '18.',
  spanText2: '2',
  spanText3: '% APY',
  inputTitle: 'Withdraw WETH',
  subnavPoolProps: subnavPool3Data,
}

const moduloApprove4Data = {
  approveText: 'Approve WETH',
  approveDescription:
    'To deposit you must approve WETH, which requires a one-time gas fee.',
}

const moduloConfirm7Data = {
  confirmText: 'Confirm 3.0 WETH deposit',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your deposit to process.',
}

const moduloConfirm8Data = {
  confirmText: 'Success!',
  confirmDescription:
    'Check your portfolio for the deposit of 3.0 WETH and watch your balance increase in real time!',
  className: 'modulo-3',
}

const moduloWithdraw2Data = {
  moduloApproveProps: moduloApprove4Data,
  moduloConfirm1Props: moduloConfirm7Data,
  moduloConfirm2Props: moduloConfirm8Data,
}

const submitRepayButton1Data = {
  children: 'Join our Discord',
}

const submitRepayButton2Data = {
  children: 'Visit us on Twitter',
}

const mobileData = {
  submitRepayButton1Props: submitRepayButton1Data,
  submitRepayButton2Props: submitRepayButton2Data,
}

const subnavPool4Data = {
  subnavButtonTextSelected: 'Bid',
  subnavButtonText: 'Redeem',
  className: 'subnav-liquidate',
}

const liquidateData = {
  sectionTitle: 'Liquidate borrow against BAYC #3420',
  liquidationItemTitle: 'BAYC #3420',
  liquidationItemProject: 'Bored Ape Yacht Club',
  liquidationFloorPriceWeth: '100.000',
  liquidationFloorPriceTitle: 'Floor price',
  liquidationFloorPriceUsd: '$200,000.00',
  liquidationCrMin: 'Min. 150%',
  liquidationCrAmount: '140.00%',
  liquidationCrTitle: 'Collateralization Ratio',
  liquidationOwnerTitle: 'Current owner',
  liquidationOwnerAccount: '0x7612...9081',
  bidWeth: 'Bid WETH',
  bidTitle: 'Current bid',
  bidAmount: '2.564 WETH',
  bidAccount: '0x5473...8712',
  timeTitle: 'Auction ends in',
  hours1: '12',
  minutes1: '27',
  seconds1: '52',
  hours2: 'hours',
  minutes2: 'minutes',
  seconds2: 'seconds',
  subnavPoolProps: subnavPool4Data,
}

const moduloApprove5Data = {
  approveText: 'Approve WETH',
  approveDescription:
    'To create a borrow you must approve WETH, which requires a one-time gas fee.',
}

const moduloConfirm9Data = {
  confirmText: 'Confirm 3.0 WETH repayment',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your repayment to process.',
}

const moduloConfirm10Data = {
  confirmText: 'Success!',
  confirmDescription:
    'Check your portfolio for the reduction of 3.0 WETH. And your NFT once the full amount is repaid.',
  className: 'modulo-4',
}

const moduloLiquidate2Data = {
  moduloApproveProps: moduloApprove5Data,
  moduloConfirm1Props: moduloConfirm9Data,
  moduloConfirm2Props: moduloConfirm10Data,
}

const subnavPool5Data = {
  subnavButtonTextSelected: 'Bid',
  subnavButtonText: 'Redeem',
  className: 'subnav-liquidate-1',
}

const riskConfiguration3Data = {
  className: 'risk-configuration-2',
}

const redeemData = {
  sectionTitle: 'Redeem borrow against BAYC #3420',
  redeemItemTitle: 'BAYC #3420',
  redeemItemProject: 'Bored Ape Yacht Club',
  redeemItemFloorPriceUsd: '$200,000.00',
  redeemItemFloorPriceWeth: '100.000',
  redeemItemFloorPriceTitle: 'Floor price',
  redeemItemBorrowWeth: '54.124',
  redeemItemBorrowTitle: 'Borrow balance',
  redeemItemBorrowUsd: '$123,000.12',
  redeemLiquidationFeeWeth: '0.124',
  redeemLiquidationFeeTitle: 'Liquidation fee',
  redeemLiquidationFeeUsd: '$250.12',
  repayWeth: 'Repay WETH',
  subnavPoolProps: subnavPool5Data,
  riskConfigurationProps: riskConfiguration3Data,
}

const subnavPool6Data = {
  subnavButtonTextSelected: 'Bid',
  subnavButtonText: 'Redeem',
  className: 'subnav-liquidate-2',
}

const search4Data = {
  className: 'search-3',
}

const rowItem72Data = {
  className: 'row-item-9',
}

const rowItem82Data = {
  className: 'row-item-10',
}

const liquidationsData = {
  sectionTitle: 'Select defaulted borrow',
  headerItemText1: 'Collateral',
  headerItemText2: 'Col. Ratio',
  headerItemText3: 'Floor Price',
  headerItemText4: 'Current Bid',
  headerItemText5: 'Action Ending In',
  headerItemText6: 'Highest Bidder',
  headerItemText7: '140.00%',
  rowItemText1: '15h  51m  20s',
  rowItemText2: '0x5473...8712',
  headerItemText8: '140.00%',
  rowItemText3: '15h  51m  20s',
  rowItemText4: '0x5473...8712',
  subnavPoolProps: subnavPool6Data,
  searchProps: search4Data,
  rowItem7Props: rowItem72Data,
  rowItem8Props: rowItem82Data,
}

const moduloApprove6Data = {
  approveText: 'Approve BAYC #3420 as collateral',
  approveDescription:
    'To create a borrow you must approve this NFT as collateral, which requires a one-time gas fee.',
}

const moduloConfirm11Data = {
  confirmText: 'Confirm 3.0 WETH borrow',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your borrow to process.',
}

const moduloConfirm12Data = {
  confirmText: 'Success!',
  confirmDescription:
    'Check your wallet for the additional 3.0 WETH. Repay your borrow at any time to redeem your NFT.',
  className: 'modulo-5',
}

const moduloLiquidate3Data = {
  moduloApproveProps: moduloApprove6Data,
  moduloConfirm1Props: moduloConfirm11Data,
  moduloConfirm2Props: moduloConfirm12Data,
}

const dashboardAverageCr22Data = {
  averageCrTitle: 'Average Col. Ratio',
  averageCrAmount: '200.12%',
}

const dashboardAverageCr23Data = {
  averageCrTitle: 'Lowest Col. Ratio',
  averageCrAmount: '154.12%',
  className: 'dashboard-lowest-cr-4',
}

const subnavPortfolio2Data = {
  subnavButtonTextSelected: 'Borrow',
  subnavButtonText: 'Repay',
}

const riskConfiguration22Data = {
  liquidationPriceTitle: 'Liquidation Price',
  collateralizationRatioTitle: 'Collateralization Ratio',
  liquidationPriceAmount: '$180,000',
  collateralizationRatioAmount: '160.00%',
  riskMin: 'Decrease risk',
  riskMax: 'Increase Risk',
}

const darkData = {
  logoDarkMode: '/img/logo-dark-mode@2x.png',
  betaText: 'BETA',
  navMenuTextSelected: 'My Portfolio',
  navMenuText1: 'Lending Pools',
  navMenuText2: 'Liquidations',
  balanceEth: '14 ETH',
  wethBalance: '0.000',
  balanceAccountAddress: '0x65db...1234',
  liquidityTitle: 'Available liquidity',
  liquidityAmountWeth: '100.0000',
  liquidityAmountUsd: '$2,000,000.00',
  borrowTitle: 'Borrow balance',
  borrowAmountWeth: '10.0000',
  borrowAmountUsd: '$20,000.00',
  depositTitle: 'Deposit balance',
  depositAmountWeth: '1.2345',
  depositUsd: '$2,345.67',
  sectionTitle: 'Borrow against BAYC #3420',
  borrowItemTitle: 'Borrow balance',
  borrowBalanceWeth: '0.000',
  borrowBalanceUsd: '$0.00',
  floorPriceWeth: '100.000',
  floorPriceTitle: 'Floor price',
  floorPriceUsd: '$200,000.00',
  borrowInterestTitle: 'Interest rate',
  borrowInterestRate: '20% APR',
  borrowItemProject: 'Bored Ape Yacht Club',
  borrowItem: 'BAYC #3420',
  inputTitle: 'Borrow WETH',
  dashboardAverageCr21Props: dashboardAverageCr22Data,
  dashboardAverageCr22Props: dashboardAverageCr23Data,
  subnavPortfolio2Props: subnavPortfolio2Data,
  riskConfiguration2Props: riskConfiguration22Data,
}
