import React from "react";
import "./RowItem9.css";

function RowItem9(props) {
  const { currentBid } = props;

  return (
    <div className="row-item-11">
      <div className="current-bid-amount valign-text-middle oxanium-normal-black-18px">{currentBid ? currentBid.toFixed(3) : "--"}</div>
      { currentBid? <img className="weth-icon-38" src="/img/weth-icon-60@2x.svg" /> : <></>}
    </div>
  );
}

export default RowItem9;
