import { useEffect } from 'react'

import { useWeb3React } from '@web3-react/core'
import debug from 'debug'

import { injected } from '../connectors'

const logger = debug('fluidnft:useActivateWallet')

export const useActivateWallet = () => {
  const { active, activate } = useWeb3React()

  async function connect() {
    try {
      await activate(injected)
    } catch (ex) {
      logger(ex)
    }
  }

  useEffect(() => {
    if (!active) {
      connect()
    }
  }, [active])

  return [active, connect]
}
