import React, { useEffect, useState } from 'react'

import Popup from 'reactjs-popup'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import Nav from '../Nav'
import SubnavPool from '../SubnavPool'
import ModuloWithdraw from '../ModuloWithdraw'
import useAssetToken from '../../hooks/useAssetToken'
import useTransaction from '../../hooks/useTransaction'
import useLendingPool from '../../hooks/useLendingPool'
import { useAppContext } from '../../AppContext'
import { useToken } from '../../hooks/useToken'
import { theme } from '../../config/theme'
import './Withdraw.css'

function Withdraw(props) {
  const {
    sectionTitle,
    lendingPoolTitle,
    lendingPoolProject,
    depositLiquidityTitle,
    depositBalanceTitle,
    depositYieldTitle,
    inputTitle,
    subnavPoolProps,
    poolSymbol,
    poolCollectionName,
    poolCollectionImage,
    poolAvailableLiquidity,
    poolYield,
    userPoolDepositBalance,
  } = props
  const { account } = useWeb3React()
  const { fetchAssetTokenBalances } = useAssetToken()
  const { assetTokenBalanceWETH, fTokenBalanceWETH, pool, ethUsdPrice } =
    useAppContext()
  const { txnStatus, setTxnStatus } = useTransaction()
  const { withdraw, fetchPool } = useLendingPool()

  const { collectionTokenAddress, poolTokenName } = useParams()
  const { fetchEthUsdPrice } = useToken()

  // Oracle <- TODO: refactor and manage state
  let ethUsd = ethUsdPrice

  // Existing deposit balance
  let depositBalance = userPoolDepositBalance
  let depositBalanceUsd = depositBalance * ethUsd

  // Max values
  const maxInputValue = depositBalance

  // Initial Values
  const initialInputValue = ''
  const initialInputValueUsd = initialInputValue
    ? initialInputValue * ethUsd
    : 0

  // State management
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [inputValueUsd, setInputValueUsd] = useState(initialInputValueUsd)
  const [poolBalance, setPoolBalance] = useState('--')

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/lend')
  }

  // Txn
  const handleWithdrawSubmit = () => {
    withdraw(collectionTokenAddress, poolTokenName, String(inputValue))
  }

  useEffect(() => {
    if (account) {
      fetchAssetTokenBalances()
      fetchPool(collectionTokenAddress)
    }
  }, [account])

  useEffect(() => {
    if (account) {
      txnStatus
    }
  }, [account])

  useEffect(() => {
    if (pool) {
      setPoolBalance(pool.userBalance.toFixed(3))
    }
  }, [pool])

  useEffect(() => {
    setTxnStatus('')
  }, [])

  return (
    <div className='container-center-horizontal'>
      <div className='withdraw screen'>
        <Nav />
        <div className='flex-row-9'>
          <div className='section-container-3'>
            <a onClick={handleClick}>
              <img className='back-arrow-3' src='/img/back-arrow-3@2x.svg' />
            </a>
            <div className='section-title-6 valign-text-middle oxanium-medium-black-27px'>{`Withdraw from ${
              poolSymbol ? poolSymbol : '...'
            }`}</div>
          </div>
          <SubnavPool
            subnavButtonTextSelected={subnavPoolProps.subnavButtonTextSelected}
            subnavButtonText={subnavPoolProps.subnavButtonText}
            className={subnavPoolProps.className}
          />
        </div>
        <img className='section-line-6' src='/img/section-line-5@1x.svg' />
        <div className='flex-row-10'>
          <div className='lending-pool-div-2'>
            <div className='lending-pool-div-3'>
              <div className='lending-pool-title-2 valign-text-middle oxanium-medium-black-30px'>
                {poolSymbol}
              </div>
              <div className='lending-pool-project-1 valign-text-middle oxanium-medium-gravel-18px'>
                {poolCollectionName}
              </div>
            </div>
            <div className='overlap-group-25'>
              <img className='weth-pill-11' src='/img/weth-pill-2@2x.svg' />
              <img className='weth-icon-30' src='/img/weth-icon-56@2x.svg' />
              <div className='nft-collection-icon-8'>
                <img
                  className='nft-collection-icon-8-image'
                  src={poolCollectionImage}
                />
              </div>
            </div>
          </div>
          <div className='lending-pool-data'>
            <div className='deposit-item-liquidity'>
              <div className='overlap-group2-9'>
                <div className='weth-container-22'>
                  <img
                    className='weth-icon-25'
                    src='/img/weth-icon-51@2x.svg'
                  />
                  <div className='deposit-4 valign-text-middle oxanium-medium-black-30px'>
                    {poolAvailableLiquidity
                      ? poolAvailableLiquidity.toLocaleString('en', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 4,
                        })
                      : ''}
                  </div>
                </div>
                <div className='deposit-liquidity-title valign-text-middle oxanium-medium-granite-gray-18px'>
                  {depositLiquidityTitle}
                </div>
              </div>
              <div className='deposit-5 valign-text-middle oxanium-medium-star-dust-15px'>
                {poolAvailableLiquidity
                  ? `$${(poolAvailableLiquidity * ethUsd).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : ''}
              </div>
            </div>
            <div className='deposit-item-balance'>
              <div className='deposit-5 valign-text-middle oxanium-medium-granite-gray-18px'>
                {depositBalanceTitle}
              </div>
              <div className='weth-container-23'>
                {userPoolDepositBalance ? (
                  <img
                    className='weth-icon-26'
                    src='/img/weth-icon-50@2x.svg'
                  />
                ) : (
                  <></>
                )}
                <div className='deposit-4 valign-text-middle oxanium-medium-black-30px'>
                  {userPoolDepositBalance
                    ? userPoolDepositBalance.toLocaleString('en', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 4,
                      })
                    : '--'}
                </div>
              </div>
              <div className='deposit-5 valign-text-middle oxanium-medium-star-dust-15px'>
                {userPoolDepositBalance
                  ? `$${(userPoolDepositBalance * ethUsd).toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : '--'}
              </div>
            </div>
            <div className='deposit-item-yield'>
              <div className='deposit-yield-title valign-text-middle oxanium-medium-granite-gray-18px'>
                Yield
              </div>
              <div className='deposit-yield valign-text-middle oxanium-medium-black-30px'>
                {poolYield ? `${poolYield}% APY` : '--'}
              </div>
            </div>
          </div>
          <img
            className='section-divider-2'
            src='/img/section-divider-3@2x.svg'
          />
          <div className='withdraw-div'>
            <div className='input-title-2 valign-text-middle oxanium-medium-black-22px'>
              Withdraw WETH
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {(maxInputValue < poolAvailableLiquidity
                        ? maxInputValue
                        : poolAvailableLiquidity
                      ).toLocaleString('en', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 4,
                      })}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={
                          maxInputValue < poolAvailableLiquidity
                            ? maxInputValue
                            : poolAvailableLiquidity
                        }
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <ThemeProvider theme={theme}>
              {inputValue ? (
                inputValue <= poolAvailableLiquidity ? (
                  <>
                    {0 < inputValue && inputValue <= maxInputValue ? (
                      <Popup
                        trigger={
                          <div className='submit-withdraw-button'>
                            <StyledButton
                              variant='contained'
                              className='submit-withdraw-button'
                              onClick={handleWithdrawSubmit}
                            >
                              {`Withdraw ${inputValue.toLocaleString('en', {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 4,
                              })} WETH`}
                            </StyledButton>
                          </div>
                        }
                        modal
                        nested
                      >
                        <ModuloWithdraw
                          moduloTitle='Complete withdraw'
                          subTitle='Withdraw'
                          withdrawAmount={inputValue}
                          withdrawAmountUsd={inputValueUsd}
                          poolName={poolName}
                          poolNftProjectName={poolNftProjectName}
                          poolCollectionImage={poolCollectionImage}
                        />
                      </Popup>
                    ) : (
                      <div className='submit-withdraw-button'>
                        <StyledButton
                          disabled
                          variant='contained'
                          className='submit-withdraw-button'
                        >
                          {inputValue > maxInputValue
                            ? 'Insufficient balance'
                            : 'Select amount > 0'}
                        </StyledButton>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='submit-withdraw-button'>
                    <StyledButton
                      disabled
                      variant='contained'
                      className='submit-withdraw-button'
                    >
                      Insufficient liquidity
                    </StyledButton>
                  </div>
                )
              ) : (
                <div className='submit-withdraw-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-withdraw-button'
                  >
                    Select amount
                  </StyledButton>
                </div>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Withdraw

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})

// Pool data
let poolName = 'BAYC-WETH'
let poolNftProjectName = 'Bored Ape Yacht Club'
