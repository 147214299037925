import React from "react";
import ModuloApprove from "../ModuloApprove";
import ModuloConfirm from "../ModuloConfirm";
import ModuloSuccess from "../ModuloSuccess";
import "./ModuloWithdraw.css";

function ModuloWithdraw(props) {
  const { moduloTitle, subTitle, withdrawAmount, withdrawAmountUsd, poolName, poolNftProjectName, poolCollectionImage } = props;

  return (
    <div className="container-center-horizontal">
      <div className="modulo-withdraw-1">
        <div className="overlap-group2">
          <img className="data-line-2" src="/img/data-line@1x.svg" />
          <img className="header-line-3" src="/img/data-line@1x.svg" />
          <div className="modulo-title valign-text-middle oxanium-medium-black-30px">{moduloTitle}</div>
        </div>
        <div className="overlap-group1">
          <div className="overlap-group">
            <div className="amount-weth-3 valign-text-middle oxanium-medium-black-30px">{withdrawAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 4})}</div>
            <div className="amount-usd-3 valign-text-middle oxanium-medium-star-dust-16px">${withdrawAmountUsd.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
            <div className="repay-title-3 valign-text-middle oxanium-medium-granite-gray-16px">{subTitle}</div>
            <img className="weth-logo-6" src="/img/weth-logo-4@2x.svg" />
          </div>
          <div className="modulo-collateral">
            <div className="icon-container">
              <img className="weth-pill-111" src="/img/weth-pill-2@2x.svg" />
              <img className="weth-icon-301" src="/img/weth-icon-56@2x.svg" />
              <div className="nft-collection-icon-81">
                <img className="nft-collection-icon-81-image" src={poolCollectionImage} />
              </div>
            </div>
            <div className="nft-container-11">
              <div className="nft-title valign-text-middle oxanium-medium-black-30px">{poolName}</div>
              <div className="nft-project valign-text-middle oxanium-medium-gravel-20px">{poolNftProjectName}</div>
            </div>
          </div>
        </div>
        <ModuloApprove
          approveText="Approve WETH"
          approveDescription="To withdraw you must approve WETH, which requires a one-time gas fee."
        />
        <ModuloConfirm
          confirmText={`Confirm ${withdrawAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 4})} WETH withdraw`}
          confirmDescription="Accept the signature request in your wallet and wait for your withdraw to process."
        />
        <ModuloSuccess
          confirmText="Success!"
          confirmDescription={`Check your wallet for the additional ${withdrawAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 4})} WETH. This change will be reflected in "My Portfolio" as well.`}
          className="modulo-3"
        />
      </div>
    </div>
  );
}

export default ModuloWithdraw;
