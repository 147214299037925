import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import debug from 'debug'

import Liquidate from '../Liquidate'
import Redeem from '../Redeem'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import './Liquidation.css'

const logger = debug('fluidnft:Liquidation')

function Liquidation(props) {
  const {
    sectionTitle,
    liquidationItemTitle,
    liquidationItemProject,
    subnavPoolProps,
    riskConfigurationProps,
  } = props
  const {
    isLiquidationLiquidate,
    setNftTokenAddress,
    setNftTokenId,
    setNftCollectionName,
    nftCollectionName,
    setNftCollectionSymbol,
    nftCollectionSymbol,
    setNftImage,
    nftImage,
    nft,
  } = useAppContext()
  const { tokenAddress, tokenId } = useParams()
  const { fetchNft, formatNftData } = useNFT()
  let nftData = {}

  useEffect(() => {
    fetchNft(tokenAddress, tokenId)
  }, [])

  useEffect(() => {
    if (Object.keys(nft).length !== 0) {
      logger('nft', nft)
      nftData = formatNftData(nft)
      setNftTokenAddress(nftData.tokenAddress)
      setNftTokenId(nftData.tokenId)
      setNftCollectionName(nftData.collectionName)
      setNftCollectionSymbol(nftData.collectionSymbol)
      setNftImage(nftData.image)
    }
  }, [nft])

  return (
    <>
      {isLiquidationLiquidate ? (
        <Liquidate
          sectionTitle={sectionTitle}
          liquidationItemTitle={liquidationItemTitle}
          liquidationItemProject={liquidationItemProject}
          subnavPoolProps={subnavPoolProps}
          tokenAddress={tokenAddress}
          tokenId={tokenId}
          nftName={
            nftCollectionSymbol
              ? `${nftCollectionSymbol} #${tokenId}`
              : `#${tokenId}`
          }
          nftProjectName={nftCollectionName}
          nftImage={nftImage}
        />
      ) : (
        <Redeem
          subnavPoolProps={subnavPoolProps}
          riskConfigurationProps={riskConfigurationProps}
          tokenAddress={tokenAddress}
          tokenId={tokenId}
          nftName={
            nftCollectionSymbol
              ? `${nftCollectionSymbol} #${tokenId}`
              : `#${tokenId}`
          }
          nftProjectName={nftCollectionName}
          nftImage={nftImage}
        />
      )}
    </>
  )
}

export default Liquidation
