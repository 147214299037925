import { useWeb3React } from '@web3-react/core'
import { formatUnits, parseUnits } from '@ethersproject/units'
import debug from 'debug'

import { useContract } from './useContract'
import useIsValidNetwork from './useIsValidNetwork'
import { useAppContext } from '../AppContext'
import useFToken from './useFToken'
import useDebtToken from './useDebtToken'
import useNFT from './useNFT'
import useAssetToken from './useAssetToken'
import useCollateralManager from './useCollateralManager'
import LendingPoolData from '../artifacts/contracts/LendingPool/LendingPool.json'
import CollateralManagerData from '../artifacts/contracts/CollateralManager/CollateralManager.json'
import InterestRateData from '../artifacts/contracts/InterestRateStrategy/InterestRateStrategy.json'

const logger = debug('fluidnft:useLendingPool')

export const useLendingPool = () => {
  const { account } = useWeb3React()
  const { isValidNetwork } = useIsValidNetwork()
  const lendingPoolContractAddress =
    process.env.REACT_APP_LENDING_POOL_CONTRACT_ADDRESS
  const lendingPoolABI = LendingPoolData['abi']
  const lendingPoolContract = useContract(
    lendingPoolContractAddress,
    lendingPoolABI
  )

  const {
    setBorrowFloorPrice,
    borrowFloorPrice,
    setTxnStatus,
    borrowProject,
    borrowToken,
    setPools,
    setPool,
  } = useAppContext()
  const { fTokenContract, fetchFTokenBalance } = useFToken()
  const { debtTokenContract, fetchDebtTokenBalance } = useDebtToken()
  const { assetTokenContract, assetTokenContractAddress } = useAssetToken()
  const { nftContract } = useNFT()
  const { collateralManagerContractAddress } = useCollateralManager()
  const collateralManagerABI = CollateralManagerData['abi']
  const collateralManagerContract = useContract(
    collateralManagerContractAddress,
    collateralManagerABI
  )

  const assetTokenContractAddressSymbolLookup = {}
  assetTokenContractAddressSymbolLookup[
    process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS
  ] = 'WETH'

  const interestRateContractAddress =
    process.env.REACT_APP_INTEREST_RATE_STRATEGY_CONTRACT_ADDRESS
  const assetTokenAddress =
    process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS
  const fTokenAddress = process.env.REACT_APP_N_TOKEN_WETH_CONTRACT_ADDRESS
  const debtTokenAddress =
    process.env.REACT_APP_DEBT_TOKEN_WETH_CONTRACT_ADDRESS

  function wait(seconds) {
    return new Promise((res) => setTimeout(res, seconds * 1000))
  }

  const interestRateABI = InterestRateData['abi']
  const interestRateContract = useContract(
    interestRateContractAddress,
    interestRateABI
  )

  const fetchBorrowFloorPrice = async () => {
    if (borrowProject != '--') {
      const nftContractAddress = await nftContract[borrowProject].address
      const assetTokenContractAddress = await assetTokenContract[borrowToken]
        .address
      // TODO update: second parameter should be assetToken (not currently used by SC)
      const price = await lendingPoolContract.getMockFloorPrice(
        nftContractAddress,
        assetTokenContractAddress
      )
      setBorrowFloorPrice(formatUnits(price, 18))
    }
  }

  const deposit = async (collateralContractAddress, tokenSymbol, amount) => {
    const onBehalfOf = account // TODO enable vars to be passed through UI
    const referralCode = '123' // TODO enable vars to be passed through UI
    logger('DEPOSIT:', collateralContractAddress, tokenSymbol, amount)
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        const tokenContract = assetTokenContract[tokenSymbol]
        const txn1 = await tokenContract.approve(
          lendingPoolContract.address,
          parseUnits(amount, 18)
        ) // TODO: remove hard-coded decimals
        setTxnStatus('ACCEPTING')
        await txn1.wait(1)
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        const txn2 = await lendingPoolContract.deposit(
          collateralContractAddress,
          tokenContractAddress,
          parseUnits(amount, 18),
          onBehalfOf,
          referralCode
        ) // TODO: remove hard-coded decimals
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchFTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        // await wait(10);
        // setTxnStatus("");
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        // await wait(10);
        // setTxnStatus("");
      }
    }
  }

  const withdraw = async (collateralContractAddress, tokenSymbol, amount) => {
    const to = account // // TODO enable vars to be passed through UI (to allow withdraw to alteranative address)
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        const _fTokenContract = fTokenContract[tokenSymbol]
        const txn1 = await _fTokenContract.approve(
          lendingPoolContract.address,
          parseUnits(amount, 18)
        ) // TODO: remove hard-coded decimals
        setTxnStatus('ACCEPTING')
        await txn1.wait(1)
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        const txn2 = await lendingPoolContract.withdraw(
          collateralContractAddress,
          tokenContractAddress,
          parseUnits(amount, 18),
          to
        ) // TODO: remove hard-coded decimals
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchFTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        // await wait(10);
        // setTxnStatus("");
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        // await wait(10);
        // setTxnStatus("");
      }
    }
  }

  const borrow = async (
    nftTokenAddress,
    nftTokenId,
    tokenAmount,
    tokenSymbol
  ) => {
    const onBehalfOf = account // TODO enable vars to be passed through UI
    const referralCode = '123' // TODO enable vars to be passed through UI
    logger('tokenSymbol', tokenSymbol)
    logger('account', account, isValidNetwork, nftTokenAddress)
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        logger('nftTokenAddress', nftTokenAddress)
        const nftTokenContract = nftContract['BAYC'] //nftTokenAddress
        logger('nftTokenContract', nftTokenContract)

        // let isApproved = await nftTokenContract.isApprovedForAll(
        //   account,
        //   collateralManagerContractAddress
        // )

        // if (!isApproved) {
        try {
          const txn1 = await nftTokenContract.setApprovalForAll(
            collateralManagerContractAddress,
            true
          )
          await txn1.wait(1)
        } catch (error) {
          logger(error)
          setTxnStatus('ERROR')
          await wait(10)
          setTxnStatus('')
          return { status: 'error' }
        }
        setTxnStatus('ACCEPTING')
        // }

        logger('tokenSymbol', tokenSymbol)
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        logger('---')
        logger('tokenContractAddress', tokenContractAddress)
        logger('tokenAmount', parseUnits(String(tokenAmount), 18))
        logger('nftTokenAddress', nftTokenAddress)
        logger('String(nftTokenId))', String(nftTokenId))
        logger('---')
        const txn2 = await lendingPoolContract.borrow(
          tokenContractAddress,
          parseUnits(String(tokenAmount), 18),
          nftTokenAddress,
          String(nftTokenId),
          onBehalfOf,
          referralCode
        )
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchDebtTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        await wait(10)
        setTxnStatus('')
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        await wait(10)
        setTxnStatus('')
      }
    }
  }

  const batchBorrow = async ({
    nftTokenAddresses,
    nftTokenIds,
    tokenAmounts,
    tokenSymbols,
    informStatus,
  }) => {
    const onBehalfOf = account // TODO enable vars to be passed through UI
    const referralCode = '123' // TODO enable vars to be passed through UI
    logger('tokenSymbols', tokenSymbols)
    logger('account', account, isValidNetwork, nftTokenAddresses)
    if (account && isValidNetwork) {
      setTxnStatus('LOADING')
      informStatus('LOADING')
      logger('nftTokenAddresses', nftTokenAddresses)
      const nftTokenContract = nftContract['BAYC']
      logger('nftTokenContract', nftTokenContract)

      // let isApproved = await nftTokenContract.isApprovedForAll(
      //   account,
      //   collateralManagerContractAddress
      // )

      // if (!isApproved) {
      try {
        const txn1 = await nftTokenContract.setApprovalForAll(
          collateralManagerContractAddress,
          true
        )
        await txn1.wait(1)
      } catch (error) {
        logger(error)
        setTxnStatus('ERROR')
        informStatus('ERROR')
        await wait(10)
        setTxnStatus('')
        return { status: 'error' }
      }
      setTxnStatus('ACCEPTING')
      informStatus('ACCEPTING')
      // }

      logger('tokenSymbols', tokenSymbols)
      const tokenContractAddresses = tokenSymbols.map(
        (ts) => assetTokenContractAddress[ts]
      )
      const parsedTokenAmounts = tokenAmounts.map((tokenAmount) =>
        parseUnits(String(tokenAmount), 18)
      )
      logger('---')
      logger('tokenContractAddress', tokenContractAddresses)
      logger('tokenAmount', parsedTokenAmounts)
      logger('nftTokenAddresses', nftTokenAddresses)
      logger(
        'nftTokenIds String(nftTokenId))',
        nftTokenIds.map((id) => String(id))
      )
      logger('---')
      try {
        // IMPORTANT: call this once!!
        const txn2 = await lendingPoolContract.batchBorrow(
          tokenContractAddresses,
          parsedTokenAmounts,
          nftTokenAddresses,
          nftTokenIds.map((id) => String(id)),
          onBehalfOf, // not a list
          referralCode // not a list
        )
        setTxnStatus('TRANSFERRING')
        informStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchDebtTokenBalance(tokenSymbols[0])
        setTxnStatus('COMPLETE')
        informStatus('COMPLETE')
        await wait(3)
        setTxnStatus('')
        return { status: 'success' }
      } catch (error) {
        setTxnStatus('ERROR')
        informStatus('ERROR')
        logger('ERROR', error)
        await wait(10)
        setTxnStatus('')
        return { status: 'error' }
      }
    }
  }

  const repay = async (borrowId, nftAddress, tokenAmount, tokenSymbol) => {
    logger('borrowId', borrowId)
    logger('tokenAmount', tokenAmount)
    logger('tokenSymbol', tokenSymbol)
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        const tokenContract = assetTokenContract[tokenSymbol]
        const _fTokenContract = fTokenContract[tokenSymbol]
        const txn1 = await tokenContract.approve(
          lendingPoolContract.address,
          parseUnits(tokenAmount, 18)
        )
        setTxnStatus('ACCEPTING')
        await txn1.wait(1)
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        logger('got here tokenContractAddress', tokenContractAddress)
        logger('nftAddress', nftAddress)
        logger('tokenContractAddress', tokenContractAddress)
        logger('tokenAmount', tokenAmount)
        logger('borrowId', borrowId)
        const txn2 = await lendingPoolContract.repay(
          nftAddress,
          tokenContractAddress,
          parseUnits(tokenAmount, 18),
          borrowId
        ) // TODO: remove hard-coded decimals
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchDebtTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        await wait(10)
        setTxnStatus('')
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        await wait(10)
        setTxnStatus('')
      }
    }
  }

  const redeem = async (borrowId, nftAddress, tokenAmount, tokenSymbol) => {
    logger('borrowId', borrowId)
    logger('tokenAmount', tokenAmount)
    logger('tokenSymbol', tokenSymbol)
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        const tokenContract = assetTokenContract[tokenSymbol]
        const _fTokenContract = fTokenContract[tokenSymbol]
        const txn1 = await tokenContract.approve(
          lendingPoolContract.address,
          parseUnits(tokenAmount, 18)
        )
        setTxnStatus('ACCEPTING')
        await txn1.wait(1)
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        logger('got here tokenContractAddress', tokenContractAddress)
        logger('nftAddress', nftAddress)
        logger('tokenContractAddress', tokenContractAddress)
        logger('tokenAmount', tokenAmount)
        logger('borrowId', borrowId)
        const txn2 = await lendingPoolContract.repay(
          nftAddress,
          tokenContractAddress,
          parseUnits(tokenAmount, 18),
          borrowId
        ) // TODO: remove hard-coded decimals
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchDebtTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        await wait(10)
        setTxnStatus('')
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        await wait(10)
        setTxnStatus('')
      }
    }
  }

  const bid = async (nftTokenAddress, nftTokenId, tokenAmount, tokenSymbol) => {
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        const borrowId = await collateralManagerContract.getBorrowId(
          nftTokenAddress,
          nftTokenId
        )
        const tokenContract = assetTokenContract[tokenSymbol]
        const txn1 = await tokenContract.approve(
          lendingPoolContract.address,
          parseUnits(tokenAmount.toString(), 18)
        )
        setTxnStatus('ACCEPTING')
        await txn1.wait(1)
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        const txn2 = await lendingPoolContract.bid(
          tokenContractAddress,
          parseUnits(tokenAmount.toString(), 18),
          borrowId
        ) // TODO: remove hard-coded decimals
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchDebtTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        await wait(10)
        setTxnStatus('')
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        await wait(10)
        setTxnStatus('')
      }
    }
  }

  const liquidate = async (nftTokenAddress, tokenSymbol, borrowId) => {
    if (account && isValidNetwork) {
      try {
        setTxnStatus('LOADING')
        // setTxnStatus("ACCEPTING");
        // await txn1.wait(1);
        const tokenContractAddress = assetTokenContractAddress[tokenSymbol]
        const txn2 = await lendingPoolContract.liquidate(
          nftTokenAddress,
          tokenContractAddress,
          borrowId
        ) // TODO: remove hard-coded decimals
        setTxnStatus('TRANSFERRING')
        await txn2.wait(1)
        await fetchDebtTokenBalance(tokenSymbol)
        setTxnStatus('COMPLETE')
        await wait(10)
        setTxnStatus('')
      } catch (error) {
        setTxnStatus('ERROR')
        logger('ERROR', error)
        await wait(10)
        setTxnStatus('')
      }
    }
  }

  const fetchPools = async () => {
    logger('run fetchPools', account, isValidNetwork)
    if (account && isValidNetwork) {
      logger('run fetchPools')
      // TODO: include support for more than one pool
      const lendingPools = [
        {
          collateral: process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS,
          asset: process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS,
        },
      ]

      logger('lendingPools', lendingPools)
      logger('lendingPools[0]["collateral"]', lendingPools[0]['collateral'])
      logger('lendingPools[0]["asset"]', lendingPools[0]['asset'])
      let pools = []

      let poolData = await lendingPoolContract.getReserveData(
        lendingPools[0]['collateral'],
        lendingPools[0]['asset']
      ) // collateral and asset

      pools.push({
        tokenAddress: lendingPools[0]['collateral'], // collateral
        userBalance: parseFloat(formatUnits(poolData[0], 18)),
        deposit: parseFloat(formatUnits(poolData[1], 18)),
        borrowed: parseFloat(formatUnits(poolData[2], 18)),
        apy: await getInterestRate(),
      })

      logger('setPools', pools)
      setPools(pools)
    }
  }

  const getInterestRate = async (nftProject) => {
    if (account) {
      const totalVariableDebt = debtTokenContract['WETH'].totalSupply()
      const interestRates = await interestRateContract.calculateInterestRates(
        assetTokenAddress, //assetTokenAddress
        fTokenAddress, //fTokenAddress
        '0', // liquidityAdded
        '0', // liquidityTaken
        totalVariableDebt, // totalVariableDebt
        '3000' // reserveFactor 30%
      )
      logger('fetchInterestRate', interestRates[1])
      const ir = formatUnits(interestRates[1], 27)
      logger('ir', ir)
      return ir
    }
  }

  const fetchPool = async (collateral) => {
    // TODO: enable support for non-weth assets
    const asset = process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS
    logger('RUN::> fetchPools', account, isValidNetwork)
    if (account && isValidNetwork) {
      logger('run fetchPool')

      let poolData = await lendingPoolContract.getReserveData(collateral, asset)
      logger('poolData', poolData)

      let pool = {
        tokenAddress: collateral, // collateral
        userBalance: parseFloat(formatUnits(poolData[0], 18)),
        deposit: parseFloat(formatUnits(poolData[1], 18)),
        borrowed: parseFloat(formatUnits(poolData[2], 18)),
        apy: await getInterestRate(), // TODO: pass nftProject to make dynamic
      }

      logger('setPool', pool)
      setPool(pool)
    }
  }

  return {
    assetTokenContractAddressSymbolLookup,
    fetchBorrowFloorPrice,
    borrowFloorPrice,
    deposit,
    withdraw,
    borrow,
    batchBorrow,
    repay,
    redeem,
    liquidate,
    bid,
    fetchPools,
    fetchPool,
  }
}

export default useLendingPool
