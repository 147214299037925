import React from "react";
import "./SubnavPortfolio.css";

function SubnavPortfolio(props) {
  const { subnavButtonText, subnavButtonTextSelected } = props;

  return (
    <div className="container-center-horizontal">
      <div className="subnav-portfolio-5 screen">
        <div className="overlap-group-container-5">
          <div className="overlap-group-30">
            <div className="subnav-button-text-11 valign-text-middle oxanium-medium-black-18px">{subnavButtonText}</div>
          </div>
          <div className="overlap-group1-17">
            <div className="subnav-button-text-11 valign-text-middle oxanium-medium-white-18px">
              {subnavButtonTextSelected}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubnavPortfolio;
