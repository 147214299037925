import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { ThemeProvider } from '@mui/material/styles'
import Spinner from 'react-spinkit'

import { useSelectNft } from '../../hooks/state/useSelectNft'
import { theme } from '../../config/theme'
import './NFT.css'

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 10,
  padding: '6px 0 6px 8px',
  borderRadius: 4,
  width: '100%',
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledCardButton = styled('div')({
  borderRadius: 10,
})

function NFT(props) {
  const {
    className,
    tokenAddress,
    tokenId,
    image,
    availableLiquidity,
    collectionSymbol,
  } = props
  const [hoverCard, setHoverCard] = useState('')
  const [selectedNfts, setNftId] = useSelectNft()

  const handleSelectCheck = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { type: targetType } = e.target
    if (targetType !== 'button') {
      if (selectedNfts.has(tokenId)) {
        selectedNfts.delete(tokenId)
      } else {
        selectedNfts.add(tokenId)
      }
      setNftId(selectedNfts)
    }
  }

  const handleMouseEnter = () => {
    setHoverCard(tokenId)
  }

  const handleMouseLeave = () => {
    setHoverCard('')
  }

  return (
    <StyledCardButton
      onClick={handleSelectCheck}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='fluid-nft-card'
    >
      <div className={`link-2 ${className || ''}`}>
        <div
          className='overlap-group-16'
          style={{
            borderRadius: '7.41px',
            boxShadow: selectedNfts?.has(tokenId)
              ? '0 0 0 3px black, 0 2px 4px black'
              : '',
          }}
        >
          <div className='nft-pill border-0-7px-mist-gray'></div>
          <Spinner
            name='three-bounce'
            color='#ececec'
            className='image-loading'
          />
          <div className='nft-collection-icon-2'>
            <LazyLoadImage
              className='nft-collection-icon-2'
              src={image}
              onLoad={(event) => (event.target.style.display = 'inline-block')}
              onError={(event) => (event.target.style.display = 'none')}
            />
          </div>
          {Boolean(selectedNfts?.has(tokenId)) && (
            <div className='fluid-nft-icon'>
              <ThemeProvider theme={theme}>
                <CheckIcon />
              </ThemeProvider>
            </div>
          )}
          {hoverCard === tokenId && !Boolean(selectedNfts?.has(tokenId)) && (
            <div className='fluid-nft-icon'>
              <AddIcon opacity={0.5} />
            </div>
          )}
          <div className='collection-text valign-text-middle oxanium-medium-black-12px'>
            {collectionSymbol} #{tokenId}
          </div>
          <div className='token-text valign-text-middle oxanium-medium-black-12px'>
            <div className='weth-price valign-text-middle oxanium-medium-black-12px'>
              {availableLiquidity}
            </div>
            <img className='weth-icon-20' src='/img/weth-icon-1@2x.svg' />
          </div>
          <div className='available-liquidity-container'>
            <Link to={`/asset/${tokenAddress}/${tokenId}`}>
              <ThemeProvider theme={theme}>
                <StyledButton variant='outlined'>
                  <span style={{ paddingTop: 2 }}>Borrow</span>
                  <ChevronRight fontSize='small' />
                </StyledButton>
              </ThemeProvider>
            </Link>
          </div>
        </div>
      </div>
    </StyledCardButton>
  )
}

export default NFT
