import React from "react";
import "./DashboardAverageCr.css";

function DashboardAverageCr(props) {
  const { averageCrTitle, averageCrAmount, className } = props;

  return (
    <div className={`dashboard-average-cr ${className || ""}`}>
      <div className="x-cr valign-text-middle oxanium-medium-granite-gray-18px">{averageCrTitle}</div>
      <div className="x-cr-amount valign-text-middle oxanium-medium-black-30px">{averageCrAmount}</div>
      <div className="x-cr valign-text-middle oxanium-medium-star-dust-16-3px">Min. 150%</div>
    </div>
  );
}

export default DashboardAverageCr;