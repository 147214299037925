import React, { useEffect, useState } from 'react'

import { useWeb3React } from '@web3-react/core'
import debug from 'debug'

import DashboardAverageCr from '../DashboardAverageCr'
import { useAppContext } from '../../AppContext'
import { useToken } from '../../hooks/useToken'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import { useLendingPool } from '../../hooks/useLendingPool'
import './Dashboard.css'

const logger = debug('fluidnft:Dashboard')

function Dashboard(props) {
  const { dashboardAverageCr1Props, dashboardAverageCr2Props } = props
  const { availableLiquidity, userBorrows, ethUsdPrice, pools } =
    useAppContext()
  const { fetchEthUsdPrice } = useToken()
  const { fetchUserBorrows } = useCollateralManager()
  const { account } = useWeb3React()
  const { fetchPools } = useLendingPool()

  const [myAvailableLiquidity, setMyAvailableLiquidity] = useState(0)
  const [ethUsd, setEthUsd] = useState(0)
  const [myBorrowBalance, setMyBorrrowBalance] = useState(0)
  const [myPoolBalance, setMyPoolBalance] = useState(0)
  const [myAvgColRatio, setMyAvgColRatio] = useState(0)
  const [myLowestColRatio, setMyLowestColRatio] = useState(0)

  const getBorrowBalance = (userBorrows) => {
    let balance = 0
    logger('userBorrows', userBorrows)
    userBorrows.map((userBorrow) => (balance += userBorrow.borrowAmount))
    return balance
  }

  const getAvgColRatio = (userBorrows) => {
    let ratioSum = 0
    userBorrows.map((userBorrow) => (ratioSum += userBorrow.collRatio))
    return ratioSum / userBorrows.length
  }

  const getLowestColRatio = (userBorrows) => {
    let ratios = []
    userBorrows.map((userBorrow) => ratios.push(userBorrow.collRatio))
    return Math.min(ratios)
  }

  useEffect(() => {
    fetchEthUsdPrice()
    fetchUserBorrows()
    fetchPools()
  }, [account])

  useEffect(() => {
    logger('userBorrows updated', userBorrows, Object(userBorrows).length)
    if (Object.values(userBorrows).length > 0) {
      setMyBorrrowBalance(getBorrowBalance(Object.values(userBorrows)))
      setMyAvgColRatio(getAvgColRatio(Object.values(userBorrows)))
      setMyLowestColRatio(getLowestColRatio(Object.values(userBorrows)))
    }
  }, [userBorrows])

  useEffect(() => {
    setMyAvailableLiquidity(availableLiquidity)
  }, [availableLiquidity])

  useEffect(() => {
    setEthUsd(ethUsdPrice)
  }, [ethUsdPrice])

  useEffect(() => {
    logger('POOLS=>>', pools)
    let balance = 0
    if (pools) {
      pools.map((pool) => (balance += pool.userBalance))
      setMyPoolBalance(balance)
    }
  }, [pools, account])

  return (
    <div className='dashboard'>
      <div className='dashboard-container'>
        <div className='dashboard-liquidity'>
          <div className='x-title valign-text-middle oxanium-medium-granite-gray-18px'>
            You can borrow
          </div>
          <div className='liquidity-amount'>
            <div className='liquidity-amount-weth valign-text-middle oxanium-medium-black-30px'>
              {myAvailableLiquidity
                ? myAvailableLiquidity.toLocaleString('en', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 3,
                  })
                : myAvailableLiquidity}
            </div>
            <img className='weth-icon-16' src='/img/weth-icon-17@2x.svg' />
          </div>
          <div className='x-amount-usd valign-text-middle oxanium-medium-star-dust-16px'>
            $
            {ethUsd && myAvailableLiquidity
              ? (myAvailableLiquidity * ethUsdPrice).toLocaleString('en', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </div>
        </div>
        <div className='dashboard-borrow'>
          <div className='x-title valign-text-middle oxanium-medium-granite-gray-18px'>
            Borrow balance
          </div>
          <div className='borrow-amount'>
            <div className='borrow-amount-weth valign-text-middle oxanium-medium-black-30px'>
              {myBorrowBalance
                ? myBorrowBalance.toLocaleString('en', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 3,
                  })
                : myBorrowBalance}
            </div>
            <img className='weth-icon-12' src='/img/weth-icon-16@2x.svg' />
          </div>
          <div className='x-amount-usd valign-text-middle oxanium-medium-star-dust-16px'>
            $
            {ethUsd && myBorrowBalance
              ? (myBorrowBalance * ethUsdPrice).toLocaleString('en', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </div>
        </div>
        <div className='dashboard-deposit'>
          <div className='deposit valign-text-middle oxanium-medium-granite-gray-18px'>
            Lend balance
          </div>
          <div className='deposit-amount'>
            <div className='deposit-amount-weth valign-text-middle oxanium-medium-black-30px'>
              {myPoolBalance
                ? myPoolBalance.toLocaleString('en', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 3,
                  })
                : myPoolBalance}
            </div>
            <img className='weth-icon-12' src='/img/weth-icon-15@2x.svg' />
          </div>
          <div className='deposit valign-text-middle oxanium-medium-star-dust-16px'>
            $
            {ethUsd && myPoolBalance
              ? (myPoolBalance * ethUsdPrice).toLocaleString('en', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </div>
        </div>
      </div>
      <div className='dashboard-container-1'>
        {/* <div className={`dashboard-average-cr`}>
          <div className="x-cr valign-text-middle oxanium-medium-granite-gray-18px">{dashboardAverageCr1Props.averageCrTitle}</div>
          <div className="x-cr-amount valign-text-middle oxanium-medium-black-30px">{dashboardAverageCr1Props.averageCrAmount}</div>
          <div className="x-cr valign-text-middle oxanium-medium-star-dust-16-3px">Min. 150%</div>
        </div> */}
        <DashboardAverageCr
          averageCrTitle="Average Collateralization"
          averageCrAmount={`${
            myAvgColRatio
              ? myAvgColRatio.toLocaleString('en', {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                })
              : '--'
          }%`}
        />
        <DashboardAverageCr
          averageCrTitle="Lowest Collateralization"
          averageCrAmount={`${
            myLowestColRatio
              ? myLowestColRatio.toLocaleString('en', {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                })
              : '--'
          }%`}
          className={dashboardAverageCr2Props.className}
        />
        {/* <div className={`dashboard-average-cr ${dashboardAverageCr2Props.className}`}>
          <div className="x-cr valign-text-middle oxanium-medium-granite-gray-18px">{dashboardAverageCr2Props.averageCrTitle}</div>
          <div className="x-cr-amount valign-text-middle oxanium-medium-black-30px">{dashboardAverageCr2Props.averageCrAmount}</div>
          <div className="x-cr valign-text-middle oxanium-medium-star-dust-16-3px">Min. 150%</div>
        </div> */}
      </div>
    </div>
  )
}

export default Dashboard
