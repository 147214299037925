import { useWeb3React } from '@web3-react/core'
import { formatUnits } from '@ethersproject/units'
import debug from 'debug'

import { useContract } from './useContract'
import useIsValidNetwork from './useIsValidNetwork'
import { useAppContext } from '../AppContext'
import DebtTokenData from '../artifacts/contracts/DebtToken/DebtToken.json'
import InterestRateData from '../artifacts/contracts/InterestRateStrategy/InterestRateStrategy.json'

const logger = debug('fluidnft:useInterestRate')

export const useInterestRate = () => {
  const { account } = useWeb3React()
  const { isValidNetwork } = useIsValidNetwork()
  const { setInterestRate } = useAppContext()

  // Addresses
  const interestRateContractAddress =
    process.env.REACT_APP_INTEREST_RATE_STRATEGY_CONTRACT_ADDRESS
  const assetTokenAddress =
    process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS
  const fTokenAddress = process.env.REACT_APP_N_TOKEN_WETH_CONTRACT_ADDRESS
  const debtTokenAddress =
    process.env.REACT_APP_DEBT_TOKEN_WETH_CONTRACT_ADDRESS

  const interestRateABI = InterestRateData['abi']
  const interestRateContract = useContract(
    interestRateContractAddress,
    interestRateABI
  )

  const debtTokenABI = DebtTokenData['abi']
  const debtTokenContract = useContract(debtTokenAddress, debtTokenABI)

  const fetchInterestRate = async (nftProject) => {
    if (account) {
      const totalVariableDebt = debtTokenContract.totalSupply()
      const interestRates = await interestRateContract.calculateInterestRates(
        assetTokenAddress, //assetTokenAddress
        fTokenAddress, //fTokenAddress
        '0', // liquidityAdded
        '0', // liquidityTaken
        totalVariableDebt, // totalVariableDebt
        '3000' // reserveFactor 30%
      )
      logger('fetchInterestRate', interestRates[1])
      const ir = formatUnits(interestRates[1], 27)
      setInterestRate(ir)
      return ir
    }
  }

  return {
    fetchInterestRate,
  }
}

export default useInterestRate
