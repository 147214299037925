import React from "react";
import "./RowItem10.css";

function RowItem10(props) {
  const { marketSize } = props;

  return (
    <div className="row-item-6">
      <div className="market-size-title valign-text-middle oxanium-normal-black-18px">{marketSize.toFixed(3)}</div>
      <img className="weth-icon-28" src="/img/weth-icon-42@2x.svg" />
    </div>
  );
}

export default RowItem10;
