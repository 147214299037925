import { useMutation, useQuery, useQueryClient } from 'react-query'
import debug from 'debug'

const logger = debug('fluidnft:usePersistentState')

export const usePersistentContext = (key) => {
  const queryClient = useQueryClient()
  const normalizeKey = `persistentState-${key}`

  const { data } = useQuery(normalizeKey, () => {
    const localStorageValue = localStorage.getItem(normalizeKey)
    try {
      return JSON.parse(localStorageValue)
    } catch (error) {
      logger(error)
    }
    return localStorageValue
  })

  const { mutateAsync: setValue } = useMutation(
    (value) => {
      return localStorage.setItem(normalizeKey, JSON.stringify(value))
    },
    {
      onMutate: (mutatedData) => {
        const current = data
        queryClient.setQueryData(normalizeKey, mutatedData)
        return current
      },
      onError: (_, __, rollback) => {
        queryClient.setQueryData(normalizeKey, rollback)
      },
    }
  )

  return [data, setValue]
}
