import { useWeb3React } from "@web3-react/core";
import useIsValidNetwork from "./useIsValidNetwork";
import { useContract } from "./useContract";
import { useAppContext } from "../AppContext";
import { formatUnits } from "@ethersproject/units";
import AssetTokenData from "../artifacts/contracts/mocks/AssetToken/AssetToken.json";


export const useAssetToken = () => {
    const { account } = useWeb3React();
    const { isValidNetwork } = useIsValidNetwork();
    const { setAssetTokenBalanceWETH } = useAppContext();

    const assetTokenContractAddressWETH = process.env.REACT_APP_ASSET_TOKEN_WETH_CONTRACT_ADDRESS;
    const assetTokenContractAddress = {
        "WETH": assetTokenContractAddressWETH
    }

    const assetTokenABI = AssetTokenData["abi"];
    const assetTokenContractWETH = useContract(assetTokenContractAddressWETH, assetTokenABI);

    const assetTokenContract = {
        "WETH": assetTokenContractWETH
    }

    const fetchAssetTokenBalances = async () => {
        const tokenBalanceWETH = await assetTokenContractWETH.balanceOf(account);
        setAssetTokenBalanceWETH(formatUnits(tokenBalanceWETH, 18));
    }

    return {
        assetTokenContract,
        assetTokenContractAddress,
        fetchAssetTokenBalances
    }
};

export default useAssetToken;