import React from 'react'

import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { ThemeProvider, styled } from '@mui/material/styles'

import { theme } from '../../config/theme'
import './SubnavPortfolio2.css'

function SubnavPortfolio2(props) {
  const { subnavButtonTextSelected, subnavButtonText } = props
  const [isDeposit, setIsDeposit] = useState(1)

  const handleChange = (event, newState) => {
    if (newState != null) {
      setIsDeposit(newState)
    }
  }

  return (
    <div className='subnav-portfolio-4'>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          color='primary'
          value={isDeposit}
          exclusive
          onChange={handleChange}
        >
          <StyledToggleButton value={1}>
            {subnavButtonTextSelected}
          </StyledToggleButton>
          <StyledToggleButton value={0}>{subnavButtonText}</StyledToggleButton>
        </ToggleButtonGroup>
      </ThemeProvider>
      <div className='overlap-group1-16'>
        <div className='subnav-button-text-selected valign-text-middle'>
          {subnavButtonTextSelected}
        </div>
      </div>
      <div className='overlap-group-29'>
        <div className='subnav-button-text-10 valign-text-middle oxanium-medium-white-24px'>
          {subnavButtonText}
        </div>
      </div>
    </div>
  )
}

export default SubnavPortfolio2

const StyledToggleButton = styled(ToggleButton)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 18,
  borderRadius: 5,
})
