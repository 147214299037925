import React from "react";
import NavMenuBalance from "../NavMenuBalance";
import "./NavBalanceContainer.css";

function NavBalanceContainer() {
  return (
    <div className="nav-balance-container">
      {/* <img className="experimental-moon" src="/img/experimental---moon@2x.svg" /> */}
      <img 
        className="idea" 
        src="/img/bulb.svg" 
        onClick={() => window.open('https://roadmap.fluidnft.org/')}/>
      <NavMenuBalance />
    </div>
  );
}

export default NavBalanceContainer;
