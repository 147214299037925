import React from "react";
import "./RiskConfiguration2.css";

function RiskConfiguration2(props) {
  const {
    liquidationPriceTitle,
    collateralizationRatioTitle,
    liquidationPriceAmount,
    collateralizationRatioAmount,
    riskMin,
    riskMax,
  } = props;

  return (
    <div className="risk-configuration-3">
      <div className="title-container-3 oxanium-medium-star-dust-20px">
        <div className="liquidation-price-title-3 valign-text-middle">{liquidationPriceTitle}</div>
        <div className="collateralization-ratio-title-3 valign-text-middle">{collateralizationRatioTitle}</div>
      </div>
      <div className="amount-container-3 oxanium-medium-white-24px">
        <div className="liquidation-price-amount-3 valign-text-middle">{liquidationPriceAmount}</div>
        <div className="collateralization-ratio-amount-3 valign-text-middle">{collateralizationRatioAmount}</div>
      </div>
      <div className="risk-slider-container-6">
        <img className="risk-slider-container-7" src="/img/risk-slider-container-1@1x.svg" />
        <div className="risk-slider-toggle-3"></div>
      </div>
      <div className="risk-m-container-3 oxanium-medium-star-dust-20px">
        <div className="risk-min-3 valign-text-middle">{riskMin}</div>
        <div className="risk-max-3 valign-text-middle">{riskMax}</div>
      </div>
    </div>
  );
}

export default RiskConfiguration2;
