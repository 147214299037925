import React, { useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material/styles'
import Slider from '@mui/material/Slider'
import debug from 'debug'

import { theme } from '../../config/theme'
import { useAppContext } from '../../AppContext'
import { useToken } from '../../hooks/useToken'
import './DefaultRiskConfiguration.css'

const logger = debug('fluidnft:DefaultRiskConfiguration')

function DefaultRiskConfiguration(props) {
  const { className, getSliderValue, isRepay } = props
  const {
    minSliderValue,
    borrowDefault,
    inputValue,
    setInputValue,
    ethUsdPrice,
  } = useAppContext()
  const { fetchEthUsdPrice } = useToken()

  let maxValue = maxSliderValue ? maxSliderValue : 100
  let minValue = minSliderValue ? minSliderValue : 0

  logger('RUNNING DEFAULT')

  const [_crValue, _setCrValue] = useState('--')
  const [_liquidationValue, _setLiquidationValue] = useState('--')

  // Oracle <- TODO: refactor and manage state
  let ethUsd = ethUsdPrice

  // TODO: connect with protocol
  let liquidationFeePercentage = 0.01

  // State management
  const [sliderValue, setSliderValue] = useState(0)
  // const [inputValue, setInputValue] = useState(0);
  const [liquidationFee, setLiquidationFee] = useState(0)
  const [minCr, setMinCr] = useState(0)
  const [minRepayment, setMinRepayment] = useState(0)
  const [maxSliderValue, setMaxSliderValue] = useState(0)
  const [crValue, setCrValue] = useState('--')
  const [liquidationValue, setLiquidationValue] = useState('--')

  // useEffect(() => {
  //   if (crValue != _crValue) {
  //     _setCrValue(crValue.toFixed(2));
  //   }
  //   if (liquidationValue != _liquidationValue) {
  //     _setLiquidationValue(liquidationValue.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3}));
  //   }
  // }, [crValue, liquidationValue]);

  useEffect(() => {
    if (borrowDefault && borrowDefault.borrowAmount) {
      let liquidationFee_ =
        borrowDefault.borrowAmount * liquidationFeePercentage

      // Min / Max values
      let minCr_ = 1.5

      // Initial Values
      // let initialSliderValue = 100 * (borrowDefault.borrowAmount + liquidationFee_) * minCr_ / borrowDefault.floorPrice;
      // let initialCrValue = borrowDefault.floorPrice / borrowDefault.borrowAmount * 100;
      let initialSliderValue = isRepay ? 33 : 66
      setSliderValue(initialSliderValue)
      setLiquidationFee(liquidationFee_)
      setMinCr(minCr_)
      setMaxSliderValue(
        (100 * (borrowDefault.borrowAmount + liquidationFee_) * minCr_) /
          borrowDefault.floorPrice
      )
    }
  }, [borrowDefault])

  // Update State
  useEffect(() => {
    const calcCrValue =
      (100 * borrowDefault.floorPrice) /
      (borrowDefault.borrowAmount + liquidationFee - inputValue)
    setCrValue(calcCrValue.toFixed(2))
    const calcLiquidationValue =
      ((100 * borrowDefault.floorPrice) / calcCrValue) * minCr
    setLiquidationValue(
      calcLiquidationValue.toLocaleString('en', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3,
      })
    )
  }, [inputValue, sliderValue])

  const getSliderAndInputValue = (event) => {
    getSliderValue(event)
    const value = Number(event.target.value)
    setSliderValue(value)
    const calcInputValue =
      borrowDefault.borrowAmount +
      liquidationFee -
      ((borrowDefault.floorPrice / minCr) * (100 - value)) / 100
    setInputValue(calcInputValue)
  }

  useEffect(() => {
    const calcCrValue =
      (100 * borrowDefault.floorPrice) /
      (borrowDefault.borrowAmount + liquidationFee - inputValue)
    setCrValue(calcCrValue.toFixed(2))
    const calcLiquidationValue =
      ((100 * borrowDefault.floorPrice) / calcCrValue) * minCr
    setLiquidationValue(
      calcLiquidationValue.toLocaleString('en', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3,
      })
    )
  }, [inputValue, sliderValue])

  return (
    <div className={`risk-configuration ${className || ''}`}>
      <div className='title-container oxanium-medium-granite-gray-15px'>
        <div className='liquidation-price-title valign-text-middle'>
          Liquidation Price
        </div>
        <div className='collateralization-ratio-title valign-text-middle'>
          Collateralization Ratio
        </div>
      </div>
      <div className='amount-container oxanium-medium-black-18px'>
        <div className='liquidation-price-amount valign-text-middle1'>
          <div className='liquidation-price-amount2'>{liquidationValue}</div>
          <img className='weth-logo-31' src='/img/weth-logo-3@2x.svg' />
        </div>
        <div className='collateralization-ratio-amount valign-text-middle'>
          {crValue}%
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Slider
          aria-label='Temperature'
          defaultValue={75}
          color='primary'
          value={sliderValue}
          onChange={getSliderAndInputValue}
          max={maxValue}
          min={minValue}
        />
      </ThemeProvider>
      <div className='risk-m-container oxanium-medium-granite-gray-15px'>
        <div className='risk-min valign-text-middle'>
          {isRepay ? 'Increase Risk' : 'Decrease Risk'}
        </div>
        <div className='risk-max valign-text-middle'>
          {isRepay ? 'Decrease Risk' : 'Increase Risk'}
        </div>
      </div>
    </div>
  )
}

export default DefaultRiskConfiguration
