import { useWeb3React } from '@web3-react/core'
import { formatUnits, parseUnits } from '@ethersproject/units'
import debug from 'debug'

import { useContract } from './useContract'
import useIsValidNetwork from './useIsValidNetwork'
import { useAppContext } from '../AppContext'
import CollateralManagerData from '../artifacts/contracts/CollateralManager/CollateralManager.json'
import LendingPoolData from '../artifacts/contracts/LendingPool/LendingPool.json'
import NFTPriceConsumerData from '../artifacts/contracts/NFTPriceConsumer/NFTPriceConsumer.json'

const logger = debug('fluidnft:useCollateralManager')

export const useCollateralManager = () => {
  const { account } = useWeb3React()
  const { isValidNetwork } = useIsValidNetwork()
  const collateralManagerContractAddress =
    process.env.REACT_APP_COLLATERAL_MANAGER_CONTRACT_ADDRESS
  const collateralManagerABI = CollateralManagerData['abi']
  const collateralManagerContract = useContract(
    collateralManagerContractAddress,
    collateralManagerABI
  )

  // NFT contract data (c&p from hooks/useNFT.js)
  const nftContractAddressPUNK = process.env.REACT_APP_NFT_PUNK_CONTRACT_ADDRESS
  const nftContractAddressBAYC = process.env.REACT_APP_NFT_BAYC_CONTRACT_ADDRESS
  const nftContractAddress = {
    PUNK: nftContractAddressPUNK,
    BAYC: nftContractAddressBAYC,
  }
  const nftContractAddressReverseLookup = {}
  nftContractAddressReverseLookup[nftContractAddressPUNK] = 'PUNK'
  nftContractAddressReverseLookup[nftContractAddressBAYC] = 'BAYC'
  nftContractAddressReverseLookup[
    '0x0d0167a823c6619d430b1a96ad85b888bcf97c37'
  ] = 'XPUNK'
  nftContractAddressReverseLookup[
    '0x22c08c358f62f35b742d023bf2faf67e30e5376e'
  ] = '0xAPE'
  nftContractAddressReverseLookup[
    '0x0D0167A823C6619D430B1a96aD85B888bcF97C37'
  ] = 'XPUNK'
  nftContractAddressReverseLookup[
    '0X22C08C358F62F35B742D023BF2FAF67E30E5376E'
  ] = '0xAPE'

  // NFT Price Consumer
  const nftPriceConsumerAddress =
    process.env.REACT_APP_NFT_PRICE_CONSUMER_CONTRACT_ADDRESS
  const nftPriceConsumerABI = NFTPriceConsumerData['abi']
  const nftPriceConsumerContract = useContract(
    nftPriceConsumerAddress,
    nftPriceConsumerABI
  )

  // LendingPool Contract
  const lendingPoolContractAddress =
    process.env.REACT_APP_LENDING_POOL_CONTRACT_ADDRESS
  const lendingPoolABI = LendingPoolData['abi']
  const lendingPoolContract = useContract(
    lendingPoolContractAddress,
    lendingPoolABI
  )

  const {
    setWhitelistNFT,
    whitelistNFT,
    setAprPUNK,
    setAprBAYC,
    aprPUNK,
    aprBAYC,
    setBorrowAPR,
    setUserBorrows,
    setBorrowDefaults,
    setBorrowDefault,
    setBorrowBalance,
  } = useAppContext()

  const fetchWhitelistNFT = async () => {
    if (account) {
      const whitelist = await collateralManagerContract.getWhitelist()
      setWhitelistNFT(whitelist)
    }
  }

  const fetchAPR = async () => {
    if (account) {
      const aprPUNK = await collateralManagerContract.getInterestRate(
        nftContractAddress['PUNK']
      )
      const aprBAYC = await collateralManagerContract.getInterestRate(
        nftContractAddress['BAYC']
      )
      setAprPUNK(formatUnits(aprPUNK, 0))
      setAprBAYC(formatUnits(aprBAYC, 0))
    }
  }

  const fetchBorrowAPR = async (nftSymbol) => {
    if (account) {
      if (nftSymbol == 'PUNK') {
        setBorrowAPR(aprPUNK)
      } else if (nftSymbol == 'BAYC') {
        setBorrowAPR(aprBAYC)
      }
    }
  }

  const formatBorrow = async (_borrow, _id) => {
    const borrow = {}
    borrow['erc20Token'] = _borrow['erc20Token']
    borrow['borrowAmount'] = parseFloat(
      formatUnits(_borrow['borrowAmount'].toString(), 18)
    )
    borrow['borrower'] = _borrow['borrower']
    // borrow['interestRate'] = parseFloat(
    //   formatUnits(_borrow['interestRate'].toString(), 27)
    // )
    borrow['liquidationPrice'] = parseFloat(
      formatUnits(_borrow['liquidationPrice'].toString(), 18)
    )
    borrow['nftContractAddress'] = _borrow['collateral'][0]
    borrow['nftSymbol'] =
      nftContractAddressReverseLookup[borrow['nftContractAddress']]
    borrow['nftTokenId'] = _borrow['collateral'][1].toNumber()
    borrow['status'] = _borrow['status']
    borrow['currentBid'] = parseFloat(
      formatUnits(_borrow['auction']['bid'], 18)
    )
    borrow['currentBidder'] = _borrow['auction']['bidder']
    borrow['auctionStart'] = _borrow['auction']['timestamp']
    borrow['id'] = _id
    return borrow
  }

  const fetchUserBorrows = async () => {
    if (account) {
      const userBorrowIds = await collateralManagerContract.getUserBorrowIds(
        account
      )
      const userBorrows = {}
      for (var i = 0; i < userBorrowIds.length; i++) {
        let borrowId = userBorrowIds[i].toString()
        let borrow = await collateralManagerContract.getBorrow(borrowId)

        // Exclude null addresses. TODO: check SC for an alternative to this patch.
        if (borrow[1] != '0x0000000000000000000000000000000000000000') {
          // Exclude non-active borrows
          if (borrow['status'] == 0) {
            let borrowFormatted = await formatBorrow(borrow, i)

            // Add floor price
            let floorPrice = formatUnits(
              (
                await nftPriceConsumerContract.getFloorPrice(
                  borrowFormatted['nftContractAddress']
                )
              ).toString(),
              18
            )
            borrowFormatted['floorPrice'] = floorPrice

            // Add in collRatio
            let borrowCollRatio =
              (100 * floorPrice) / borrowFormatted['borrowAmount']
            borrowFormatted['collRatio'] = borrowCollRatio
            userBorrows[borrowId] = borrowFormatted

            // Get interest Rate
          }
        }
      }
      setUserBorrows(userBorrows)
    }
  }

  const fetchDefaultedBorrows = async () => {
    if (account) {
      logger('runnin33g fetchDefaultedBorrows')
      const lastBorrowId = await collateralManagerContract.getLastBorrowId()
      logger('lastBorrowId>>>>>>>', formatUnits(lastBorrowId, 0))
      const borrowDefaults = []
      for (let i = 0; i <= lastBorrowId; i++) {
        let borrow = await collateralManagerContract.getBorrow(i)

        logger('BORROW', borrow)

        // Check if SC borrow is active and exclude null address (FE patch)
        if (
          borrow['status'] in [0, 1] &&
          borrow['borrower'] != '0x0000000000000000000000000000000000000000'
        ) {
          let borrowFormatted = await formatBorrow(borrow, i)
          let floorPrice = parseFloat(
            formatUnits(
              (
                await nftPriceConsumerContract.getFloorPrice(
                  borrowFormatted['nftContractAddress']
                )
              ).toString(),
              18
            )
          )

          // if undercollateralized
          if (floorPrice <= borrowFormatted['liquidationPrice']) {
            borrowFormatted['collRatio'] =
              (100 * floorPrice) / borrowFormatted['borrowAmount']
            borrowFormatted['floorPrice'] = floorPrice
            borrowDefaults.push(borrowFormatted)
          }
        }
      }

      logger('setBorrowDefaults', borrowDefaults)
      setBorrowDefaults(borrowDefaults)
    }
  }

  const fetchDefaultedBorrow = async (nftContractAddress, nftTokenId) => {
    if (account) {
      let borrowId = await collateralManagerContract.getBorrowId(
        nftContractAddress,
        nftTokenId
      )
      let borrow = await collateralManagerContract.getBorrow(borrowId)
      borrow = await formatBorrow(borrow, borrowId)

      let floorPrice = parseFloat(
        formatUnits(
          (
            await nftPriceConsumerContract.getFloorPrice(
              borrow['nftContractAddress']
            )
          ).toString(),
          18
        )
      )
      borrow['collRatio'] = (100 * floorPrice) / borrow['borrowAmount']
      borrow['floorPrice'] = floorPrice

      setBorrowDefault(borrow)
    }
  }

  const fetchBorrowBalance = async (tokenAddress, tokenId) => {
    if (account) {
      logger('tokenAddress, tokenId', tokenAddress, tokenId)
      const borrowBalance = formatUnits(
        (
          await collateralManagerContract.getBorrowBalance(
            tokenAddress,
            tokenId
          )
        ).toString(),
        18
      )
      logger('borrowBalance', borrowBalance)
      setBorrowBalance(Number(borrowBalance))
    }
  }

  return {
    fetchWhitelistNFT,
    whitelistNFT,
    fetchAPR,
    fetchBorrowAPR,
    collateralManagerContractAddress,
    fetchUserBorrows,
    fetchDefaultedBorrows,
    // fetchInterestRate,
    fetchBorrowBalance,
    fetchDefaultedBorrow,
    // fetchNftInterestRate,
  }
}

export default useCollateralManager
