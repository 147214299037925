import React from "react";
import "./RowItem8.css";

function RowItem8(props) {
  const { className, nftFloor } = props;

  return (
    <div className={`row-item-10-1 ${className || ""}`}>
      <div className="text valign-text-middle oxanium-normal-black-18px">{nftFloor ? nftFloor.toFixed(3) : "--"}</div>
      <img className="weth-icon-37" src="/img/weth-icon-59@2x.svg" />
    </div>
  );
}

export default RowItem8;
