import React, { useState, useEffect } from "react";
import "./SearchPool.css";
import { Autocomplete, TextField } from '@mui/material';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { styled } from '@mui/material/styles';
import { useAppContext } from "../../AppContext";
import { useLendingPool } from "../../hooks/useLendingPool";
import { useWeb3React } from "@web3-react/core";

function SearchPool() {
  const { 
    setSearchSelection,
    pools,
    poolSymbols,
    poolCollectionNames,
    poolCollectionAddresses,
    isLendingPoolDeposit
  } = useAppContext();
  const { account } = useWeb3React()
  // const { fetchPools } = usePool();
  const { fetchPools } = useLendingPool();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchPools();
  }, []);

  const filterPools = (pools) => {
    let filtered = [];
    if (isLendingPoolDeposit || !account) {
      filtered = pools;
    } else {
      pools.map((pool, index) => {
        if (pool.userBalance > 0) {
          filtered.push(pool);
        }
      })
    }
    return filtered;
  }

  useEffect(() => {
    if (pools.length !== 0) {
      const optionsData = [];
      const seen = new Set([]);
      filterPools(pools).map((pool, index) => {
        const poolName = poolSymbols[index];
        const collectionName = poolCollectionNames[index];
        const collectionAddress = poolCollectionAddresses[index]
        if (!seen.has(poolName)) {
          optionsData.push({title: poolName, type: "POOL", collectionAddress: collectionAddress})
          seen.add(poolName)
        }  
        if (!seen.has(collectionName)) {
          optionsData.push({title: collectionName, type: "COLLECTION", collectionAddress: collectionAddress})
          seen.add(collectionName)
        }  
      });
      setOptions(optionsData);
    };
  }, [pools, poolSymbols, poolCollectionNames, isLendingPoolDeposit, account]);

  return (
    <Autocomplete
      id="lending-pools"
      options={options.sort((a, b) => -b.type.localeCompare(a.type) || -b.title.localeCompare(a.title))}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.title}
      onChange={(event, value) => setSearchSelection(value)}
      sx={{ width: 748, height: 44 }}
      size="small"
      renderInput={(params) => {
        return (
          <StyledTextField
            {...params}
            placeholder="Filter by lending pool or collection"
            fullWidth
            sx={{ width: 748, height: 44, borderRadius: 30, fontFamily: "Oxanium" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        );
      }}
    /> 
  );
}

export default SearchPool;


const StyledTextField = styled(TextField)({
  textTransform: 'none',
  fontFamily: "Oxanium",
  fontSize: 40,
  borderRadius: 30,
});