import React from "react";
import "./RowItem4.css";

function RowItem4(props) {
  const { liquidationPrice } = props;
  return (
    <div className="row-item-4">
      <div className="header-item-text-10 valign-text-middle oxanium-normal-black-18px">{liquidationPrice}</div>
      <img className="weth-icon-23" src="/img/weth-icon-33@2x.svg" />
    </div>
  );
}

export default RowItem4;
