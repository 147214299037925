import React, { useEffect, useState } from 'react'

import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Countdown from 'react-countdown'
import Popup from 'reactjs-popup'
import debug from 'debug'

import Nav from '../Nav'
import SubnavLiquidation from '../SubnavLiquidation'
import ModuloLiquidate from '../ModuloLiquidate'
import useLendingPool from '../../hooks/useLendingPool'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import { useAppContext } from '../../AppContext'
import { useToken } from '../../hooks/useToken'
import { theme } from '../../config/theme'
import './Liquidate.css'

const logger = debug('fluidnft:Liquidate')

function Liquidate(props) {
  const {
    sectionTitle,
    liquidationItemTitle,
    liquidationItemProject,
    subnavPoolProps,
    nftName,
    nftProjectName,
    nftImage,
  } = props
  const { borrowDefault, assetTokenBalanceWETH, ethUsdPrice } = useAppContext()
  const { fetchDefaultedBorrow } = useCollateralManager()
  const { bid, liquidate } = useLendingPool()
  const { account } = useWeb3React()
  const { tokenAddress, tokenId } = useParams()
  const { fetchEthUsdPrice } = useToken()

  useEffect(() => {
    fetchDefaultedBorrow(tokenAddress, tokenId)
  }, [account])

  useEffect(() => {
    logger('borrowDefault', borrowDefault)
  }, [borrowDefault])

  const [maxInputValue, setMaxInputValue] = useState(0)
  useEffect(() => {
    if (assetTokenBalanceWETH) {
      setMaxInputValue(assetTokenBalanceWETH)
    }
  }, [assetTokenBalanceWETH])

  const [minInputValue, setMinInputValue] = useState(0)
  useEffect(() => {
    if (assetTokenBalanceWETH) {
      setMinInputValue(assetTokenBalanceWETH)
    }
  }, [assetTokenBalanceWETH])

  // Oracle <- TODO: refactor and manage state
  let ethUsd = ethUsdPrice

  // Min / Max values
  const minCr = 1.5
  let minBid = borrowDefault.borrowAmount

  // Initial Values
  const initialInputValue = ''
  const initialInputValueUsd = initialInputValue
    ? initialInputValue * ethUsd
    : 0

  // State management
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [inputValueUsd, setInputValueUsd] = useState(initialInputValueUsd)
  const [countdown, setCountdown] = useState(1) // not zero

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/snipe')
  }

  const handleBidSubmit = () => {
    logger('handleBidSubmit', tokenAddress, tokenId, inputValue)
    bid(tokenAddress, tokenId, inputValue, 'WETH')
  }

  const handleLiquidateSubmit = () => {
    logger('handleLiquidateSubmit', tokenAddress, borrowDefault.id, 'WETH')
    liquidate(tokenAddress, 'WETH', borrowDefault.id)
  }

  const auctionComponent = ({
    hours,
    minutes,
    seconds,
    completed,
    countdown = true,
  }) => {
    logger('auctionComponent borrowDefault', borrowDefault)
    return (
      <>
        <div className='liquidation-auction-info'>
          <div className='bid-container'>
            <div className='bid-title valign-text-middle oxanium-medium-granite-gray-15px-2'>
              {completed
                ? 'Winning bid'
                : countdown
                ? 'Current bid'
                : 'Minimum first bid'}
            </div>
            {countdown ? (
              <>
                <div className='bid-amount valign-text-middle oxanium-medium-black-30px-2'>
                  {borrowDefault && borrowDefault.currentBid
                    ? `${borrowDefault.currentBid.toLocaleString('en', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 3,
                      })} WETH`
                    : '--'}
                </div>
                <div className='bid-account valign-text-middle oxanium-medium-granite-gray-15px-2'>
                  {borrowDefault && borrowDefault.currentBidder
                    ? `${borrowDefault.currentBidder.substring(
                        0,
                        6
                      )}...${borrowDefault.currentBidder.slice(-4)}`
                    : '--'}
                </div>
              </>
            ) : (
              <>
                <div className='bid-amount valign-text-middle oxanium-medium-black-30px-2'>
                  {borrowDefault && borrowDefault.borrowAmount
                    ? `${borrowDefault.borrowAmount.toLocaleString('en', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 3,
                      })} WETH`
                    : '--'}
                </div>
                <div className='bid-account valign-text-middle oxanium-medium-granite-gray-15px-2'>
                  {borrowDefault && borrowDefault.borrower
                    ? `${borrowDefault.borrower.substring(
                        0,
                        6
                      )}...${borrowDefault.borrower.slice(-4)}`
                    : '--'}
                </div>
              </>
            )}
            {/* <div className="bid-account valign-text-middle oxanium-medium-granite-gray-15px-2">{countdown && borrowDefault && borrowDefault.currentBidder ? `${borrowDefault.currentBidder.substring(0,6)}...${borrowDefault.currentBidder.slice(-4)}`: `${borrowDefault.borrower.substring(0,6)}...${borrowDefault.borrower.slice(-4)}`}</div>  */}
          </div>
          <div className='time-container'>
            <div className='time-title valign-text-middle oxanium-medium-granite-gray-15px-2'>
              {countdown ? 'Auction ends in' : 'Auction will end in'}
            </div>
            <div className='time-amounts oxanium-medium-black-30px-2'>
              <div className='hours valign-text-middle'>{hours}</div>
              <div className='minutes valign-text-middle'>{minutes}</div>
              <div className='seconds valign-text-middle'>{seconds}</div>
            </div>
            <div className='time-subtitle'>
              <div className='hours-1 valign-text-middle oxanium-medium-granite-gray-15px-2'>
                hours
              </div>
              <div className='minutes-1 valign-text-middle oxanium-medium-granite-gray-15px-2'>
                minutes
              </div>
              <div className='seconds-1 valign-text-middle'>seconds</div>
            </div>
          </div>
        </div>
        <ThemeProvider theme={theme}>
          {completed ? (
            account == borrowDefault.borrower ||
            account == borrowDefault.currentBidder ? (
              <div className='submit-bid-button'>
                <StyledButton
                  variant='contained'
                  className='submit-bid-button'
                  onClick={handleLiquidateSubmit}
                >
                  Liquidate borrow
                </StyledButton>
              </div>
            ) : (
              <div className='submit-bid-button'>
                <StyledButton
                  disabled
                  variant='contained'
                  className='submit-bid-button'
                >
                  Auction ended
                </StyledButton>
              </div>
            )
          ) : inputValue ? (
            <>
              {minBid < inputValue && inputValue <= maxInputValue ? (
                <Popup
                  trigger={
                    <div className='submit-bid-button'>
                      <StyledButton
                        variant='contained'
                        className='submit-bid-button'
                        onClick={handleBidSubmit}
                      >
                        Bid{' '}
                        {inputValue.toLocaleString('en', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 4,
                        })}{' '}
                        WETH
                      </StyledButton>
                    </div>
                  }
                  modal
                  nested
                >
                  <ModuloLiquidate
                    moduloTitle='Complete auction bid'
                    subTitle='Bid'
                    bidAmount={inputValue}
                    bidAmountUsd={inputValueUsd}
                    nftName={nftName}
                    nftProjectName={nftProjectName}
                    nftImage={nftImage}
                  />
                </Popup>
              ) : (
                <div className='submit-bid-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-bid-button'
                  >
                    {inputValue > maxInputValue
                      ? 'Insufficient funds'
                      : `Select amount > ${(countdown
                          ? borrowDefault.currentBid
                          : minBid
                        ).toLocaleString('en', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 3,
                        })}`}
                  </StyledButton>
                </div>
              )}
            </>
          ) : (
            <div className='submit-bid-button'>
              <StyledButton
                disabled
                variant='contained'
                className='submit-bid-button'
              >
                Select amount
              </StyledButton>
            </div>
          )}
        </ThemeProvider>
      </>
    )
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return auctionComponent({ hours, minutes, seconds, completed })
  }

  return (
    <div className='container-center-horizontal'>
      <div className='liquidate screen'>
        <Nav />
        <div className='flex-row-11'>
          <div className='section-container-4'>
            <a onClick={handleClick}>
              <img className='back-arrow-4' src='/img/back-arrow-4@2x.svg' />
            </a>
            <div className='section-title-9 valign-text-middle oxanium-medium-black-27px'>{`Liquidate borrow against ${nftName}`}</div>
          </div>
          <SubnavLiquidation
            subnavButtonTextSelected={subnavPoolProps.subnavButtonTextSelected}
            subnavButtonText={subnavPoolProps.subnavButtonText}
            className={subnavPoolProps.className}
          />
        </div>
        <img className='section-line-7' src='/img/section-line-6@1x.svg' />
        <div className='div-container-4'>
          <div className='nft-div-1'>
            <img className='nft-icon-5' src={nftImage} />
            <div className='nft-data'>
              <div className='liquidation-item'>
                <div className='liquidation-item-title valign-text-middle oxanium-medium-black-30px'>
                  {nftName}
                </div>
                <div className='liquidation-item-project valign-text-middle oxanium-medium-gravel-18px'>
                  {nftProjectName}
                </div>
              </div>
              <div className='liquidation-item-floor-price'>
                <div className='overlap-group-26'>
                  <img
                    className='weth-icon-33'
                    src='/img/weth-icon-64@2x.svg'
                  />
                  <div className='liquidation-floor-price-weth valign-text-middle oxanium-medium-black-30px'>
                    {borrowDefault && borrowDefault.floorPrice
                      ? borrowDefault.floorPrice.toLocaleString('en', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 3,
                        })
                      : '--'}
                  </div>
                  <div className='liquidation valign-text-middle oxanium-medium-granite-gray-18px'>
                    Floor price
                  </div>
                </div>
                <div className='liquidation-1 valign-text-middle oxanium-medium-star-dust-15px'>
                  {borrowDefault && borrowDefault.floorPrice
                    ? `$${(borrowDefault.floorPrice * ethUsd).toLocaleString(
                        'en',
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}`
                    : '--'}
                </div>
              </div>
              <div className='liquidation-item-cr'>
                <div className='liquidation-cr-min valign-text-middle oxanium-medium-star-dust-15px'>
                  {account ? `Min: ${150}%` : '--'}
                </div>
                <div className='liquidation-cr-amount valign-text-middle oxanium-medium-black-30px'>
                  {borrowDefault.collRatio
                    ? `${borrowDefault.collRatio.toFixed(1)}%`
                    : '--%'}
                </div>
                <div className='liquidation valign-text-middle oxanium-medium-granite-gray-18px'>
                  Collateralization Ratio
                </div>
              </div>
              <div className='liquidation-item-owner'>
                <div className='liquidation-1 valign-text-middle oxanium-medium-granite-gray-18px'>
                  Current owner
                </div>
                <div className='liquidation-owner-account valign-text-middle oxanium-medium-black-30px'>
                  {borrowDefault.borrower
                    ? `${borrowDefault.borrower.substring(
                        0,
                        6
                      )}...${borrowDefault.borrower.slice(-4)}`
                    : '--'}
                </div>
              </div>
            </div>
          </div>
          <img
            className='section-divider-4'
            src='/img/section-divider-5@2x.svg'
          />
          <div className='bid-div'>
            <div className='bid-weth valign-text-middle oxanium-medium-black-22px'>
              Bid WETH
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {maxInputValue && !isNaN(maxInputValue)
                        ? parseFloat(maxInputValue).toLocaleString('en', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 4,
                          })
                        : '--'}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={maxInputValue}
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <>
              {borrowDefault && borrowDefault.auctionStart ? (
                <Countdown
                  date={borrowDefault.auctionStart * 1000 + 1000 * 60 * 60 * 24} // Date.now() + 3000
                  renderer={renderer}
                />
              ) : (
                auctionComponent({
                  hours: 24,
                  minutes: 0,
                  seconds: 0,
                  countdown: false,
                })
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Liquidate

const moduloLiquidateApprove = {
  approveText: 'Approve WETH',
  approveDescription:
    'To bid you must approve WETH, which requires a one-time gas fee.',
}

const moduloLiquidateConfirm = {
  confirmText: 'Confirm 3.0 WETH bid',
  confirmDescription:
    'Accept the signature request in your wallet and wait for your bid to process.',
}

const moduloLiquidateSuccess = {
  confirmText: 'Success!',
  confirmDescription:
    'Your WETH will be locked until you are out-bid or the auction ends; when the NFT will be redeemed.',
  className: 'modulo-2',
}

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})
