import { useContract } from "./useContract";
import useIsValidNetwork from "./useIsValidNetwork";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "../AppContext";
import { formatUnits } from "@ethersproject/units";
import DebtTokenData from "../artifacts/contracts/DebtToken/DebtToken.json";


export const useDebtToken = () => {
    const { account } = useWeb3React();
    const { isValidNetwork } = useIsValidNetwork();
    const { setDebtTokenBalanceWETH, debtTokenBalanceWETH } = useAppContext();

    // DebtTokens
    const debtTokenContractAddressWETH = process.env.REACT_APP_DEBT_TOKEN_WETH_CONTRACT_ADDRESS;
    
    const debtTokenABI = DebtTokenData["abi"];
    const debtTokenContractWETH = useContract(debtTokenContractAddressWETH, debtTokenABI);
    const debtTokenContract = {
        "WETH": debtTokenContractWETH
    }

    const fetchDebtTokenBalance = async (ccy) => {
        
        switch(ccy) {
            case "WETH":
                const debtTokenBalanceWETH = await debtTokenContractWETH.balanceOf(account);
                setDebtTokenBalanceWETH(formatUnits(debtTokenBalanceWETH, 18));
        }
    };

    return {
        debtTokenBalanceWETH,
        fetchDebtTokenBalance,
        debtTokenContract
    }
};

export default useDebtToken;