import React from "react";
import "./BorrowItemBalance.css";

function BorrowItemBalance(props) {
  const { borrowBalance, borrowBalanceUsd } = props;

  return (
    <div className="borrow-item-balance">
      <div className="borrow-item-title valign-text-middle oxanium-medium-granite-gray-18px">Borrow balance</div>
      <div className="overlap-group1-7">
        <div className="borrow-balance-usd valign-text-middle oxanium-medium-star-dust-17-8px">${borrowBalanceUsd.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
        <div className="weth-container-20">
          <img className="weth-logo-4" src="/img/weth-logo-2@2x.svg" />
          <div className="borrow-balance-weth valign-text-middle oxanium-medium-black-30px">{borrowBalance.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3})}</div>
        </div>
      </div>
    </div>
  );
}

export default BorrowItemBalance;
