import React from "react";
import "./RowItem22.css";

function RowItem22(props) {
  const { borrowedAmount, className } = props;

  return (
    <div className={`row-item-7-1 ${className || ""}`}>
      <div className="borrowed-amount valign-text-middle oxanium-normal-black-18px">{borrowedAmount}</div>
      {
        (borrowedAmount !== "--") ?
          <img className="weth-icon-29" src="/img/weth-icon-43@2x.svg" /> : 
          <></>
      }
      
    </div>
  );
}

export default RowItem22;
