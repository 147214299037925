import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import Nav from '../Nav'
import Dashboard from '../Dashboard'
import SubnavPortfolio4 from '../SubnavPortfolio4'
import BorrowItemDiv from '../BorrowItemDiv'
import BorrowItemBalance from '../BorrowItemBalance'
import BorrowItemInterest from '../BorrowItemInterest'
import RiskConfiguration from '../RiskConfiguration'
import ModuloRepay from '../ModuloRepay'
import useLendingPool from '../../hooks/useLendingPool'
import useCollateralManager from '../../hooks/useCollateralManager'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import { useToken } from '../../hooks/useToken'
import { theme } from '../../config/theme'
import './Repay.css'

function Repay(props) {
  const {
    floorPriceTitle,
    dashboardProps,
    subnavPortfolio4Props,
    riskConfigurationProps,
    nftName,
  } = props
  const { tokenAddress, tokenId } = useParams()
  const {
    setNftTokenAddress,
    setNftTokenId,
    setNftCollectionName,
    nftCollectionName,
    setNftImage,
    nftImage,
    nft,
    floorPrice,
    interestRate,
    setLiquidationValue,
    setCrValue,
    crValue,
    liquidationValue,
    assetTokenBalanceWETH,
    userBorrows,
    borrowBalance,
    ethUsdPrice,
  } = useAppContext()
  const { account } = useWeb3React()
  const { fetchBorrowBalance, fetchUserBorrows } = useCollateralManager()
  const { fetchNft, formatNftData } = useNFT()
  const { repay } = useLendingPool()
  const { fetchEthUsdPrice } = useToken()
  let nftData = {}

  // Oracle data <- TODO: refactor and manage state
  const [ethUsd, setEthUsd] = useState(0)

  useEffect(() => {
    if (ethUsdPrice) {
      setEthUsd(ethUsdPrice)
    }
  }, [ethUsdPrice])

  // let floorPrice = 100;
  // let floorPriceUsd = floorPrice * ethUsd;

  // let formattedFloorPrice = floorPrice.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3});
  // let formattedFloorPriceUsd = floorPriceUsd.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  // <--- TO REMOVE

  useEffect(() => {
    fetchNft(tokenAddress, tokenId)
    fetchUserBorrows()
    fetchEthUsdPrice()
  }, [])

  useEffect(() => {
    if (Object.keys(nft).length !== 0) {
      nftData = formatNftData(nft)
      setNftTokenAddress(nftData.tokenAddress)
      setNftTokenId(nftData.tokenId)
      setNftCollectionName(nftData.collectionName)
      setNftImage(nftData.image)
    }
  }, [nft])

  useEffect(() => {
    if (account) {
      fetchBorrowBalance(tokenAddress, tokenId)
    }
  }, [account, userBorrows])

  // Wallet balance
  let walletBalance = assetTokenBalanceWETH

  // Existing borrow id / balance
  let borrowId
  // let borrowBalance = "--";
  // let borrowBalanceUsd = (borrowBalance * ethUsd).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  Object.keys(userBorrows).map((key, index) => {
    if (
      userBorrows[key].nftContractAddress.toUpperCase() ==
        tokenAddress.toUpperCase() &&
      userBorrows[key].nftTokenId == tokenId
    ) {
      borrowId = key
    }
  })

  useEffect(() => {
    Object.keys(userBorrows).map((key, index) => {
      if (
        userBorrows[key].nftContractAddress.toUpperCase() ==
          tokenAddress.toUpperCase() &&
        userBorrows[key].nftTokenId == tokenId
      ) {
        borrowId = key
      }
    })
  }, [userBorrows, account])

  // Min / Max values
  const minCr = 1.5
  const maxInputValue = borrowBalance
  // Initial Values
  const initialSliderValue = borrowBalance
    ? (100 * borrowBalance * minCr) / floorPrice
    : 75
  const initialCrValue = (minCr / initialSliderValue) * 100
  const initialInputValue = borrowBalance ? '' : floorPrice / initialCrValue
  const initialInputValueUsd = initialInputValue * ethUsd
  // const initialLiquidationValue = floorPriceUsd / initialCrValue;

  // State management
  const [sliderValue, setSliderValue] = useState(initialSliderValue)
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [inputValueUsd, setInputValueUsd] = useState(initialInputValueUsd)
  // const [crValue, setCrValue] = useState(initialCrValue);
  // const [liquidationValue, setLiquidationValue] = useState(initialLiquidationValue);

  // Update State
  useEffect(() => {
    let calcCrValue = ((minCr * 100) / initialSliderValue) * 100
    if (floorPrice && inputValue && borrowBalance) {
      calcCrValue = (100 * floorPrice) / (borrowBalance - inputValue)
    } else if (floorPrice && inputValue) {
      calcCrValue = (100 * floorPrice) / inputValue
    }

    const calcLiquidationValue = ((100 * floorPrice) / calcCrValue) * minCr
    setCrValue(calcCrValue)
    setLiquidationValue(calcLiquidationValue)
  }, [inputValue, sliderValue, floorPrice, borrowBalance])

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
    const calcSliderValue =
      100 - (100 * ((borrowBalance - value) * minCr)) / floorPrice
    setSliderValue(calcSliderValue)
  }

  const getSliderValue = (event) => {
    const value = Number(event.target.value)
    setSliderValue(value)
    const calcInputValue =
      borrowBalance - ((floorPrice / minCr) * (100 - value)) / 100
    setInputValue(calcInputValue)
    setInputValueUsd(calcInputValue * ethUsd)
  }

  const reloadImage = (e) => {
    e.target.src = nftImage
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/')
  }

  const handleRepaySubmit = () => {
    repay(borrowId, tokenAddress, String(inputValue), 'WETH')
  }

  const formatValue = (value, decimals) => {
    return Number(value).toFixed(decimals)
  }

  return (
    <div className='container-center-horizontal'>
      <div className='repay screen'>
        <Nav />
        <Dashboard
          dashboardAverageCr1Props={dashboardProps.dashboardAverageCr1Props}
          dashboardAverageCr2Props={dashboardProps.dashboardAverageCr2Props}
        />
        <div className='flex-row-4'>
          <div className='section-container-1'>
            <a onClick={handleClick}>
              <img className='back-arrow-1' src='/img/back-arrow@2x.svg' />
            </a>
            <div className='section-title-3 valign-text-middle oxanium-medium-black-27px'>{`Repay borrow against ${nftName}`}</div>
          </div>
          <SubnavPortfolio4 className={subnavPortfolio4Props.className} />
        </div>
        <img className='section-line-1' src='/img/section-line@1x.svg' />
        <div className='div-container'>
          <div className='nft-div'>
            <img
              className='nft-collection-icon-4'
              src={nftImage}
              onError={reloadImage}
            />
            <div className='nft-dashboard-1'>
              <BorrowItemDiv
                nftName={nftName}
                nftProjectName={nftCollectionName}
              />
              <div className='borrow-item-floor-price-1'>
                <div className='overlap-group2-8'>
                  <div className='floor-price-container'>
                    <img
                      className='weth-logo-9'
                      src='/img/weth-logo-8@2x.svg'
                    />
                    <div className='floor-price-weth-1 valign-text-middle oxanium-medium-black-30px'>
                      {floorPrice
                        ? floorPrice.toLocaleString('en', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          })
                        : '--'}
                    </div>
                  </div>
                  <div className='floor-price-title-1 valign-text-middle oxanium-medium-granite-gray-18px'>
                    {floorPriceTitle}
                  </div>
                </div>
                <div className='floor-price-usd-1 valign-text-middle oxanium-medium-star-dust-18px'>
                  {floorPrice && ethUsd
                    ? (floorPrice * ethUsd).toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : '--'}
                </div>
              </div>
              <BorrowItemBalance
                borrowBalance={borrowBalance}
                borrowBalanceUsd={ethUsd ? borrowBalance * ethUsd : '--'}
              />
              <BorrowItemInterest interestRate={interestRate} />
            </div>
          </div>
          <img className='section-divider' src='/img/section-divider@2x.svg' />
          <div className='borrow-div-repay'>
            <div className='input-title-1 valign-text-middle oxanium-medium-black-22px'>
              Repay WETH
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {isNaN(maxInputValue) || isNaN(walletBalance)
                        ? ''
                        : formatValue(
                            maxInputValue < walletBalance
                              ? maxInputValue
                              : walletBalance,
                            3
                          )}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={
                          maxInputValue < walletBalance
                            ? maxInputValue
                            : walletBalance
                        }
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <RiskConfiguration
              className={riskConfigurationProps.className}
              getSliderValue={getSliderValue}
              sliderValue={sliderValue}
              crValue={crValue}
              liquidationValue={liquidationValue}
              maxSliderValue={100}
              isRepay={true}
            />
            <ThemeProvider theme={theme}>
              {inputValue ? (
                <>
                  {0 < inputValue && inputValue <= maxInputValue + 10 ? ( // TODO: remove the +1 with something more calculated for paying accrued interest
                    <Popup
                      trigger={
                        <div className='submit-repay-button'>
                          <ThemeProvider theme={theme}>
                            <StyledButton
                              variant='contained'
                              className='submit-repay-button'
                              onClick={handleRepaySubmit}
                            >
                              Repay{' '}
                              {inputValue.toLocaleString('en', {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 4,
                              })}{' '}
                              WETH
                            </StyledButton>
                          </ThemeProvider>
                        </div>
                      }
                      modal
                      nested
                    >
                      <ModuloRepay
                        moduloTitle='Complete repayment'
                        subTitle='Repay'
                        repayAmount={inputValue * 1.00001} // TODO: find a better way of paying off accrued interest
                        repayAmountUsd={inputValueUsd}
                        nftName={nftName}
                        nftProjectName={nftCollectionName}
                        nftImage={nftImage}
                      />
                    </Popup>
                  ) : (
                    <div className='submit-borrow-button'>
                      <StyledButton
                        disabled
                        variant='contained'
                        className='submit-borrow-button'
                      >
                        {inputValue > borrowBalance
                          ? 'Select amount < balance'
                          : 'Select amount > 0'}
                      </StyledButton>
                    </div>
                  )}
                </>
              ) : (
                <div className='submit-borrow-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-borrow-button'
                  >
                    Select amount
                  </StyledButton>
                </div>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Repay

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})
