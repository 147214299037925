import { useWeb3React } from '@web3-react/core'
import { useCallback, useState, useEffect } from 'react'
import debug from 'debug'

import { minCollRatio } from '../core/constants'
import { useAppContext } from '../AppContext'
import { useSelectNft } from './state/useSelectNft'
import { useNFT } from './useNFT'
import useInterestRate from './useInterestRate'

const logger = debug('fluidnft:useListNfts')

export const useListNfts = () => {
  const { formatNftData } = useNFT()
  const {
    nfts: { ownedNfts },
    searchSelection,
  } = useAppContext()
  const { account } = useWeb3React()
  const { fetchInterestRate } = useInterestRate()
  const [selectedNfts] = useSelectNft()
  const [matched, setMatched] = useState([])
  logger({ matched, ownedNfts })

  const filteredNfts = useCallback(() => {
    const formattedNfts = ownedNfts.map((nft) => {
      const nftData = formatNftData(nft)
      const availableLiquidity = nftData.floorPrice
        ? nftData.floorPrice / minCollRatio
        : '--'
      nftData.availableLiquidity = availableLiquidity
      return nftData
    })
    const filtered = []
    if (!searchSelection) {
      return formattedNfts
    } else {
      formattedNfts.map((nft) => {
        switch (searchSelection.type) {
          case 'COLLECTION':
            if (nft.collectionName == searchSelection.title) {
              filtered.push(nft)
            }
          case 'TOKEN ID':
            if (nft.tokenId == searchSelection.title) {
              filtered.push(nft)
            }
        }
      })
    }
    logger({ filtered, ownedNfts, searchSelection })
    return filtered
  }, [ownedNfts, searchSelection])

  const displayNfts = ownedNfts?.length && account ? filteredNfts() : []

  useEffect(() => {
    const getInterestRate = async (nft) => {
      const interestRate = await fetchInterestRate(nft.tokenAddress)
      logger({ interestRate })
      return interestRate
    }
    if (selectedNfts.size && displayNfts.length) {
      const matchNfts = displayNfts.filter((nft) =>
        selectedNfts.has(nft.tokenId)
      )
      setMatched(matchNfts)
      Promise.all(
        matchNfts.map(async (nft) => {
          const ir = await getInterestRate(nft)
          nft.interestRate = ir
          return nft
        })
      ).then((data) => {
        setMatched(data)
      })
    } else {
      setMatched([])
    }
  }, [selectedNfts.size, displayNfts.length])

  return [displayNfts, matched]
}
