import React, { useEffect, useState } from 'react'

import Popup from 'reactjs-popup'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import debug from 'debug'

import Nav from '../Nav'
import SubnavLiquidation from '../SubnavLiquidation'
import DefaultRiskConfiguration from '../DefaultRiskConfiguration'
import ModuloRedeem from '../ModuloRedeem'
import useLendingPool from '../../hooks/useLendingPool'
import { useHistory, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import { useAppContext } from '../../AppContext'
import { useToken } from '../../hooks/useToken'
import { theme } from '../../config/theme'
import './Redeem.css'

const logger = debug('fluidnft:Redeem')

function Redeem(props) {
  const {
    subnavPoolProps,
    riskConfigurationProps,
    nftName,
    nftProjectName,
    nftImage,
  } = props
  const { borrowDefault, inputValue, setInputValue, ethUsdPrice } =
    useAppContext()
  const { fetchDefaultedBorrow } = useCollateralManager()
  const { account } = useWeb3React()
  const { tokenAddress, tokenId } = useParams()
  const { redeem } = useLendingPool()
  const { fetchEthUsdPrice } = useToken()

  useEffect(() => {
    fetchDefaultedBorrow(tokenAddress, tokenId)
  }, [account])

  // State management
  const [sliderValue, setSliderValue] = useState(0)
  // const [inputValue, setInputValue] = useState(0);
  const [inputValueUsd, setInputValueUsd] = useState(0)
  const [crValue, setCrValue] = useState(0)
  const [liquidationValue, setLiquidationValue] = useState(0)
  const [liquidationFee, setLiquidationFee] = useState(0)
  const [maxInputValue, setMaxInputValue] = useState(0)
  const [minCr, setMinCr] = useState(0)
  const [minRepayment, setMinRepayment] = useState(0)

  // Oracle <- TODO: refactor and manage state
  let ethUsd = ethUsdPrice

  // TODO: connect with protocol
  let liquidationFeePercentage = 0.05

  useEffect(() => {
    let liquidationFee_ = borrowDefault.borrowAmount * liquidationFeePercentage
    // Min / Max values
    let minCr_ = 1.5
    let maxInputValue_ = borrowDefault.borrowAmount + liquidationFee_
    let minRepayment_ =
      borrowDefault.borrowAmount -
      borrowDefault.floorPrice / minCr_ +
      liquidationFee_

    let floorPriceUsd_ = borrowDefault.floorPrice * ethUsd

    // Initial Values
    let initialSliderValue =
      (100 * (borrowDefault.borrowAmount + liquidationFee_) * minCr_) /
      borrowDefault.floorPrice
    let initialCrValue =
      (borrowDefault.floorPrice / borrowDefault.borrowAmount) * 100
    let initialInputValue = ''
    let initialInputValueUsd = initialInputValue
      ? initialInputValue * ethUsd
      : 0
    let initialLiquidationValue = borrowDefault.liquidationPrice

    setSliderValue(initialSliderValue)
    setInputValue(initialInputValue)
    setInputValueUsd(initialInputValueUsd)
    setCrValue(initialCrValue)
    setLiquidationValue(initialLiquidationValue)
    setLiquidationFee(liquidationFee_)
    setMaxInputValue(maxInputValue_)
    setMinCr(minCr_)
    setMinRepayment(minRepayment_)
  }, [borrowDefault])

  // Existing borrow balance
  // let borrowBalance = 100;
  // let borrowBalanceUsd = (borrowBalance * ethUsd).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  // Default fee
  // let liquidationFee = borrowDefault.borrowBalance * liquidationFeePercentage;
  // let liquidationFeeUsd = liquidationFee * ethUsd;

  // Update State
  useEffect(() => {
    const calcCrValue =
      (100 * borrowDefault.floorPrice) /
      (borrowDefault.borrowAmount + liquidationFee - inputValue)
    setCrValue(calcCrValue)
    const calcLiquidationValue =
      ((100 * borrowDefault.floorPrice) / calcCrValue) * minCr
    setLiquidationValue(calcLiquidationValue)
  }, [inputValue, sliderValue])

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
    const calcSliderValue =
      (100 * ((value - borrowDefault.borrowAmount - liquidationFee) * minCr)) /
      borrowDefault.floorPrice
    setSliderValue(calcSliderValue)
  }

  const getSliderValue = (event) => {
    const value = Number(event.target.value)
    // setSliderValue(value);
    const calcInputValue =
      borrowDefault.borrowAmount +
      liquidationFee -
      ((borrowDefault.floorPrice / minCr) * (100 - value)) / 100
    setInputValue(calcInputValue)
    setInputValueUsd(calcInputValue * ethUsd)
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/snipe')
  }

  const handleRedeemSubmit = () => {
    logger(
      'handleRedeemSubmit',
      borrowDefault.id,
      tokenAddress,
      String(inputValue),
      'WETH'
    )
    redeem(borrowDefault.id, tokenAddress, String(inputValue), 'WETH')
  }

  return (
    <div className='container-center-horizontal'>
      <div className='redeem screen'>
        <Nav />
        <div className='flex-row-12'>
          <div className='section-container-5'>
            <a onClick={handleClick}>
              <img className='back-arrow-5' src='/img/back-arrow-5@2x.svg' />
            </a>
            <div className='section-title-9 valign-text-middle oxanium-medium-black-27px'>{`Redeem borrow against ${nftName}`}</div>
          </div>
          <SubnavLiquidation
            subnavButtonTextSelected={subnavPoolProps.subnavButtonTextSelected}
            subnavButtonText={subnavPoolProps.subnavButtonText}
            className={subnavPoolProps.className}
          />
        </div>
        <img className='section-line-7' src='/img/section-line-6@1x.svg' />
        <div className='div-container-5'>
          <div className='nft-div-2'>
            <img className='nft-icon-6' src={nftImage} />
            <div className='nft-data-1'>
              <div className='redeem-item-1'>
                <div className='redeem-item-title valign-text-middle oxanium-medium-black-30px'>
                  {nftName}
                </div>
                <div className='redeem-item-project valign-text-middle oxanium-medium-gravel-18px'>
                  {nftProjectName}
                </div>
              </div>
              <div className='redeem-item-floor-price'>
                <div className='redeem-item-floor-price-usd valign-text-middle oxanium-medium-star-dust-15px'>
                  {borrowDefault && borrowDefault.floorPrice
                    ? `$${(borrowDefault.floorPrice * ethUsd).toLocaleString(
                        'en',
                        { minimumFractionDigits: 1, maximumFractionDigits: 3 }
                      )}`
                    : '--'}
                </div>
                <div className='overlap-group4-2'>
                  <img
                    className='weth-icon-34'
                    src='/img/weth-icon-68@2x.svg'
                  />
                  <div className='redeem-item-floor-price-weth valign-text-middle oxanium-medium-black-30px'>
                    {borrowDefault && borrowDefault.floorPrice
                      ? borrowDefault.floorPrice.toLocaleString('en', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 3,
                        })
                      : '--'}
                  </div>
                  <div className='redeem-1 valign-text-middle oxanium-medium-granite-gray-18px'>
                    Floor price
                  </div>
                </div>
              </div>
              <div className='redeem-item'>
                <div className='overlap-group-27'>
                  <div className='weth-container-25'>
                    <img
                      className='weth-icon-35'
                      src='/img/weth-icon-67@2x.svg'
                    />
                    <div className='redeem-2 valign-text-middle oxanium-medium-black-30px'>
                      {borrowDefault && borrowDefault.borrowAmount
                        ? borrowDefault.borrowAmount.toLocaleString('en', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 3,
                          })
                        : '--'}
                    </div>
                  </div>
                  <div className='redeem-1 valign-text-middle oxanium-medium-granite-gray-18px'>
                    Borrow balance
                  </div>
                </div>
                <div className='redeem-3 valign-text-middle oxanium-medium-star-dust-15px'>
                  {borrowDefault && borrowDefault.borrowAmount
                    ? `$${(borrowDefault.borrowAmount * ethUsd).toLocaleString(
                        'en',
                        { minimumFractionDigits: 1, maximumFractionDigits: 3 }
                      )}`
                    : '--'}
                </div>
              </div>
              <div className='redeem-item'>
                <div className='overlap-group-27'>
                  <div className='weth-container-25'>
                    <img
                      className='weth-icon-36'
                      src='/img/weth-icon-66@2x.svg'
                    />
                    <div className='redeem-2 valign-text-middle oxanium-medium-black-30px'>
                      {borrowDefault && borrowDefault.borrowAmount
                        ? (
                            borrowDefault.borrowAmount *
                            liquidationFeePercentage
                          ).toLocaleString('en', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 3,
                          })
                        : '--'}
                    </div>
                  </div>
                  <div className='redeem-1 valign-text-middle oxanium-medium-granite-gray-18px'>
                    Liquidation fee
                  </div>
                </div>
                <div className='redeem-3 valign-text-middle oxanium-medium-star-dust-15px'>
                  $
                  {(liquidationFee * ethUsd).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
          </div>
          <img
            className='section-divider-4'
            src='/img/section-divider-5@2x.svg'
          />
          <div className='redeem-div'>
            <div className='repay-weth valign-text-middle oxanium-medium-black-22px'>
              Repay WETH
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {account
                        ? maxInputValue.toLocaleString('en', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 4,
                          })
                        : // ((maxInputValue < account) ? maxInputValue : account).toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 4})
                          '--'}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={
                          maxInputValue < account ? maxInputValue : account
                        }
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <DefaultRiskConfiguration
              className={riskConfigurationProps.className}
              getSliderValue={getSliderValue}
              sliderValue={sliderValue}
              crValue={crValue}
              liquidationValue={liquidationValue}
              maxSliderValue={sliderValue}
              isRepay={true}
            />
            <ThemeProvider theme={theme}>
              {inputValue ? (
                <>
                  {minRepayment < inputValue && inputValue <= maxInputValue ? (
                    <Popup
                      trigger={
                        <div className='submit-repay-button'>
                          <ThemeProvider theme={theme}>
                            <StyledButton
                              variant='contained'
                              className='submit-repay-button'
                              onClick={handleRedeemSubmit}
                            >
                              Repay{' '}
                              {inputValue.toLocaleString('en', {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 4,
                              })}{' '}
                              WETH
                            </StyledButton>
                          </ThemeProvider>
                        </div>
                      }
                      modal
                      nested
                    >
                      <ModuloRedeem
                        moduloTitle='Complete redeem repayment'
                        subTitle='Repay'
                        repayAmount={inputValue * 1.0001} // TODO: better way to pay off accumulated interest
                        repayAmountUsd={inputValueUsd}
                        nftName={nftName}
                        nftProjectName={nftProjectName}
                        nftImage={nftImage}
                      />
                    </Popup>
                  ) : (
                    <div className='submit-repay-button'>
                      <StyledButton
                        disabled
                        variant='contained'
                        className='submit-repay-button'
                      >
                        {inputValue > borrowDefault.borrowAmount
                          ? `Select amount <= ${(
                              borrowDefault.borrowAmount + liquidationFee
                            ).toLocaleString('en', {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 3,
                            })}`
                          : `Select amount > ${minRepayment.toLocaleString(
                              'en',
                              {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 3,
                              }
                            )}`}
                      </StyledButton>
                    </div>
                  )}
                </>
              ) : (
                <div className='submit-repay-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-repay-button'
                  >
                    Select amount
                  </StyledButton>
                </div>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Redeem

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})
