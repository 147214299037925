import React from "react";
import "./BorrowItemInterest.css";

function BorrowItemInterest(props) {
  const { interestRate } = props;

  return (
    <div className="borrow-item-interest">
      <div className="borrow-interest-title valign-text-middle oxanium-medium-granite-gray-18px">Interest rate</div>
      <div className="borrow-interest-rate valign-text-middle oxanium-medium-black-30px">{isNaN(interestRate) ? "--" : (interestRate*100).toFixed(2)}% APR</div>
    </div>
  );
}

export default BorrowItemInterest;
