import React, { useEffect, useState } from 'react'

import Popup from 'reactjs-popup'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import Nav from '../Nav'
import Dashboard from '../Dashboard'
import SubnavPortfolio4 from '../SubnavPortfolio4'
import BorrowItemDiv from '../BorrowItemDiv'
import BorrowItemBalance from '../BorrowItemBalance'
import BorrowItemInterest from '../BorrowItemInterest'
import RiskConfiguration from '../RiskConfiguration'
import ModuloBorrow from '../ModuloBorrow'
import useLendingPool from '../../hooks/useLendingPool'
import useCollateralManager from '../../hooks/useCollateralManager'
import { theme } from '../../config/theme'
import { formatNumber } from '../../core/utils/format-number'
import { useActivateWallet } from '../../hooks/useActivateWallet'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import { useToken } from '../../hooks/useToken'
import './Borrow.css'

function Borrow(props) {
  const {
    floorPriceTitle,
    inputTitle,
    dashboardProps,
    subnavPortfolio4Props,
    nftName,
  } = props
  const { tokenAddress, tokenId } = useParams()
  const {
    setNftTokenAddress,
    setNftTokenId,
    setNftCollectionName,
    nftCollectionName,
    setNftImage,
    nftImage,
    nft,
    floorPrice,
    borrowBalance,
    setBorrowBalance,
    interestRate,
    setLiquidationValue,
    setCrValue,
    crValue,
    liquidationValue,
    userBorrows,
    setMinSliderValue,
    minSliderValue,
    ethUsdPrice,
  } = useAppContext()
  const { account } = useWeb3React()
  const { fetchNft, formatNftData } = useNFT()
  const { fetchBorrowBalance, fetchUserBorrows } = useCollateralManager()
  const { borrow } = useLendingPool()
  const { fetchEthUsdPrice } = useToken()
  useActivateWallet()

  let nftData = {}

  // Oracle data <- TODO: refactor and manage state
  let ethUsd = ethUsdPrice

  // let floorPrice = 100;
  let floorPriceUsd = floorPrice * ethUsd

  let formattedFloorPrice = floorPrice.toLocaleString('en', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
  let formattedFloorPriceUsd = floorPriceUsd.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  // <--- TO REMOVE

  // Min / Max values
  const minCr = 1.5
  const maxInputValue = floorPrice / minCr - borrowBalance

  // isNaN(borrowBalance) || isNaN(floorPrice) ? 0 : borrowBalance / floorPrice * 100

  // Initial Values
  const initialSliderValue = borrowBalance
    ? (100 * borrowBalance * minCr) / floorPrice
    : 75
  const initialCrValue = (minCr / initialSliderValue) * 100
  const [initialInputValue] = useState(
    borrowBalance ? '' : floorPrice / initialCrValue
  )
  const [initialInputValueUsd] = useState(
    initialInputValue ? initialInputValue * ethUsd : 0
  )
  const initialLiquidationValue = floorPriceUsd / initialCrValue

  // State management
  const [sliderValue, setSliderValue] = useState(initialSliderValue)
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [inputValueUsd, setInputValueUsd] = useState(initialInputValueUsd)
  // const [crValue, setCrValue] = useState(200);
  // const [liquidationValue, setLiquidationValue] = useState(10);

  // Update State
  useEffect(() => {
    let calcCrValue = ((minCr * 100) / initialSliderValue) * 100
    if (floorPrice && inputValue && borrowBalance) {
      calcCrValue = (100 * floorPrice) / (inputValue + borrowBalance)
    } else if (floorPrice && inputValue) {
      calcCrValue = (100 * floorPrice) / inputValue
    }

    const calcLiquidationValue = ((100 * floorPrice) / calcCrValue) * minCr
    setCrValue(calcCrValue)
    setLiquidationValue(calcLiquidationValue)
  }, [inputValue, sliderValue, floorPrice, borrowBalance, initialSliderValue])

  // onChange handlers
  const getInputValue = (event) => {
    const value = Number(event.target.value)
    setInputValue(value)
    setInputValueUsd(value * ethUsd)
    const calcSliderValue =
      (100 * ((value + borrowBalance) * minCr)) / floorPrice
    console.log({ calcSliderValue })
    setSliderValue(calcSliderValue)
  }

  const getSliderValue = (event) => {
    const value = Number(event.target.value)
    console.log({ value })
    setSliderValue(value)
    const calcInputValue = ((floorPrice / minCr) * value) / 100 - borrowBalance
    setInputValue(calcInputValue)
    setInputValueUsd(calcInputValue * ethUsd)
  }

  useEffect(() => {
    if (account) {
      fetchNft(tokenAddress, tokenId)
      fetchBorrowBalance(tokenAddress, tokenId)
      fetchEthUsdPrice()
    }
  }, [account])

  useEffect(() => {
    if (account) {
      fetchBorrowBalance(tokenAddress, tokenId)
    }
  }, [account, userBorrows])

  useEffect(() => {
    if (
      !initialInputValue &&
      borrowBalance >= 0 &&
      floorPrice >= 0 &&
      initialCrValue > 0
    ) {
      setInputValue(borrowBalance ? '' : floorPrice / initialCrValue)
    }
  }, [initialInputValue, borrowBalance, floorPrice, initialCrValue])

  useEffect(() => {
    console.log({ initialInputValue, ethUsd, inputValue })
    if (!initialInputValue && inputValue && ethUsd) {
      setInputValueUsd(inputValue ? inputValue * ethUsd : 0)
    }
  }, [inputValue, ethUsd])

  useEffect(() => {
    console.log({ sliderValue, initialSliderValue })
    if (isNaN(sliderValue) && !isNaN(initialSliderValue)) {
      setSliderValue(initialSliderValue)
    }
  }, [initialSliderValue, initialSliderValue])

  useEffect(() => {
    if (Object.keys(nft).length !== 0) {
      nftData = formatNftData(nft)
      setNftTokenAddress(nftData.tokenAddress)
      setNftTokenId(nftData.tokenId)
      setNftCollectionName(nftData.collectionName)
      setNftImage(nftData.image)
    }
  }, [nft])

  useEffect(() => {
    if (account && !isNaN(floorPrice) && !isNaN(borrowBalance)) {
      setMinSliderValue((borrowBalance / floorPrice) * 100)
    }
  }, [floorPrice, borrowBalance, account])

  const reloadImage = (e) => {
    e.target.src = nftImage
  }

  // Navigation
  let history = useHistory()

  const handleClick = () => {
    history.push('/')
  }

  const handleBorrowSubmit = async () => {
    await borrow(tokenAddress, tokenId, inputValue, 'WETH')
    await fetchUserBorrows()
  }

  const formatValue = (value, decimals) => {
    return Number(value).toFixed(decimals)
  }

  return (
    <div className='container-center-horizontal'>
      <div className='borrow screen'>
        <Nav />
        <Dashboard
          dashboardAverageCr1Props={dashboardProps.dashboardAverageCr1Props}
          dashboardAverageCr2Props={dashboardProps.dashboardAverageCr2Props}
        />
        <div className='flex-row-2'>
          <div className='section-container'>
            <a onClick={handleClick}>
              <img className='back-arrow' src='/img/back-arrow@2x.svg' />
            </a>
            <div className='section-title-1 valign-text-middle oxanium-medium-black-27px'>{`Borrow against ${nftName}`}</div>
          </div>
          <SubnavPortfolio4 className={subnavPortfolio4Props.className} />
        </div>
        <img className='section-line-1' src='/img/section-line@1x.svg' />
        <div className='div-container'>
          <div className='nft-div'>
            <img
              className='nft-collection-icon-3'
              src={nftImage}
              onError={reloadImage}
            />
            {/* <LazyLoadImage
                className="nft-collection-icon-3"
                src={nftImage}
                onLoad={(event) => event.target.style.display = 'inline-block'}
                onError={(event) => event.target.style.display = 'none'}
              /> */}
            <div className='nft-dashboard'>
              <BorrowItemDiv
                nftName={nftName}
                nftProjectName={nftCollectionName}
              />
              <div className='borrow-item-floor-price'>
                <div className='overlap-group2-3'>
                  <div className='floor-price-container'>
                    <img
                      className='weth-logo-3'
                      src='/img/weth-logo-3@2x.svg'
                    />
                    <div className='floor-price-weth valign-text-middle oxanium-medium-black-30px'>
                      {formattedFloorPrice}
                    </div>
                  </div>
                  <div className='floor-price-title valign-text-middle oxanium-medium-granite-gray-18px'>
                    {floorPriceTitle}
                  </div>
                </div>
                <div className='floor-price-usd valign-text-middle oxanium-medium-star-dust-18px'>
                  ${formattedFloorPriceUsd}
                </div>
              </div>
              <BorrowItemBalance
                borrowBalance={borrowBalance.toLocaleString('en', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}
                borrowBalanceUsd={(borrowBalance * ethUsd).toLocaleString(
                  'en',
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              />
              <BorrowItemInterest interestRate={interestRate} />
            </div>
          </div>
          <img className='section-divider' src='/img/section-divider@2x.svg' />
          <div className='borrow-div'>
            <div className='input-title valign-text-middle oxanium-medium-black-22px'>
              {inputTitle}
            </div>
            <div className='input-container'>
              <div className='input-container-2'>
                <input
                  type='number'
                  className='borrow-amount-input valign-text-middle oxanium-medium-black-40px'
                  placeholder='0.0'
                  onChange={getInputValue}
                  value={inputValue === '' ? '' : inputValue}
                  max={maxInputValue}
                />
                <div className='borrow-container-input-1 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-amount-usd valign-text-middle'>
                    $
                    {inputValueUsd.toLocaleString('en', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
              <div className='overlap-group111'>
                <div className='input-asset111'>
                  <div className='borrow-asset-title valign-text-middle oxanium-medium-black-20px'>
                    WETH
                  </div>
                  <img className='icon-weth' src='/img/weth-icon-1@2x.svg' />
                </div>
                <div className='borrow-container-input-2 oxanium-medium-granite-gray-15px-2'>
                  <div className='borrow-max-ltv'>
                    <div className='borrow-max-ltv-amount valign-text-middle'>
                      {isNaN(maxInputValue)
                        ? ''
                        : formatValue(maxInputValue, 3)}
                    </div>
                    <ThemeProvider theme={theme}>
                      <StyledButtonMax
                        variant='contained'
                        className='submit-borrow-button'
                        value={maxInputValue}
                        onClick={getInputValue}
                      >
                        MAX
                      </StyledButtonMax>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
            <RiskConfiguration
              getSliderValue={getSliderValue}
              sliderValue={sliderValue}
              crValue={crValue}
              liquidationValue={liquidationValue}
              minSliderValue={minSliderValue}
              isRepay={false}
            />
            <ThemeProvider theme={theme}>
              {inputValue ? (
                <>
                  {0 < inputValue && inputValue <= maxInputValue ? (
                    <Popup
                      trigger={
                        <div className='submit-borrow-button'>
                          <StyledButton
                            variant='contained'
                            className='submit-borrow-button'
                            onClick={handleBorrowSubmit}
                          >
                            Borrow{' '}
                            {inputValue.toLocaleString('en', {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 4,
                            })}{' '}
                            WETH
                          </StyledButton>
                        </div>
                      }
                      modal
                      nested
                    >
                      <ModuloBorrow
                        moduloTitle='Complete borrow'
                        subTitle={`Borrow @ ${formatNumber(
                          interestRate * 100
                        )}% APR`}
                        borrowAmount={inputValue}
                        borrowAmountUsd={inputValueUsd}
                        nftName={nftName}
                        nftProjectName={nftCollectionName}
                        nftImage={nftImage}
                      />
                    </Popup>
                  ) : (
                    <div className='submit-borrow-button'>
                      <StyledButton
                        disabled
                        variant='contained'
                        className='submit-borrow-button'
                      >
                        {inputValue > minCr
                          ? `Select CR > ${minCr * 100}%`
                          : 'Select amount > 0'}
                      </StyledButton>
                    </div>
                  )}
                </>
              ) : (
                <div className='submit-borrow-button'>
                  <StyledButton
                    disabled
                    variant='contained'
                    className='submit-borrow-button'
                  >
                    Select amount
                  </StyledButton>
                </div>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Borrow

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 30,
  borderRadius: 10,
})

const StyledButtonMax = styled(Button)({
  fontFamily: 'Oxanium',
  fontSize: 15,
  borderRadius: 50,
  height: 20,
  width: 0,
})
