import React from "react";
import "./ModuloApprove.css";
var Spinner = require('react-spinkit');
import useTransaction from '../../hooks/useTransaction';

function ModuloApprove(props) {
  const { approveText, approveDescription } = props;
  const { txnStatus } = useTransaction();

  if (txnStatus == "LOADING") {
    return (
      <div className="modulo-approve">
      <div className="step-viz-loading">
      </div>
      <div className="step-loading">
        <Spinner name="ball-scale-ripple-multiple" />
      </div>
        <div className="approve-container">
          <div className="approve-text valign-text-middle oxanium-medium-black-22px">{approveText}</div>
          <p className="approve-description valign-text-middle oxanium-medium-granite-gray-16px">{approveDescription}</p>
        </div>
      </div>
    );
  } else if (txnStatus == "ERROR") {
    return (
      <div className="modulo-approve">
        <div className="step-viz-error">
        </div>
        <div className="approve-container">
          <div className="approve-text valign-text-middle oxanium-medium-black-22px">{approveText}</div>
          <p className="approve-description valign-text-middle oxanium-medium-granite-gray-16px">{approveDescription}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modulo-approve">
        <div className="step-viz-complete">
          <img src="/img/circle_check.svg" className="filter-green" />
        </div>
        <div className="approve-container">
          <div className="approve-text valign-text-middle oxanium-medium-black-22px">{approveText}</div>
          <p className="approve-description valign-text-middle oxanium-medium-granite-gray-16px">{approveDescription}</p>
        </div>
      </div>
    );
  }

}

export default ModuloApprove;
