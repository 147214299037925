import React from "react";
import ModuloApprove from "../ModuloApprove";
import ModuloConfirm from "../ModuloConfirm";
import "./ModuloLiquidate.css";

function ModuloLiquidate(props) {
  const { moduloTitle, subTitle, nftName, nftProjectName, bidAmount, bidAmountUsd, nftImage } = props;

  return (
    <div className="container-center-horizontal">
      <div className="modulo-liquidate-1">
        <div className="overlap-group1-10">
          <img className="data-line-2" src="/img/data-line@1x.svg" />
          <img className="header-line-3" src="/img/data-line@1x.svg" />
          <div className="modulo-title-3 valign-text-middle oxanium-medium-black-30px">{moduloTitle}</div>
        </div>
        <div className="overlap-group2-5">
          <div className="overlap-group-19">
            <div className="amount-weth-3 valign-text-middle oxanium-medium-black-30px">{bidAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 3})}</div>
            <div className="amount-usd-3 valign-text-middle oxanium-medium-star-dust-16px">{`$${bidAmountUsd.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 3})}`}</div>
            <div className="repay-title-3 valign-text-middle oxanium-medium-granite-gray-16px">{subTitle}</div>
            <img className="weth-logo-6" src="/img/weth-logo-4@2x.svg" />
          </div>
          <div className="modulo-collateral-3">
            <img className="nft-icon-2" src={nftImage} />
            <div className="collateral-container">
              <div className="nft-title-4 valign-text-middle oxanium-medium-black-30px">{nftName}</div>
              <div className="nft-project-3 valign-text-middle oxanium-medium-gravel-20px">{nftProjectName}</div>
            </div>
          </div>
        </div>
        <ModuloApprove
          approveText="Approve WETH"
          approveDescription="To bid you must approve WETH, which requires a one-time gas fee."
        />
        <ModuloConfirm
          confirmText={`Confirm ${bidAmount.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 3})} WETH bid`}
          confirmDescription="Accept the signature request in your wallet and wait for your bid to process."
        />
        <ModuloConfirm
          confirmText="Success!"
          confirmDescription="Your WETH will be locked in our smart contracts until you are out-bid or the auction ends; when the NFT is sent to the highest bidder."
          className="modulo-2"
        />
      </div>
    </div>
  );
}

export default ModuloLiquidate;
