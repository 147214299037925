import React from 'react'

import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { ThemeProvider, styled } from '@mui/material/styles'

import { useAppContext } from '../../AppContext'
import { theme } from '../../config/theme'
import './SubnavLiquidation.css'

function SubnavLiquidation(props) {
  const { subnavButtonTextSelected, subnavButtonText, className } = props
  const { isLiquidationLiquidate, setLiquidationLiquidate } = useAppContext()

  const handleChange = (event, newState) => {
    if (newState !== null) {
      setLiquidationLiquidate(newState)
    }
  }

  return (
    <div className={`subnav-pool ${className || ''}`}>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          color='primary'
          value={isLiquidationLiquidate}
          exclusive
          onChange={handleChange}
        >
          <StyledToggleButton value={true}>
            {subnavButtonTextSelected}
          </StyledToggleButton>
          <StyledToggleButton value={false}>
            {subnavButtonText}
          </StyledToggleButton>
        </ToggleButtonGroup>
      </ThemeProvider>
    </div>
  )
}

export default SubnavLiquidation

const StyledToggleButton = styled(ToggleButton)({
  textTransform: 'none',
  fontFamily: 'Oxanium',
  fontSize: 18,
  borderRadius: 5,
})
