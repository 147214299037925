import React, { useEffect } from 'react'

import { useWeb3React } from '@web3-react/core'
import { useParams } from 'react-router-dom'
import debug from 'debug'

import Deposit from '../Deposit'
import Withdraw from '../Withdraw'
import { useAppContext } from '../../AppContext'
import { useLendingPool } from '../../hooks/useLendingPool'
import { useNFT } from '../../hooks/useNFT'
import './LendingPool.css'

const logger = debug('fluidnft:LendinPool')

function LendingPool(props) {
  const {
    sectionTitle,
    lendingPoolTitle,
    lendingPoolProject,
    depositLiquidityTitle,
    depositBalanceTitle,
    inputTitle,
    subnavPoolProps,
    depositYieldTitle,
  } = props

  const {
    isLendingPoolDeposit,
    pool,
    poolSymbols,
    poolCollectionNames,
    poolCollectionImages,
    poolCollectionAddresses,
  } = useAppContext()

  const { collectionTokenAddress, poolTokenName } = useParams()
  // const { fetchPool } = usePool();
  const { fetchPool } = useLendingPool()
  const { fetchPoolNftData } = useNFT()
  const { account } = useWeb3React()

  useEffect(() => {
    fetchPool(collectionTokenAddress)
  }, [])

  useEffect(() => {
    fetchPool(collectionTokenAddress)
  }, [account])

  useEffect(() => {
    if (pool) {
      logger('pool?', pool)
      fetchPoolNftData([pool])
    }
  }, [pool])

  return (
    <>
      {isLendingPoolDeposit ? (
        <Deposit
          // sectionTitle={sectionTitle}
          // lendingPoolTitle={lendingPoolTitle}
          // lendingPoolProject={lendingPoolProject}
          depositLiquidityTitle={depositLiquidityTitle}
          depositBalanceTitle={depositBalanceTitle}
          inputTitle={inputTitle}
          subnavPoolProps={subnavPoolProps}
          depositYieldTitle={depositYieldTitle}
          collectionTokenAddress={collectionTokenAddress}
          poolTokenName={poolTokenName}
          poolSymbol={poolSymbols ? poolSymbols[0] : ''}
          poolCollectionName={poolCollectionNames ? poolCollectionNames[0] : ''}
          poolCollectionImage={
            poolCollectionImages ? poolCollectionImages[0] : ''
          }
          poolCollectionAddress={
            poolCollectionAddresses ? poolCollectionAddresses[0] : ''
          }
          poolAvailableLiquidity={
            pool ? (pool.deposit - pool.borrowed).toFixed(3) : '--'
          }
          poolYield={pool ? (100 * pool.apy).toFixed(2) : '--'}
          userPoolDepositBalance={pool ? pool.userBalance : '--'}
        />
      ) : (
        <Withdraw
          sectionTitle={sectionTitle}
          lendingPoolTitle={lendingPoolTitle}
          lendingPoolProject={lendingPoolProject}
          depositLiquidityTitle={depositLiquidityTitle}
          depositBalanceTitle={depositBalanceTitle}
          inputTitle={inputTitle}
          subnavPoolProps={subnavPoolProps}
          depositYieldTitle={depositYieldTitle}
          collectionTokenAddress={collectionTokenAddress}
          poolTokenName={poolTokenName}
          poolSymbol={poolSymbols ? poolSymbols[0] : ''}
          poolCollectionName={poolCollectionNames ? poolCollectionNames[0] : ''}
          poolCollectionImage={
            poolCollectionImages ? poolCollectionImages[0] : ''
          }
          poolCollectionAddress={
            poolCollectionAddresses ? poolCollectionAddresses[0] : ''
          }
          poolAvailableLiquidity={
            pool ? (pool.deposit - pool.borrowed).toFixed(3) : '--'
          }
          poolYield={pool ? (100 * pool.apy).toFixed(2) : '--'}
          userPoolDepositBalance={pool ? pool.userBalance.toFixed(3) : '--'}
        />
      )}
    </>
  )
}

export default LendingPool
