import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Countdown from 'react-countdown'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debug from 'debug'

import Nav from '../Nav'
import SearchBorrow from '../SearchBorrow'
import RowItem7 from '../RowItem7'
import RowItem8 from '../RowItem8'
import RowItem9 from '../RowItem9'
import './Liquidations.css'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import { useCollateralManager } from '../../hooks/useCollateralManager'
import SubnavLiquidation from '../SubnavLiquidation'

const logger = debug('fluidnft:Liquidations')

function Liquidations(props) {
  const {
    sectionTitle,
    headerItemText1,
    headerItemText2,
    headerItemText3,
    headerItemText4,
    headerItemText5,
    headerItemText6,
    headerItemText7,
    rowItemText1,
    rowItemText2,
    headerItemText8,
    rowItemText3,
    rowItemText4,
    subnavPoolProps,
    searchProps,
    rowItem7Props,
    rowItem8Props,
  } = props
  const { fetchNftData } = useNFT()
  const { fetchDefaultedBorrows } = useCollateralManager()
  const {
    borrowImages,
    borrowSymbols,
    borrows,
    isLiquidationLiquidate,
    searchSelection,
    borrowDefaults,
  } = useAppContext()
  const { account } = useWeb3React()

  logger('account', account)

  const [defaults, setDefaults] = useState([])

  useEffect(() => {
    if (borrowDefaults) {
      fetchNftData(borrowDefaults)
      logger('borrowDefaults:::', borrowDefaults)
    }
  }, [borrowDefaults])

  useEffect(() => {
    fetchDefaultedBorrows()
  }, [account])

  useEffect(() => {
    if (borrowDefaults) {
      logger('borrowDefaults::', borrowDefaults)
      setDefaults(borrowDefaults)
    }
  }, [borrowDefaults])

  useEffect(() => {
    if (borrowDefaults) {
      setDefaults(filterBorrows(borrowDefaults))
    }
  }, [borrowDefaults, isLiquidationLiquidate])

  const [tokenIdImageMap, setTokenIdImageMap] = useState({})

  const filterBorrows = (borrows) => {
    let filtered = []
    let tokenIdImageMap_ = {}
    if (account) {
      borrows.map((borrow, index) => {
        tokenIdImageMap_[borrow.nftTokenId] = index
        if (isLiquidationLiquidate) {
          if (borrow.borrower != account) {
            filtered.push(borrow)
          }
        } else {
          if (borrow.borrower == account) {
            filtered.push(borrow)
          }
        }
        setTokenIdImageMap(tokenIdImageMap_)
      })
    }
    return filtered
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return `${hours}h ${minutes}m ${seconds}s`
  }

  const rowComponent = ({ borrow, index }) => {
    return (
      // PATCH. TODO Resolve collRatio maths at the sc level
      borrow.collRatio < 150 ? 
      <div className='div-container-33' key={index}>
        <img className='x-divider' src='/img/items-divider-1@1x.svg' />
        <Link to={`/borrow/${borrow.nftContractAddress}/${borrow.nftTokenId}`}>
          <div className='row-div-4 oxanium-normal-black-18px'>
            <RowItem7
              nftImage={borrowImages[tokenIdImageMap[borrow.nftTokenId]]}
              nftSymbol={borrow.nftSymbol}
              nftTokenId={borrow.nftTokenId}
            />
            <div className='header-item-text-17 valign-text-middle'>
              {borrow.collRatio.toFixed(2)}%
            </div>
            <RowItem8 nftFloor={borrow.floorPrice} />
            <RowItem9 currentBid={borrow.currentBid} />
            <div className='row-item-text valign-text-middle'>
              {borrow.auctionStart ? (
                <Countdown
                  date={borrow.auctionStart * 1000 + 1000 * 60 * 60 * 24}
                  renderer={renderer}
                />
              ) : (
                '--'
              )}
            </div>
            <div className='row-item-text-1 valign-text-middle'>
              {borrow.currentBidder &&
              borrow.currentBidder !=
                '0x0000000000000000000000000000000000000000'
                ? `${borrow.currentBidder.substring(
                    0,
                    6
                  )}...${borrow.currentBidder.slice(-4)}`
                : '--'}
            </div>
          </div>
        </Link>
      </div> :
      <></>
    )
  }

  return (
    <div className='container-center-horizontal'>
      <div className='liquidations screen'>
        <Nav />
        <div className='flex-row-13'>
          <div className='section-title-10 valign-text-middle oxanium-medium-black-27px'>
            {sectionTitle}
          </div>
          <SubnavLiquidation
            subnavButtonTextSelected={subnavPoolProps.subnavButtonTextSelected}
            subnavButtonText={subnavPoolProps.subnavButtonText}
            className={subnavPoolProps.className}
          />
        </div>
        <img className='section-line-9' src='/img/section-line-5@1x.svg' />
        {/* <div className="flex-row-1">
          <SearchBorrow pathName="liquidations" className={searchProps.className} />
        </div> */}
        <div className='header-div-2'>
          <div className='overlap-group-28'>
            <div className='header-item-text-18 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText1}
            </div>
            <img
              className='arrow-caret_down-12'
              src='/img/arrow---caret-down-12@2x.svg'
            />
          </div>
          <div className='overlap-group1-15'>
            <div className='header-item-text-19 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText2}
            </div>
            <img
              className='arrow-caret_down-13'
              src='/img/arrow---caret-down-13@2x.svg'
            />
          </div>
          <div className='overlap-group2-11'>
            <div className='header-item-text-20 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText3}
            </div>
            <img
              className='arrow-caret_down-14'
              src='/img/arrow---caret-down-14@2x.svg'
            />
          </div>
          <div className='overlap-group3-3'>
            <div className='header-item-text-21 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText4}
            </div>
            <img
              className='arrow-caret_down-15'
              src='/img/arrow---caret-down-15@2x.svg'
            />
          </div>
          <div className='overlap-group4-3'>
            <div className='header-item-text-22 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText5}
            </div>
            <img
              className='arrow-caret_down-16'
              src='/img/arrow---caret-down-16@2x.svg'
            />
          </div>
          <div className='overlap-group5-2'>
            <div className='header-item-text-23 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText6}
            </div>
            <img
              className='arrow-caret_down-17'
              src='/img/arrow---caret-down-17@2x.svg'
            />
          </div>
        </div>
        <Scrollbars style={{ width: 1240, height: screen.height }}>
          {defaults &&
            defaults.map((borrow, index) =>
              rowComponent({ borrow: borrow, index: index })
            )}
        </Scrollbars>
      </div>
    </div>
  )
}

export default Liquidations
