import React, { useState, useEffect } from "react";
import "./SearchBorrow.css";
import { Autocomplete, TextField } from '@mui/material';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { styled } from '@mui/material/styles';
import { useAppContext } from "../../AppContext";
import { useNFT } from "../../hooks/useNFT";
import { useWeb3React } from "@web3-react/core"
import { useCollateralManager } from "../../hooks/useCollateralManager";

function SearchBorrow(props) {
  const { pathName } = props;
  const { borrows, setSearchSelection, borrowNames, borrowSymbols, isLiquidationLiquidate, isMyPortfolioBorrow } = useAppContext();
  const { account } = useWeb3React();
  // const { fetchUserBorrows } = useBorrow();
  const { fetchUserBorrows } = useCollateralManager();
  const { fetchNftData } = useNFT();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchUserBorrows();
  }, []);

  useEffect(() => {
    fetchNftData(borrows);
  }, [borrows]);

  const filterBorrows = (borrows) => {
    let filtered = [];
    if (pathName=="liquidations" && !isLiquidationLiquidate && !account) {
      filtered = [];
    } else if (pathName=="liquidations" && !isLiquidationLiquidate && account) {
      borrows.map((borrow, index) => {
        if (borrow.ownerAddress === walletAddress) {
          borrow["searchIndex"] = index;
          filtered.push(borrow);
        }
      })
    } else if (pathName=="" && !isMyPortfolioBorrow && !account) {
      filtered = [];
    } else if (pathName=="" && !isMyPortfolioBorrow && account) {
      borrows.map((borrow, index) => {
        borrow["searchIndex"] = index;
        filtered.push(borrow);
      })
    } else {
      borrows.map((borrow, index) => {
        borrow["searchIndex"] = index;
        filtered.push(borrow);
      })
    }
    return filtered;
  }

  useEffect(() => {
    if (borrows.length !== 0) {
      const optionsData = [];
      const seen = new Set([]);
      filterBorrows(borrows).map((borrow, index) => {
        const collectionName = borrowNames[borrow.searchIndex];
        const collectionSymbol = borrowSymbols[borrow.searchIndex];
        const tokenId = String(borrow.tokenId);
        if (!seen.has(collectionName)) {
          optionsData.push({title: collectionName, type: "COLLECTION"})
          seen.add(collectionName)
        }
        if (!seen.has(collectionSymbol)) {
          optionsData.push({title: collectionSymbol, type: "SYMBOL"})
          seen.add(collectionSymbol)
        }
        if (!seen.has(tokenId)) {
          optionsData.push({title: tokenId, type: "TOKEN ID"})
          seen.add(tokenId)
        }        
      });
      setOptions(optionsData);
    };
  }, [borrows, borrowSymbols, account, isLiquidationLiquidate, isMyPortfolioBorrow]);

  return (
    <Autocomplete
      id="my-borrows"
      options={options.sort((a, b) => -b.type.localeCompare(a.type) || -b.title.localeCompare(a.title))}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.title}
      onChange={(event, value) => setSearchSelection(value)}
      sx={{ width: 748, height: 44 }}
      size="small"
      renderInput={(params) => {
        return (
          <StyledTextField
            {...params}
            placeholder="Filter by collection, symbol or token id"
            fullWidth
            sx={{ width: 748, height: 44, borderRadius: 30, fontFamily: "Oxanium" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        );
      }}
    /> 
  );
}

export default SearchBorrow;


const StyledTextField = styled(TextField)({
  textTransform: 'none',
  fontFamily: "Oxanium",
  fontSize: 40,
  borderRadius: 30,
});

// // mock data to match Liquidations.jsx
// const walletAddress = "0x123";