import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debug from 'debug'

import Nav from '../Nav'
import SubnavPool from '../SubnavPool'
import SearchPool from '../SearchPool'
import RowItem10 from '../RowItem10'
import RowItem22 from '../RowItem22'
import './LendingPools.css'
import { useLendingPool } from '../../hooks/useLendingPool'
import { useNFT } from '../../hooks/useNFT'
import { useAppContext } from '../../AppContext'
import useFToken from '../../hooks/useFToken'
import useTransaction from '../../hooks/useTransaction'

const logger = debug('fluidnft:LendingPools')

function LendingPools(props) {
  const {
    sectionTitle,
    headerItemText1,
    headerItemText2,
    headerItemText3,
    headerItemText4,
    headerItemText5,
    headerItemText6,
    subnavPoolProps,
    searchProps,
    rowItem224Props,
  } = props
  const {
    pools,
    poolSymbols,
    poolCollectionNames,
    poolCollectionImages,
    poolCollectionAddresses,
    isLendingPoolDeposit,
    searchSelection,
    setSearchSelection,
    fTokenSupplyWETH,
    fTokenBalanceWETH,
    fTokenYieldWETH,
  } = useAppContext()
  const { fetchFTokenBalance, fetchFTokenYield, fetchFTokenSupply } =
    useFToken()
  const { account } = useWeb3React()
  // const { fetchPools } = usePool();
  const { fetchPools } = useLendingPool()
  const { fetchPoolNftData } = useNFT()
  const { txnStatus } = useTransaction()

  const fTokenBalance = {
    WETH: fTokenBalanceWETH,
  }

  const fTokenYield = {
    WETH: fTokenYieldWETH,
  }

  const fTokenSupply = {
    WETH: fTokenSupplyWETH,
  }

  useEffect(() => {
    logger('useEffect, fetchPools')
    fetchPools()
  }, [account])

  useEffect(() => {
    if (pools) {
      logger('pools>>>', pools)
      fetchPoolNftData(pools)
    }
  }, [pools])

  useEffect(() => {
    if (account) {
      fetchFTokenBalance()
      fetchFTokenYield()
      fetchFTokenSupply()
    }
  }, [account, txnStatus])

  const formatNumber = (value) => {
    let res
    if (value == '--') {
      res = value
    } else {
      res = Number(value).toFixed(1)
    }
    return res
  }

  const filterPools = (pools) => {
    let filtered = []
    if (isLendingPoolDeposit || !account) {
      filtered = pools
    } else {
      pools.map((pool) => {
        if (pool.userBalance > 0) {
          filtered.push(pool)
        }
      })
    }
    return filtered
  }

  const searchFilteredPools = (pools) => {
    const filtered = []
    if (!searchSelection) {
      return filterPools(pools)
    } else {
      filterPools(pools).map((pool, index) => {
        switch (searchSelection.type) {
          case 'COLLECTION':
            if (poolCollectionNames[index] == searchSelection.title) {
              pool['searchIndex'] = index
              filtered.push(pool)
            }
          case 'POOL':
            if (poolSymbols[index] == searchSelection.title) {
              pool['searchIndex'] = index
              filtered.push(pool)
            }
        }
      })
    }
    return filtered
  }

  const handleClick = () => {
    setSearchSelection(null)
  }

  return (
    <div className='container-center-horizontal'>
      <div className='lending-pools screen'>
        <Nav />
        <div className='flex-row-8'>
          <div className='section-title-5 valign-text-middle'>
            {sectionTitle}
          </div>
          <SubnavPool
            subnavButtonTextSelected={subnavPoolProps.subnavButtonTextSelected}
            subnavButtonText={subnavPoolProps.subnavButtonText}
            className={subnavPoolProps.className}
          />
        </div>
        <img className='section-line-5' src='/img/section-line-5@1x.svg' />
        {/* <div className="flex-row-1">
          <SearchPool className={searchProps.className} />
        </div> */}
        <div className='header-div-1'>
          <div className='overlap-group-24'>
            <div className='header-item-text-11 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText1}
            </div>
            <img
              className='arrow-caret_down-6'
              src='/img/arrow---caret-down-6@2x.svg'
            />
          </div>
          <div className='overlap-group1-14'>
            <div className='header-item-text-12 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText2}
            </div>
            <img
              className='arrow-caret_down-7'
              src='/img/arrow---caret-down-7@2x.svg'
            />
          </div>
          <div className='overlap-group2-10'>
            <div className='header-item-text-13 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText3}
            </div>
            <img
              className='arrow-caret_down-8'
              src='/img/arrow---caret-down-8@2x.svg'
            />
          </div>
          <div className='overlap-group3-2'>
            <div className='header-item-text-14 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText4}
            </div>
            <img
              className='arrow-caret_down-9'
              src='/img/arrow---caret-down-9@2x.svg'
            />
          </div>
          <div className='overlap-group4-1'>
            <div className='header-item-text-15 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText5}
            </div>
            <img
              className='arrow-caret_down-10'
              src='/img/arrow---caret-down-10@2x.svg'
            />
          </div>
          <div className='overlap-group5-1'>
            <div className='header-item-text-16 valign-text-middle oxanium-normal-gravel-18px'>
              {headerItemText6}
            </div>
            <img
              className='arrow-caret_down-11'
              src='/img/arrow---caret-down-11@2x.svg'
            />
          </div>
        </div>
        <Scrollbars style={{ width: 1240, height: screen.height }}>
          {searchFilteredPools(pools).map(
            (pool, index) => (
              logger(index, 'pool', pool),
              (
                <div className='div-container-3' key={index}>
                  <img
                    className='x-divider'
                    src='/img/items-divider-1@1x.svg'
                  />
                  <Link
                    to={`/pool/${
                      poolCollectionAddresses[
                        pool.searchIndex ? pool.searchIndex : index
                      ]
                    }/WETH`}
                    onClick={handleClick}
                  >
                    <div className='row-div-3 oxanium-normal-black-18px'>
                      <div className='row-item-5'>
                        <div className='icon-container-3'>
                          <img
                            className='weth-icon-pill-1'
                            src='/img/weth-icon-pill-1@2x.svg'
                          />
                          <div className='nft-collection-icon-7'>
                            <img
                              className='nft-collection-icon-7-image'
                              src={
                                poolCollectionImages[
                                  pool.searchIndex ? pool.searchIndex : index
                                ]
                              }
                            />
                          </div>
                          <img
                            className='weth-icon-27'
                            src='/img/weth-icon-45@2x.svg'
                          />
                        </div>
                        <div className='lending-pool-title-1 valign-text-middle oxanium-normal-black-18px'>
                          {
                            poolSymbols[
                              pool.searchIndex ? pool.searchIndex : index
                            ]
                          }
                        </div>
                      </div>
                      <div className='nft-collection-title valign-text-middle'>
                        {
                          poolCollectionNames[
                            pool.searchIndex ? pool.searchIndex : index
                          ]
                        }
                      </div>
                      <RowItem10 marketSize={pool.deposit} />
                      <RowItem22 borrowedAmount={pool.borrowed.toFixed(3)} />
                      <RowItem22
                        borrowedAmount={
                          account ? pool.userBalance.toFixed(3) : '--'
                        }
                        className={rowItem224Props.className}
                      />
                      <div className='deposit-apy valign-text-middle'>
                        {(100 * pool.apy).toFixed(2)}%
                      </div>
                    </div>
                  </Link>
                </div>
              )
            )
          )}
        </Scrollbars>
      </div>
    </div>
  )
}

export default LendingPools
