import React from "react";
import "./RowItem7.css";

function RowItem7(props) {
  const { className, nftSymbol, nftTokenId, nftImage } = props;

  return (
    <div className={`row-item-9-1 ${className || ""}`}>
      <img className="nft-icon-7" src={nftImage} />
      <div className="nft-title-7 valign-text-middle oxanium-semi-bold-black-18px">
        <span>
          <span className="span-2 oxanium-semi-bold-black-18px">
            {nftSymbol}
            <br />
          </span>
          <span className="span-2 oxanium-normal-black-18px">#{nftTokenId}</span>
        </span>
      </div>
    </div>
  );
}

export default RowItem7;
