import React, { useEffect } from 'react'

import { Scrollbars } from 'react-custom-scrollbars-2'
import { styled } from '@mui/system'
import { switchUnstyledClasses } from '@mui/base/SwitchUnstyled'
import { useWeb3React } from '@web3-react/core'
import 'regenerator-runtime/runtime'
import debug from 'debug'

import { minCollRatio } from '../../core/constants'
import { useAppContext } from '../../AppContext'
import { useNFT } from '../../hooks/useNFT'
import { useToken } from '../../hooks/useToken'
import { useListNfts } from '../../hooks/useListNfts'
import { FluidDrawer } from '../Drawer'
import { formatNumber } from '../../core/utils/format-number'
import Nav from '../Nav'
import Dashboard from '../Dashboard'
import SubnavPortfolio4 from '../SubnavPortfolio4'
import NFT from '../NFT'
import './MyPortfolio.css'

const logger = debug('fluidnft:MyPortfolio')

function MyPortfolio(props) {
  const { sectionTitle, dashboardProps, nFT7Props } = props
  const { fetchNfts, formatNftData, fetchNftFloorPrices } = useNFT()
  const {
    nfts,
    setWhitelisted,
    isWhitelisted,
    setNftTokenAddress,
    setNftTokenId,
    setNftCollectionName,
    setNftCollectionSymbol,
    setNftImage,
    setAvailableLiquidity,
    floorPrices,
  } = useAppContext()
  const { account } = useWeb3React()
  const { fetchEthUsdPrice } = useToken()
  // const [ isWhitelist, setWhitelist ] = useState(true); // TODO: Link this to the toggle

  var nftData = {}
  var filteredNFts = []

  useEffect(() => {
    setNftTokenAddress('')
    setNftTokenId('')
    setNftCollectionName('')
    setNftCollectionSymbol('')
    setNftImage('')
    fetchEthUsdPrice()
  }, [account])

  useEffect(() => {
    let liquidity = 0
    if (Object.keys(nfts).length > 0) {
      const formattedNfts = nfts.ownedNfts.map((nft) => formatNftData(nft))
      logger('RUNNING!')
      formattedNfts.map((nft) => (liquidity += nft.floorPrice / minCollRatio))
      logger('liquidity!', liquidity)
      setAvailableLiquidity(liquidity)
    }
  }, [nfts])

  useEffect(() => {
    if (account) {
      fetchNfts(account, isWhitelisted)
      fetchNftFloorPrices()
      fetchEthUsdPrice()
    }
  }, [account, isWhitelisted])

  const handleChange = () => {
    setWhitelisted(!isWhitelisted)
  }

  const [displayNfts, matchedNfts] = useListNfts()

  return (
    <div className='container-center-horizontal'>
      <div className='my-portfolio screen'>
        <Nav />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          <Dashboard
            dashboardAverageCr1Props={dashboardProps.dashboardAverageCr1Props}
            dashboardAverageCr2Props={dashboardProps.dashboardAverageCr2Props}
          />
          <FluidDrawer
            absolute
            compact
            margin='7vh 0 5vh 0'
            maxHeight={250}
            maxWidth={260}
            nfts={matchedNfts}
            title='Collateral'
          />
        </div>
        <div className='flex-row'>
          <div className='section-title valign-text-middle oxanium-medium-black-27px'>
            {sectionTitle}
          </div>
          <SubnavPortfolio4 />
        </div>
        <img className='section-line' src='/img/section-line@1x.svg' />
        {/* <div className="flex-row-1">
          <Search />
          <div className="toggle-group">
            <div className="toggle-text valign-text-middle">{toggleText}</div>
            <SwitchUnstyled
              component={Root} {...label}
              checked={isWhitelisted}
              onChange={handleChange}
            />
          </div>
        </div> */}
        <Scrollbars style={{ width: 1240, height: screen.height }}>
          <div className='nft-portfolio-container'>
            <div className='nft-portfolio'>
              {displayNfts.map(
                (nft, index) => (
                  logger('nft', nft, floorPrices),
                  (
                    <NFT
                      className={nFT7Props.className}
                      tokenAddress={nft.tokenAddress}
                      tokenId={nft.tokenId}
                      collectionName={nft.collectionName}
                      collectionSymbol={nft.collectionSymbol}
                      image={nft.image}
                      key={index}
                      availableLiquidity={
                        nft.floorPrice
                          ? formatNumber(nft.floorPrice / minCollRatio, 3)
                          : '--'
                      }
                    />
                  )
                )
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

export default MyPortfolio

const black = {
  500: '#000000',
}

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
}

const Root = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
  margin: 9px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${theme.palette.mode === 'dark' ? grey[600] : grey[400]};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 20px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${black[500]};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
)

const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } }
